export const metaData = {
    "homepage": {
        pageTitle:"Web & Software Development Comapny in Rajkot | Clevero Technology",
        metaDescription:"We provide Web Development, CRM Development, API Development, Search Engine Optimization, Digital Marketing, Game Development and Android App Development.",
        metaKeywords:"Web & Software Development, App Development,Web Development,Digital Marketing,Seo",
        ogTitle:"Web & Software Development Comapny in Rajkot | Clevero Technology",
        ogImage: "https://www.clevero.in/assets/images/home/home.png",
        ogDescription:"We provide Web Development, CRM Development, API Development, Search Engine Optimization, Digital Marketing, Game Development and Android App Development."
    },
    "aboutUs": {
        pageTitle: "Clevero Technology - Your Web and Mobile App Solution Partner",
        metaDescription: "Clevero Technology was founded in 2008, and now it is a leading mobile app and web development company in Rajkot, Gujarat, India.",
        metaKeywords: "Web & Software Development, App Development,Web Development,Digital Marketing,Seo",
        ogTitle: "Clevero Technology - Your Web and Mobile App Solution Partner",
        ogImage: "https://www.clevero.in/assets/images/about/who-we-are.png",
        ogDescription: "Clevero Technology was founded in 2008, and now it is a leading mobile app and web development company in Rajkot, Gujarat, India."
    },
    "contactUs": {
        pageTitle: "Contact Clevero Technology – Web and App Solution Company",
        metaDescription: "Clevero Technology is a leading Web and Mobile App Solution Company located in Rajkot, Gujarat, India. Get Free Quote or contact us now.",
        metaKeywords: "Web & Software Development, App Development,Web Development,Digital Marketing,Seo",
        ogTitle: "Contact Clevero Technology – Web and App Solution Company",
        ogImage: "https://www.clevero.in/assets/images/logo/logo.jpg",
        ogDescription: "Clevero Technology is a leading Web and Mobile App Solution Company located in Rajkot, Gujarat, India. Get Free Quote or contact us now."
    },
    "career": {
        pageTitle: "Career | Clevero Technology",
        metaDescription: "Advance your career with the premier web design and development company in Rajkot. We offer challenges and inspiration to help you achieve your highest goals.",
        metaKeywords: "Web & Software Development, App Development,Web Development,Digital Marketing,Seo",
        ogTitle: "Career | Clevero Technology",
        ogImage:"https://www.clevero.in/assets/images/career/team-work.png",
        ogDescription: "Advance your career with the premier web design and development company in Rajkot. We offer challenges and inspiration to help you achieve your highest goals."
    },
    "services": {
        pageTitle:"Web & Software Development services | Clevero Technology",
        metaDescription:"Expert web & software development services by Clevero Technology. Tailored solutions, innovative designs, and reliable support to boost your business success.",
        metaKeywords:"Web & Software Development, App Development,Web Development,Digital Marketing,SEO",
        ogTitle:"Web & Software Development services | Clevero Technology",
        ogImage: "https://www.clevero.in/assets/images/logo/logo.jpg",
        ogDescription:"Expert web & software development services by Clevero Technology. Tailored solutions, innovative designs, and reliable support to boost your business success.",
    },
    "blogPage": {
        pageTitle:"Web & Software Development Blogs | Clevero Technology",
        metaDescription:"Expert web & software development services by Clevero Technology. Tailored solutions, innovative designs, and reliable support to boost your business success.",
        metaKeywords:"Web & Software Development, App Development,Web Development,Digital Marketing,SEO",
        ogTitle:"Web & Software Development services | Clevero Technology",
        ogImage: "https://www.clevero.in/assets/images/logo/logo.jpg",
        ogDescription:"Expert web & software development services by Clevero Technology. Tailored solutions, innovative designs, and reliable support to boost your business success.",
    },
    "web-development": {
        pageTitle:"Web Design & Development Services | Clevero Technology",
        metaDescription:"Explore Clevero professional web design and development services. We specialize in creating stunning websites tailored to your business needs. Contact us today!",
        metaKeywords:"Web & Software Development, App Development,Web Development,Digital Marketing,Seo",
        ogTitle:"Web Design & Development Services | Clevero Technology",
        ogImage: "https://www.clevero.in/assets/images/service/sub-services/web-development.png",
        ogDescription:"Explore Clevero professional web design and development services. We specialize in creating stunning websites tailored to your business needs. Contact us today!",
    },
    "crm-development": {
        pageTitle:"CRM Development Services | Clevero Technology",
        metaDescription:"Clevero provides exceptional solutions through powerful CRM tools that have revolutionized CRM methods.",
        metaKeywords:"Web & Software Development, App Development,Web Development,Digital Marketing,Seo",
        ogTitle:"CRM Development Services | Clevero Technology ",
        ogImage: "https://www.clevero.in/assets/images/service/sub-services/crm-development.png",
        ogDescription:"Clevero provides exceptional solutions through powerful CRM tools that have revolutionized CRM methods.",
    },
    "api-development": {
        pageTitle:"API Development Services | Clevero Technology",
        metaDescription:"One of the Leading Experts in Custom API Development Services & Integration Solutions. Get in touch today!",
        metaKeywords:"Web & Software Development, App Development,Web Development,Digital Marketing,Seo",
        ogTitle:"API Development Services | Clevero Technology",
        ogImage: "https://www.clevero.in/assets/images/service/sub-services/api-development.png",
        ogDescription:"One of the Leading Experts in Custom API Development Services & Integration Solutions. Get in touch today!"
    },
    "graphic-designing": {
        pageTitle:"Game Development Services | Clevero Technology",
        metaDescription:"Dive into Clevero game development services. We specialize in creating immersive and engaging games for various platforms. Contact us to bring your game ideas to life!",
        metaKeywords:"Web & Software Development, App Development,Web Development,Digital Marketing,Seo",
        ogTitle:"Game Development Services | Clevero Technology",
        ogImage: "https://www.clevero.in/assets/images/service/sub-services/graphic-design.png",
        ogDescription:"Dive into Clevero game development services. We specialize in creating immersive and engaging games for various platforms. Contact us to bring your game ideas to life!"
    },
    "android-development": {
        pageTitle:"Custom Android Mobile App Development Services in India",
        metaDescription:"We are the best Android app development company providing expert android(Java, Kotlin) application development services.",
        metaKeywords:"Web & Software Development, App Development,Web Development,Digital Marketing,Seo",
        ogTitle:"Custom Android Mobile App Development Services in India",
        ogImage: "https://www.clevero.in/assets/images/service/sub-services/android-development.png",
        ogDescription:"We are the best Android app development company providing expert android(Java, Kotlin) application development services."
    },
    "ios-development": {
        pageTitle:"IOS  App Development Services | Clevero Technology",
        metaDescription:"Clevero Technology, an award-winning iOS app development company, provides custom iOS and iPhone app development services for entrepreneurs and small businesses.",
        metaKeywords:"Web & Software Development, App Development,Web Development,Digital Marketing,Seo",
        ogTitle:"IOS  App Development Services | Clevero Technology",
        ogImage: "https://www.clevero.in/assets/images/service/sub-services/ios-development.png",
        ogDescription:"Clevero Technology, an award-winning iOS app development company, provides custom iOS and iPhone app development services for entrepreneurs and small businesses."
    },
    "e-commerce-services": {
        pageTitle:"Ecommerce Website Development | Clevero Technology",
        metaDescription:"Boost your e-commerce success with Clevero Technology services, including custom web and mobile app development, digital marketing, and strategic solutions for outstanding online shopping experiences.",
        metaKeywords:"Web & Software Development, App Development,Web Development,Digital Marketing,Seo",
        ogTitle:"Ecommerce Website Development | Clevero Technology",
        ogImage: "https://www.clevero.in/assets/images/service/sub-services/e-commerce-development.png",
        ogDescription:"Boost your e-commerce success with Clevero Technology services, including custom web and mobile app development, digital marketing, and strategic solutions for outstanding online shopping experiences."
    },
    "php-development": {
        pageTitle:"PHP Development Services | Clevero Technology",
        metaDescription:"We Provide a top-notch PHP development services at Clevero in rajkot, india. We offer custom solutions to meet your business needs. Contact us today!",
        metaKeywords:"Web & Software Development, App Development,Web Development,Digital Marketing,Seo",
        ogTitle:"PHP Development Services | Clevero Technology",
        ogImage: "https://www.clevero.in/assets/images/service/sub-services/php-development.png",
        ogDescription:"We Provide a top-notch PHP development services at Clevero in rajkot, india. We offer custom solutions to meet your business needs. Contact us today!"
    },
    "wordpress-development": {
        pageTitle:"WordPress Development Services | Clevero Technology",
        metaDescription:"Explore our WordPress development services at Clevero India. From customization to optimization, we help you build a powerful online presence. Contact us today!",
        metaKeywords:"Web & Software Development, App Development,Web Development,Digital Marketing,Seo",
        ogTitle:"WordPress Development Services | Clevero Technology",
        ogImage: "https://www.clevero.in/assets/images/service/sub-services/wordpress-development.png",
        ogDescription:"Explore our WordPress development services at Clevero India. From customization to optimization, we help you build a powerful online presence. Contact us today!"
    },
    "ui-ux-designing": {
        pageTitle:"UI/UX Web Designing Services | Clevero Technology",
        metaDescription:"Clevero Technology offers comprehensive UI/UX design and development services to create web and mobile apps that align with your business goals, delivering delightful user experiences.",
        metaKeywords:"Web & Software Development, App Development,Web Development,Digital Marketing,Seo",
        ogTitle:"UI/UX Web Designing Services | Clevero Technology",
        ogImage: "https://www.clevero.in/assets/images/service/sub-services/uiux-designing.png",
        ogDescription:"Clevero Technology offers comprehensive UI/UX design and development services to create web and mobile apps that align with your business goals, delivering delightful user experiences."
    },
    "digital-marketing": {
        pageTitle:"Digital Marketing Services | Clevero Technology",
        metaDescription:"CleveroTechnology is top Digital Marketing Company in Rajkot, India. We provide services like AdWords services, SEO services, Social media marketing services and other digital marketing services.",
        metaKeywords:"Web & Software Development, App Development,Web Development,Digital Marketing,Seo",
        ogTitle:"Digital Marketing Services | Clevero Technology",
        ogImage: "https://www.clevero.in/assets/images/service/sub-services/digital-marketing.png",
        ogDescription:"CleveroTechnology is top Digital Marketing Company in Rajkot, India. We provide services like AdWords services, SEO services, Social media marketing services and other digital marketing services."
    },
    "seo": {
        pageTitle:"Local SEO Services | Clevero Technology",
        metaDescription:"Searching for Best SEO Companies? SEO Agency- Clevero Technology offers Best SEO Services. 100% Success Rate, 1k+ projects.",
        metaKeywords:"Web & Software Development, App Development,Web Development,Digital Marketing,Seo",
        ogTitle:"Local SEO Services | Clevero Technology",
        ogImage: "https://www.clevero.in/assets/images/service/sub-services/seo-development.png",
        ogDescription:"Searching for Best SEO Companies? SEO Agency- Clevero Technology offers Best SEO Services. 100% Success Rate, 1k+ projects."
    },
    "social-media-management" : {
        pageTitle:"Social Media Management Services | Clevero Technology",
        metaDescription:"Clevero is a leading social media management company offering comprehensive social media marketing, management, and advertising services to help businesses thrive online.",
        metaKeywords:"Web & Software Development, App Development,Web Development,Digital Marketing,Seo",
        ogTitle:"Social Media Management Services | Clevero Technology",
        ogImage: "https://www.clevero.in/assets/images/service/sub-services/expert-for-social-media-marketing.png",
        ogDescription:"Clevero is a leading social media management company offering comprehensive social media marketing, management, and advertising services to help businesses thrive online."
    },
    "google-advertising": {
        pageTitle:"Grow your business with Google Ads | Clevero Technology",
        metaDescription:"Our Google Ads service delivers high-quality traffic and measurable results, ensuring your business reaches its target audience effectively and achieves optimal ROI.",
        metaKeywords:"Web & Software Development, App Development,Web Development,Digital Marketing,SEO",
        ogTitle:"Grow your business with Google Ads | Clevero Technology",
        ogImage: "https://www.clevero.in/assets/images/service/sub-services/google-ads-services-for-maximum-roi.png",
        ogDescription:"Our Google Ads service delivers high-quality traffic and measurable results, ensuring your business reaches its target audience effectively and achieves optimal ROI."
    },
    "e-commerce-marketing": {
        pageTitle:"E-commerce marketing services | Clevero Technology",
        metaDescription:"Boost your online sales with our expert E-commerce Marketing services. We drive traffic and convert visitors into loyal customers.",
        metaKeywords:"Web & Software Development, App Development,Web Development,Digital Marketing,SEO",
        ogTitle:"Grow your business with Google Ads | Clevero Technology",
        ogImage: "https://www.clevero.in/assets/images/service/sub-services/e-commerce-marketing.png",
        ogDescription:"Boost your online sales with our expert E-commerce Marketing services. We drive traffic and convert visitors into loyal customers."
    },
    "e-mail-marketing": {
        pageTitle:"E-mail marketing services | Clevero Technology",
        metaDescription:"Boost your business with Clevero Technology's expert email marketing services. Engage, convert, and retain customers with targeted campaigns and analytics.",
        metaKeywords:"Web & Software Development, App Development,Web Development,Digital Marketing,SEO",
        ogTitle:"E-mail marketing services | Clevero Technology",
        ogImage: "https://www.clevero.in/assets/images/service/sub-services/email-marketing.png",
        ogDescription:"Boost your business with Clevero Technology's expert email marketing services. Engage, convert, and retain customers with targeted campaigns and analytics."
    }
}