import React, { useEffect, useState } from 'react'

function CarouselAbout() {

  const [currentIndex, setCurrentIndex] = useState(0);
  const testimonials = [
    {
      text: <><p className='text-18'>"Exceptional Service and Results!"</p><p>We've been working with Clevero Technology for over a year now, and the results have been outstanding. Their digital marketing strategies have significantly boosted our online presence and lead generation. The team is professional, responsive, and always goes the extra mile to ensure our satisfaction. We highly recommend Clevero Technology for anyone looking to take their business to the next level.</p></>,
      author: "Amit Patel",
      // position: "CEO & Co-Founder, Sen Group"
    },
    {
      text: <><p className='text-18'>"Highly Skilled and Professional Team"</p><p> Clevero Technology transformed our outdated website into a modern, user-friendly platform that our customers love. Their web development team is highly skilled, and they completed the project ahead of schedule. The ongoing support and regular updates have been fantastic. We're thrilled with the partnership and the growth we've seen since the new website launch.</p></>,
      author: "Priya Mehta",
      // position: "CEO & Co-Founder, Sen Group"
    },
    {
      text: <><p className='text-18'>"Outstanding Digital Marketing Expertise"</p><p> Choosing Clevero Technology for our digital marketing needs was one of the best decisions we've made. Their expertise in SEO, social media, and PPC campaigns has helped us reach a wider audience and increase our sales. The team's dedication to our success is evident in everything they do. We couldn't be happier with the results and look forward to continuing our collaboration.</p></>,
      author: "Rakesh Sharma",
      // position: "CEO & Co-Founder, Sen Group"
    }
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  useEffect(() => {
    const autoSlide = setInterval(nextSlide, 5000);

    return () => clearInterval(autoSlide);
  }, [testimonials.length]);

  return (
    <>
      <div className="w-full carousel-container relative carousel-nav-left carousel-nav-lg carousel-nav-left carousel-dots-mobile-outside carousel-dots-mobile-center yes">
        <div className="-mr-15 -ml-15 carousel-items relative">
          <div className="carousel-container">
            <div className="carousel-item-inner" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
              {testimonials.map((testimonial, index) => (
                <div className="carousel-item-content" key={`carousel${index}`}>
                  <p>{testimonial.text}</p>
                  <h5>{testimonial.author}</h5>
                  <span>{testimonial.position}</span>
                </div>
              ))}
            </div>
            <div className="carousel-controls">
              <button onClick={prevSlide} className="flickity-button flickity-prev-next-button previous" type="button" aria-label="Previous" disabled={currentIndex === 0} >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13.4" viewBox="0 0 16 13.4">
                  <path d="M9.3,1.3,7.9,2.7,12.2,7H0V9H12.2L7.9,13.3l1.4,1.4L16,8Z" transform="translate(16 14.7) rotate(180)"></path>
                </svg>
              </button>
              <div className="flex d-flex align-items-center justify-content-center flex items-center justify-center"
              style={{alignItems: "baseline !important"}}>
                <div className="lqd-carousel-slides-current d-inline-block overflow-hidden text-center" style={{ width: '20px', whiteSpace: 'nowrap' }}>
                  <div className="lqd-carousel-slides-current-inner d-inline-block pos-rel"
                    style={{ transition: 'transform 0.5s ease', transform: `translateX(-${currentIndex * 20}px)` }}>
                    {testimonials.map((_, index) => (
                      <span key={index} className="d-inline-block" style={{ marginTop: '13px', width: '20px', fontSize: '16px', textAlign: 'center', textIndent: index >= 0 ? '10px' : '' }}>
                        {index + 1}
                      </span>
                    ))}
                  </div>

                </div>
                <div className="lqd-carousel-slides-about" style={{marginTop: "2px"}}>{testimonials.length}</div>
              </div>
              <button onClick={nextSlide} className="flickity-button flickity-prev-next-button next" type="button" aria-label="Next" disabled={currentIndex === testimonials.length - 1} >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13.4" viewBox="0 0 16 13.4">
                  <path d="M9.3,13.4,7.9,12l4.3-4.3H0v-2H12.2L7.9,1.4,9.3,0,16,6.7Z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default CarouselAbout