import React from 'react'
import HeaderPage from './HeaderPage';
import Particle from './Particle';
import { Link } from 'react-router-dom';

export default function TitlebarInner(props) {

  return (
    <>
        <link rel="stylesheet" href="/assets/css/demo/responsivecss.css"/>
        <section className="lqd-section w-full flex-column items-center titlebar-padding justify-center" id='titlebar'>
            <div className="absolute w-full h-full">
                <div className="ld-particles-container relative w-full lqd-particles-as-bg lqd-overlay flex ">
                    <Particle />
                </div>
            </div>
            <div className={`titlebar bg-transparent ${props.PageName ? 'bg-subpage-image' : ''} custom-box-shadow`} id="titlebar">

            {/* <div className="titlebar bg-transparent bg-subpage-image custom-box-shadow" id="titlebar"> */}
                <div className="lqd-sticky-placeholder hidden"></div>
                {/* <HeaderPage /> */}
                {props.PageName?
                <div className="titlebar-inner pt-120 pb-85">
                    <div className="lqd-particles-bg-wrap w-full h-full absolute top-0 left-0 pointer-events-none">
                        <div className="ld-particles-container lqd-particles-as-bg w-full h-full absolute top-0 left-0">
                            <div className="ld-particles-inner w-full h-full absolute top-0 left-0" id="lqd-features-particle"></div>
                        </div>
                    </div>
                    <div className="lqd-shape lqd-shape-bottom">
                        <svg className="lqd-custom-shape" fill="none" height="461" viewBox="0 0 1440 461" width="1440" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"></svg>
                    </div>
                    <div className="container py-0 px-15">
                        <div className="row justify-center -mx-15">
                            <div className="col titlebar-col col-xl-8 col-lg-8 col-12 text-center py-0 px-15 text-gray-600">
                                <h1 style={{textTransform: 'capitalize'}} className="leading-60 text-title">{props.PageName}</h1>
                                <p className="leading-28">
                                {props.servicetype && <Link to="/services">Services / </Link>}
                                {props.PageDec}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                : ''
                }           
            </div>
        </section>
    </>
  )
}