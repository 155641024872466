import { gsap } from "gsap";
import { ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function homeContentSec(cls,trVal){
    gsap.timeline({
        scrollTrigger: {
          trigger: cls,
          start: `top ${trVal? trVal : '80'}%`,
        },
      }).fromTo(cls,{y:35},{duration: 1.8,y:0,ease: "power3.out",});
}

export function subHeadHead(cls){
    gsap.timeline({
        scrollTrigger: {
          trigger: cls,
          start: "top 101%",
        },
      }).fromTo(cls,{y:30,opacity:0},{y:0,opacity:1,stagger:0.1,});
}

export function creativeServices(cls){
  let element = document.querySelectorAll(`.${cls}`);
  element.forEach((data,ind)=>{
  data.classList.add(`${cls}${ind+1}`)
  let animation = gsap.to(`.${cls}${ind+1} img,.${cls}${ind+1} h3,.${cls}${ind+1} p`,{
    paused: true,
    y:-20,
    duration:0.5,
    stagger:0.1,
    ease:'power1.out'
  });

  let animationA = gsap.fromTo(`.${cls}${ind+1} a.view-more-btn`,{opacity:0,},{
    paused: true,
    y:-20,
    opacity:1,
    duration:0.5,
    stagger:0.1,
    ease:'power1.out'
  });
  
  let text = document.querySelectorAll(`.${cls}${ind+1}`);

  text[0].addEventListener("mouseenter", () => {animation.play();animationA.play();});
  text[0].addEventListener("mouseleave", () => {animation.reverse();animationA.reverse()});
})
}
export function creativeServicesMobile(cls){
  let element = document.querySelectorAll(`.${cls}`);
  element.forEach((data,ind)=>{
  data.classList.add(`${cls}${ind+1}`)
  let animation = gsap.to(`.${cls}${ind+1} img,.${cls}${ind+1} h3,.${cls}${ind+1} p`,{
    paused: true,
    y:-20,
    duration:0.5,
    stagger:0.1,
    ease:'power1.out'
  });

  let animationA = gsap.fromTo(`.${cls}${ind+1} a.view-more-btn`,{opacity:1,},{
    paused: true,
    y:-20,
    opacity:1,
    duration:0.5,
    stagger:0.1,
    ease:'power1.out'
  });
  
  let text = document.querySelectorAll(`.${cls}${ind+1}`);

  text[0].addEventListener("mouseenter", () => {animation.play();animationA.play();});
  text[0].addEventListener("mouseleave", () => {animation.reverse();animationA.reverse()});
})
}

export function careerData(cls){
  gsap.timeline({
      scrollTrigger: {
        trigger: cls,
        start: "top 90%",
      },
    }).fromTo(cls,{x: '70px', opacity : 0},{stagger:0.2,duration:1,ease: 'expo.out',x: 0, opacity : 1});
}

export function BenefitsSecAnimation(cls){
  gsap.timeline({
      scrollTrigger: {
        trigger: cls,
        start: "top 120%",
      },
    }).fromTo(cls,{y: '100px', opacity : 0},{stagger:0.2,duration:1,ease: 'expo.out',y: 0, opacity : 1});
}

window.onscroll = function () { scrollFunction() };

export function scrollFunction() {
  const goTopButton = document.getElementById("goTopButton");
  if (goTopButton) {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          goTopButton.style.display = "block";
      } else {
          goTopButton.style.display = "none";
      }
  }

  return document.body.scrollTop || document.documentElement.scrollTop;
}

export function  scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
}

export function servicesProvide(cls){
  gsap.timeline({
      scrollTrigger: {
        trigger: cls,
        start: 'top bottom',
        end: 'bottom+=0px top',
      },
    }).fromTo(cls,{y: 60},{scrub:1,duration:1,ease: 'linear',y: -150 ,});
}

export function lineAnimate(cls, delay){
  let element = document.querySelectorAll(`.${cls}`);
  element.forEach((data,ind)=>{
    data.style.overflowY='hidden';
    data.classList.add(`${cls}${ind+1}`)
    
    gsap.timeline({
      scrollTrigger: {
        trigger: `.${cls}${ind+1}`,
        start: "top 90%",
      },
    }).fromTo(`.${cls}${ind+1}`,{y: 80, opacity : 0},{delay:`${delay? delay : 0}`, y: 0, opacity : 1});
})
}