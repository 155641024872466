import React, { useEffect, useRef, useState, useCallback } from 'react';
import { gsap } from 'gsap';
import Matter from 'matter-js';
import { useInView } from 'react-intersection-observer';

const Throwable = ({ roundness = 'sharp', scrollGravity = false }) => {

  const containerRef = useRef(null);
  const [engine, setEngine] = useState(null);
  const [runner, setRunner] = useState(null);
  const [bodies, setBodies] = useState([]);
  const [boundaries, setBoundaries] = useState({});
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [ref, inView] = useInView();
  const [triggered, setTriggered] = useState(false);
  const [draggedBody, setDraggedBody] = useState(null);
  const [prevMousePos, setPrevMousePos] = useState({ x: 0, y: 0 });

  const labels = ['Laravel','CodeIgniter','CorePHP','jQuery','ReactJS','AngularJS','React Native','VueJS','Flutter','Android','HTML5','Python','JAVASCRIPT'] 

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const updateDimensions = () => {
      setDimensions({
        width: container.offsetWidth,
        height: container.offsetHeight
      });
    };

    updateDimensions();

    const newEngine = Matter.Engine.create();
    const newRunner = Matter.Runner.create();
    setEngine(newEngine);
    setRunner(newRunner);

    const boundStart = Matter.Bodies.rectangle(-250, container.offsetHeight / 2, 500, 4 * container.offsetHeight, { isStatic: true });
    const boundEnd = Matter.Bodies.rectangle(container.offsetWidth + 250, container.offsetHeight / 2, 500, 4 * container.offsetHeight, { isStatic: true });
    const boundBottom = Matter.Bodies.rectangle(container.offsetWidth / 2, container.offsetHeight + 250, container.offsetWidth, 500, { isStatic: true });

    Matter.Composite.add(newEngine.world, [boundStart, boundEnd, boundBottom]);
    setBoundaries({ boundStart, boundEnd, boundBottom });

    const handleResize = () => {
      updateDimensions();
      updateBoundaries(container, newEngine, boundaries);
      updateBodies(container, bodies, roundness);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      Matter.Runner.stop(newRunner);
      Matter.Engine.clear(newEngine);
    };
  }, [containerRef]);

  useEffect(() => {
    if (!engine || !runner || !containerRef.current || !inView || triggered) return; // Check if already triggered
    setTriggered(true); // Set triggered to true after first trigger

    const throwables = containerRef.current.querySelectorAll('[data-lqd-throwable-el]');
    const newBodies = [];

    throwables.forEach((element, index) => {
      const span = element.querySelector('span');
      const rect = element.getBoundingClientRect();
      const angle = gsap.utils.random(-0.2 * Math.PI, 0.2 * Math.PI);
      const xPos = gsap.utils.random(rect.width / 2, dimensions.width - rect.width / 2);
      const yPos = -rect.height - (index * rect.height + 10);
      const chamferRadius = roundness === 'sharp' ? 0 : rect.height / 2;
      const body = Matter.Bodies.rectangle(xPos, yPos, rect.width, rect.height, {
        chamfer: { radius: chamferRadius },
        angle,
        restitution: 0.3
      });
      newBodies.push(body);
      Matter.Composite.add(engine.world, [body]);
      Matter.Events.on(runner, 'tick', () => {
        span.style.transform = `translate(-50%, -50%) rotate(${body.angle.toFixed(2)}rad)`;
        gsap.quickSetter(element, 'x', 'px')(body.position.x.toFixed(1));
        gsap.quickSetter(element, 'y', 'px')(body.position.y.toFixed(1));
      });
    });

    setBodies(newBodies);
    Matter.Runner.start(runner, engine);
  }, [engine, runner, dimensions, roundness, inView, triggered]); // Add triggered to dependencies

  const updateBoundaries = useCallback((container, engine, boundaries) => {
    const { boundStart, boundEnd, boundBottom } = boundaries;
    if (!boundStart || !boundEnd || !boundBottom) return;

    Matter.Body.setPosition(boundStart, { x: -250, y: container.offsetHeight / 2 });
    Matter.Body.setVertices(boundStart, Matter.Bodies.rectangle(-250, container.offsetHeight / 2, 500, 4 * container.offsetHeight, { isStatic: true }).vertices);

    Matter.Body.setPosition(boundEnd, { x: container.offsetWidth + 250, y: container.offsetHeight / 2 });
    Matter.Body.setVertices(boundEnd, Matter.Bodies.rectangle(container.offsetWidth + 250, container.offsetHeight / 2, 500, 4 * container.offsetHeight, { isStatic: true }).vertices);

    Matter.Body.setPosition(boundBottom, { x: container.offsetWidth / 2, y: container.offsetHeight + 250 });
    Matter.Body.setVertices(boundBottom, Matter.Bodies.rectangle(container.offsetWidth / 2, container.offsetHeight + 250, container.offsetWidth, 500, { isStatic: true }).vertices);
  }, []);

  const updateBodies = useCallback((container, bodies, roundness) => {
    bodies.forEach((body, index) => {
      const element = container.querySelectorAll('[data-lqd-throwable-el]')[index];
      const rect = element.getBoundingClientRect();
      const chamferRadius = roundness === 'sharp' ? 0 : rect.height / 2;
      const newBody = Matter.Bodies.rectangle(body.position.x, body.position.y, rect.width, rect.height, {
        chamfer: { radius: chamferRadius },
        angle: body.angle
      });

      Matter.Body.setVertices(body, newBody.vertices);
      if (body.position.y > container.offsetHeight) {
        Matter.Body.setPosition(body, { y: container.offsetHeight / 2, x: body.position.x });
      }
      if (body.position.x > container.offsetWidth) {
        const xPos = gsap.utils.random(rect.width / 2, container.offsetWidth - rect.width / 2);
        Matter.Body.setPosition(body, { y: body.position.y, x: xPos });
      }
    });
  }, []);

  const handleDragStart = (event, body) => {
    setDraggedBody(body);
    setPrevMousePos({ x: event.clientX, y: event.clientY });
  };
  
  const handleDragMove = (event) => {
    if (!draggedBody) return;
  
    const dx = event.clientX - prevMousePos.x;
    const dy = event.clientY - prevMousePos.y;
  
    // Apply easing to the dragging motion
    const easingFactor = 2.5; // Adjust the easing factor as needed for desired smoothness
    const newX = draggedBody.position.x + dx * easingFactor;
    const newY = draggedBody.position.y + dy * easingFactor;
  
    const newPosition = { x: newX, y: newY };
    Matter.Body.setPosition(draggedBody, newPosition);
  
    setPrevMousePos({ x: event.clientX, y: event.clientY });
  };
  
  const handleDragEnd = (event) => {
    if (!draggedBody) return;
  
    const dx = event.clientX - prevMousePos.x;
    const dy = event.clientY - prevMousePos.y;
  
    // Calculate velocity based on mouse movement
    const velocityX = dx * 0.9; // Adjust multiplier as needed
    const velocityY = dy * 0.9; // Adjust multiplier as needed
  
    // Apply impulse to simulate throwing motion
    Matter.Body.setVelocity(draggedBody, { x: velocityX, y: velocityY });
  
    setDraggedBody(null);
  };
  
  return (
    <div
      ref={containerRef}
      className="w-full flex transition-all border-top border-black-10 animation-element lqd-unit-animation-done"
      onMouseMove={handleDragMove}
      onMouseUp={handleDragEnd}
    >
      <div ref={ref} className="lqd-throwable-scene relative overflow-hidden w-full h-450" data-lqd-throwable-scene="true">
        {labels?.map((label, index) => (
          <p
            key={index}
            className={`lqd-throwable-element custom-op-translate-${index === 0 ? '1' : '3'} inline-block absolute top-0 left-0 whitespace-nowrap m-0 pointer-events-auto user-select-none`}
            data-lqd-throwable-el=""
            onMouseDown={(event) => handleDragStart(event, bodies[index])}
          >
            <span className={`lqd-throwable-element-rot custom-transform-50 inline-block rounded-100 py-0/25em px-1/5em text-18 font-medium text-black module-text-${index <= 6? index : index-6} module-text-${index <= 12? index : index-12} module-text-${index <= 13? 1 : ""} sm:text-14`}>
              {label}
            </span>
          </p>
        ))}
      </div>
    </div>
  );
};  
export default Throwable;