import React, {useState } from 'react';

const RotateText = (props) => {

  const [txt_rotate_4,settxt_rotate_4] = useState(1)
    
  setTimeout(()=>{
        settxt_rotate_4(txt_rotate_4 + 1);
        if(txt_rotate_4 == props.rotateKeyword.length){
            settxt_rotate_4(1)
        }
    },3000)

  return (
    <>
    {
      props.rotateKeyword?.map((data ,ind)=>{
        return(
          <span key={ind} className={`txt-rotate-keyword ${txt_rotate_4==ind+1? 'lqd-keyword-slide-in active' : "is-next lqd-keyword-slide-out"}`}>{data}</span>
        )
      })
    }
    </>
  );
};
export default RotateText;