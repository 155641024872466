import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';

function GetStarted(props){

    useEffect(()=>{
        gsap.registerPlugin(ScrollTrigger);
    },[])

    useEffect(()=>{
        ScrollTrigger.matchMedia({  
            // desktop
            "(min-width: 1200px)": function() {
                gsap.timeline({
                    scrollTrigger: {
                      trigger: ".lqd-section-animate",
                      ease: 'linear',
                      start: 'top 90%',
                      scrub:1,
                    },
                  }).fromTo(".lqd-section-animate",{ease: 'linear',y: 60, scaleX : 1.1, scaleY : 1.1},{ease: 'linear',y: 0, scaleX : 1 , scaleY : 1,});
                    gsap.timeline({
                    scrollTrigger: {
                      trigger: ".lqd-section-animate",
                      ease: 'linear',
                      start: 'top 90%',
                    },
                  }).fromTo(".lqd-section-animate,.lqd-section-animate h2,.lqd-section-animate p,.lqd-section-animate .btn",{y: 100, z: 100, rotationX : -65, opacity : 0},{ duration:2,ease: 'power4.out',y: 0, z: 0, rotationX : 0, opacity : 1});
            }, 
            
            // mobile
            "(max-width: 1199px)": function() {
                gsap.timeline({
                    scrollTrigger: {
                      trigger: ".lqd-section-animate",
                      ease: 'linear',
                      start: 'top 90%',
                      scrub:1,
                    },
                  }).fromTo(".lqd-section-animate",{ease: 'linear',y: 30},{ease: 'linear',y: 0,});
                    gsap.timeline({
                    scrollTrigger: {
                      trigger: ".lqd-section-animate",
                      ease: 'linear',
                      start: 'top 90%',
                    },
                }).fromTo(".lqd-section-animate,.lqd-section-animate h2,.lqd-section-animate p,.lqd-section-animate .btn",{y: 100, z: 100, rotationX : -65, opacity : 0},{ duration:2,ease: 'power4.out',y: 0, z: 0, rotationX : 0, opacity : 1});
            }, 
            
            "all": function() {
            
            }
          });
    },[])

  return (
    <>
        <section className="lqd-section-animate lqd-section contact pb-100" id="contact" style={{perspective:'800px'}}>
            <div className="container">
                <div className="row">
                    <div className="col col-12 p-0">
                        <div className="w-full flex flex-wrap bg-white py-60 px-65 transition-all box-shadow-bottom rounded-16 module-content animation-element-wrap">
                            <div className="w-60percent p-10 lg:w-full animation-element-inner">
                                <div className="w-full flex flex-col justify-start lg:justify-center lg:text-center">
                                    <div className="ld-fancy-heading relative animation-element">
                                        <h2 className="mb-0/75em ld-fh-element relative">Ready to get started?</h2>
                                    </div>
                                    <div className="ld-fancy-heading relative animation-element">
                                        <p className="text-20 leading-1/5em m-0 ld-fh-element relative">{props?.pera}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-40percent relative flex items-center justify-end p-10 lg:w-full lg:justify-center lg:text-center animation-element-inner">
                                <div className="absolute w-auto top-25percent right-30 module-shape">
                                    <div className="lqd-imggrp-single block relative animation-element">
                                        <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                                            <figure className="w-full relative"><img src="/assets/images/shape-lines-circle.svg" alt="shape"/></figure>
                                        </div>
                                    </div>
                                </div>
                                <div className="animation-element">
                                    <Link to="/contact" className="btn font-bold text-14 uppercase tracking-0/5 whitespace-nowrap btn-solid btn-xl btn-hover-txt-liquid-y-alt text-white rounded-100 bg-primary hover:bg-"><span className="btn-txt" data-text="Get started now">Get started now</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
export default GetStarted;