import React, { useRef, useEffect } from 'react';

function Particle() {
  const stage = useRef();

  const Soup = ({
    baseLineOpacity = 0.2,
    maxLineDistance = 40,
    particleCount = 18,
    particleSize = 4,
    particleSpeed = 0.5,
  }) => {
    let context;
    const points = [];
    const checkDistance = (x1, y1, x2, y2) => Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
    const genColor = () => Math.round(255 * Math.random());
    const addPoint = (x, y) => points.push({
      x,
      y,
      dx: -particleSpeed / 2 + particleSpeed * Math.random(),
      dy: -particleSpeed / 2 + particleSpeed * Math.random(),
      color: `rgb(${genColor()},${genColor()},${genColor()})`
    });

    const drawPoint = point => {
      context.fillStyle = point.color;
      context.beginPath();
      context.arc(point.x, point.y, particleSize, 0, 2 * Math.PI);
      context.fill();
    };

    const drawLines = (point, index) => {
      for (let i = 0; i < index; i++) {
        const opacity = baseLineOpacity - checkDistance(point.x, point.y, points[i].x, points[i].y) / maxLineDistance;
        if (opacity > 0) {
          const grad = context.createLinearGradient(point.x, point.y, points[i].x, points[i].y);
          grad.addColorStop(0, point.color);
          grad.addColorStop(1, points[i].color);

          context.strokeStyle = grad;
          context.globalAlpha = opacity;
          context.beginPath();
          context.moveTo(point.x, point.y);
          context.lineTo(points[i].x, points[i].y);
          context.stroke();
          context.globalAlpha = 1;
        }
      }
    };

    const movePoint = () => {
      for (let i = 0; i < points.length; i++) {
        const xDx = points[i].x + points[i].dx;
        const yDy = points[i].y + points[i].dy;

        if (xDx - particleSize < 0 || xDx + particleSize > stage.current.width) {
          points[i].dx = -points[i].dx;
        }

        if (yDy - particleSize < 0 || yDy + particleSize > stage.current.height) {
          points[i].dy = -points[i].dy;
        }

        points[i].x += points[i].dx;
        points[i].y += points[i].dy;

        drawPoint(points[i]);
        drawLines(points[i], i);
      }
    };

    const init = () => {
      if (stage.current) {
        stage.current.width = window.innerWidth;
        stage.current.height = window.innerHeight;

        points.length = 0;

        for (let i = 0; i < particleCount; i++) {
          addPoint(stage.current.width * Math.random(), stage.current.height * Math.random());
        }
      }
    };

    const draw = () => {
      if (stage.current) {
        context.clearRect(0, 0, stage.current.width, stage.current.height);
        movePoint();
        window.requestAnimationFrame(draw);
      }
    };

    useEffect(() => {
      if (stage.current) {
        context = stage.current.getContext('2d');

        window.addEventListener('resize', init);

        stage.current.addEventListener('mouseup', function (e) {
          addPoint(e.pageX - stage.current.offsetLeft, e.pageY - stage.current.offsetTop);
        });

        init();
        draw();
      }

      return () => {
        window.removeEventListener('resize', init);
      };
    }, []);

    return <canvas ref={stage} />;
  };

  return (
    <>
      <div style={{ overflow: 'hidden', zIndex: '-1' }}>
        <Soup />
      </div>
    </>
  );
}

export default Particle;