import React, { useEffect} from 'react'
import TitlebarInner from './TitlebarInner';
import { creativeServices, creativeServicesMobile, lineAnimate } from '../functions/functions';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Link, useParams } from 'react-router-dom';
import { serviceContent } from '../data/serviceContent';
import CountUp from 'react-countup';
import ContactForm from './ContactForm';
import FooterPage from './FooterPage';
import MetaData from './MetaData';
import { metaData } from '../metaData/meta';
const serviceArr = Object.keys(serviceContent).map(key => serviceContent[key]);

export default function ServicesPage(){

    const url = useParams()


    var service_slug = url.service;
    var serviceprovider_slug = url.serviceprovider;


    useEffect(()=>{
        gsap.registerPlugin(ScrollTrigger);
    },[])

    useEffect(()=>{
        ScrollTrigger.matchMedia({  
            // desktop
            "(min-width: 1025px)": function() {
                creativeServices('creative-services-box')
                }, 
                
                // mobile
                "(max-width: 1024px)": function() {
                    creativeServicesMobile('creative-services-box');
            }, 
            
            "all": function() {
            
            }
            
          });
          lineAnimate('form-line-line-animate')
    },[window.screen.width , url])
    
    useEffect(()=>{
        gsap.timeline({
            scrollTrigger: {
              trigger: ".home-form-animate",
              start: "top 90%",
            },
          }).fromTo(".home-form-animate h2,.home-form-animate .animation-element",{y: 60, scaleX : 0.8 , scaleY : 0.8 , opacity : 0},{ duration:2,ease: 'expo.out',y: 0, scaleX : 1 , scaleY : 1 , opacity : 1});
    },[])
  return (
    <>
        <MetaData metaData={metaData["services"]} />
        <link rel="stylesheet" href="/assets/css/demo/start-hub-8/start-hub-8.css"/>
        <TitlebarInner key="Services" PageName="Services" PageDec="What we do for you" />
        <main className="content bg-top-center bg-cover about-background content-Separate"
            id="lqd-site-content">
            <div className="lqd-contents-wrap">

                <section className="lqd-section design-services pt-100 pb-40" id="services">
                    <div className="absolute top-0">
                        <div className="ld-particles-container relative w-full">
                            <div className="ld-particles-inner lqd-overlay flex pointer-events-none" id="lqd-services-design-services">
                            </div>
                        </div>
                    </div>
                    
                    <div className="container">
                        <div className="row">
                            <div className="w-450 flex flex-col relative items-center max-w-full text-center mx-auto">
                                <div className='about-head-sec'>
                                    <div className=" ld-fancy-heading relative w-auto mb-0/65em bg-orange-100 rounded-100 animation-element lqd-unit-animation-done">
                                        <h6 className="ld-fh-element relative m-0 py-10 px-20">About</h6>
                                    </div>
                                </div>
                                <div className="ld-fancy-heading relative mask-text lqd-unit-animation-done">
                                    <h2 className="about-head-sec ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-bottom lqd-highlight-reset-onhover lqd-split-lines mb-0/5em mt-0/25em">
                                        <span>Our </span>
                                        <mark className="lqd-highlight">
                                            <span className="lqd-highlight-txt"> Company</span>
                                            <span className="bottom-0 left-0 lqd-highlight-inner bg-purple-100"></span>
                                        </mark>
                                    </h2>
                                </div>
                                <div className="absolute -top-5percent module-shape about-head-sec service-about-img">
                                    <div className="lqd-imggrp-single block relative">
                                        <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                                            <figure className="w-full relative"><img width="67" height="73" src="/assets/images/Shape.svg" alt="shape"/>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                                <div className="ld-fancy-heading relative mask-text">
                                    <p className="about-head-sec ld-fh-element relative lqd-split-lines text-17 leading-1/3em">We are an innovative and progressive technology company, committed to delivering innovative solutions and outstanding services.</p>
                                </div>
                            </div>
                            <div className="col col-12 d-xs-none d-sm-none lg:block"></div>
                            <div className="col col-12 flex gap-35 sm:flex-wrap module-iconbox">
                                <div className="max-w-full animation-element">
                                    <div className="m-0 py-50 pr-65 pl-45 rounded-20 text-start lqd-iconbox-scale transition-all creative-services-box">
                                        <div className="iconbox flex flex-grow-1 relative flex-col iconbox-default iconbox-contents-show-onhover">
                                            <div className="iconbox-icon-wrap">
                                                <div className="w-60 text-36 mb-20 iconbox-icon-container inline-flex text-primary">
                                                    <img src="/assets/images/service/Icons/(1)Excellent-Support.png" alt=""/>
                                                </div>
                                            </div>
                                            <h3 className="text-20 mb-0/75em lqd-iconbox-heading">Excellent Support</h3>
                                            <div className="contents">
                                                <p className="text-16 leading-1/25em">Dedicated to responsiveness, expertise, and customer-centricity, guaranteeing satisfaction on every occasion.</p>
                                                <Link to="/contact" className="view-more-btn btn btn-underlined border-thin btn-icon-right btn-hover-reveal text-black before:bg-black">
                                                    <span className="btn-txt" data-text="contact us">contact us</span>
                                                    <span className="btn-icon">
                                                        <i className="lqd-icn-ess icon-ion-ios-arrow-forward"></i>
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="max-w-full animation-element">
                                    <div className="m-0 py-50 pr-65 pl-45 rounded-20 text-start lqd-iconbox-scale transition-all creative-services-box">
                                        <div className="iconbox flex flex-grow-1 relative flex-col iconbox-default iconbox-contents-show-onhover">
                                            <div className="iconbox-icon-wrap">
                                                <div className="w-60 text-36 mb-20 iconbox-icon-container inline-flex text-primary">
                                                    <img src="/assets/images/service/Icons/(2)Awesome-Team.png" alt=""/>
                                                </div>
                                            </div>
                                            <h3 className="text-20 mb-0/75em lqd-iconbox-heading">Awesome Team</h3>
                                            <div className="contents">
                                                <p className="text-16 leading-1/25em">Remarkable teamwork synergy, achieving consistent excellence and delivering outstanding results collectively.
                                                </p>
                                                <Link to="/contact" className="view-more-btn btn btn-underlined border-thin btn-icon-right btn-hover-reveal text-black before:bg-black">
                                                    <span className="btn-txt" data-text="contact us">contact us</span>
                                                    <span className="btn-icon">
                                                        <i className="lqd-icn-ess icon-ion-ios-arrow-forward"></i>
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="max-w-full animation-element">
                                    <div className="py-50 pr-65 pl-45 rounded-20 m-0 text-start lqd-iconbox-scale transition-all creative-services-box">
                                        <div className="iconbox flex flex-grow-1 relative flex-col iconbox-default iconbox-contents-show-onhover">
                                            <div className="iconbox-icon-wrap">
                                                <div className="w-60 text-36 mb-20 iconbox-icon-container inline-flex text-primary">
                                                    <img src="/assets/images/service/Icons/(3)Faster-Performance.png" alt=""/>
                                                </div>
                                            </div>
                                            <h3 className="text-20 mb-0/75em lqd-iconbox-heading">Faster Performance</h3>
                                            <div className="contents">
                                                <p className="text-16 leading-1/25em">Incredible speed, improving efficiency, and optimising tasks for exceptional performance enhancements.</p>
                                                <Link to="/contact" className="view-more-btn btn btn-underlined border-thin btn-icon-right btn-hover-reveal text-black before:bg-black">
                                                    <span className="btn-txt" data-text="contact us">contact us</span>
                                                    <span className="btn-icon">
                                                        <i className="lqd-icn-ess icon-ion-ios-arrow-forward"></i>
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="lqd-section lqd-section services icon-box-page bg-gray-200 pt-90 pb-100" id="services2">
                    <div className="container">
                        <div className="absolute mb-0 top-0 z-0 max-w-full module-shape service-about-img">
                            <figure id="lqd-draw-shape-851c9d9" className="lqd-draw-shape">
                                <svg xmlns="http://www.w3.org/2000/svg" width="255.64" height="182.773" viewBox="0 0 255.64 182.773">
                                    <path d="M629.327,2793.022s26.615,148.038,71.511,142.649c13.427-2.278,22.262-11.867,28.131-22.554,8-14.569,9.938-31.107,9.938-31.107s29.991,80.066,62.334,80.06,27.3-44.1,27.3-44.1,9.277,42.023,49.372,50.767" transform="translate(-625.844 -2789.539)" fill="none" stroke="#ff5125" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6"></path>
                                </svg>
                            </figure>
                        </div>

                        <div className="col col-12">
                            <div className=" flex flex-col relative items-start max-w-full text-start mx-auto resp-title">
                                <div className="ld-fancy-heading relative w-auto mb-0/65em bg-orange-100 rounded-100 animation-element lqd-unit-animation-done">
                                    <h6 className="ld-fh-element relative m-0 py-10 px-20">Services</h6>
                                </div>
                                <div className="ld-fancy-heading relative mask-text text-center lqd-unit-animation-done">
                                    <h2 className="ld-fh-element relative lqd-split-chars mb-0/75em lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2">
                                        <span>Creative </span> 
                                        <mark className="lqd-highlight">
                                            <span className="lqd-highlight-txt"> Services</span>
                                            <span className="lqd-highlight-inner"></span>
                                        </mark>
                                    </h2>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col col-12 text-center flex flex-wrap p-0">
                                {
                                    serviceArr?.map((data , ind)=>{
                                        return(
                                        <div key={data.card.servicetitle || ind} className="col col-12 col-md-6 col-xl-4 p-0">
                                            <div className="flex flex-auto p-15 transition-all mn-h-100">
                                                <div className="iconbox flex-grow-1 relative flex-col iconbox-default iconbox-contents-show-onhover py-40 px-20 mb-30 items-center bg-white rounded-10 shadow-bottom lg:m-0 creative-services-box">
                                                    <div className="iconbox-icon-wrap creative-services-cont">
                                                        <div className="mb-25 iconbox-icon-container inline-flex w-60 text-50">
                                                            <img src={`../assets/images/service/Icons/${data.card.iconsrc}`} alt=""/>
                                                        </div>
                                                    </div>
                                                    <h3 className="creative-services-cont lqd-iconbox-heading text-center text-20 leading-1em mb-0/5em">{data.card.servicetitle}</h3>
                                                    <div className="contents">
                                                        <p className='creative-services-cont'>{data.card.servicecontent}</p>
                                                        <Link to={`${data.card.servicetitle ? data.card.servicetitle.toLowerCase().replace(/\//g, '-').replace(/ /g, '-') : ''}`} className="view-more-btn creative-services-btn btn btn-view-more btn-naked btn-icon-right btn-hover-swp mt-em mb-0 items-center text-15 font-bold text-secondary hover:text-primary"><span className="btn-txt" data-text="Learn more">Learn more</span> <span className="btn-icon text-16 tracking-0"><i className="lqd-icn-ess icon-md-arrow-round-forward-2"></i></span><span className="btn-icon mr-10 text-16 tracking-0"><i className="lqd-icn-ess icon-md-arrow-round-forward-2"></i></span></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </section>

                <section className="lqd-section counters py-45 border-bottom border-lightgray" id="count">
                    <div className="container">
                        <div className="row">
                            <div className="col col-12 text-center">
                                <div className="ld-fancy-heading mb-25">
                                    <h6 className="ld-fh-element mb-0/5em text-12 font-bold tracking-0/1em">GLOBAL CLIENTS
                                        AROUND THE WORLD</h6>
                                </div>
                            </div>
                            <div className="col col-12 col-sm-6 col-lg-3 text-center mb-25">
                                <div className="ld-fancy-heading">
                                    <p className="ld-fh-element mb-0 h1">
                                    <CountUp duration={10} className="counter" end={2.7} decimals={1} />x
                                    </p>
                                </div>
                                <div className="ld-fancy-heading uppercase">
                                    <p className="ld-fh-element mb-0/5em text-12 font-bold tracking-0/1em text-black-40">
                                        Avarage growth</p>
                                </div>
                            </div>

                            <div className="col col-12 col-sm-6 col-lg-3 text-center mb-25">
                                <div className="ld-fancy-heading">
                                    <p className="ld-fh-element mb-0 h1">
                                    <CountUp duration={10} className="counter" end={98.7} decimals={1} />+
                                    </p>
                                </div>
                                <div className="ld-fancy-heading uppercase">
                                    <p className="ld-fh-element mb-0/5em text-12 font-bold tracking-0/1em text-black-40">
                                        Customer satisfaction</p>
                                </div>
                            </div>
                            <div className="col col-12 col-sm-6 col-lg-3 text-center mb-25">
                                <div className="ld-fancy-heading">
                                    <p className="ld-fh-element mb-0 h1">  <CountUp duration={10} className="counter" end={302}/>m
                                    </p>
                                </div>
                                <div className="ld-fancy-heading uppercase">
                                    <p className="ld-fh-element mb-0/5em text-12 font-bold tracking-0/1em text-black-40">
                                        Monthly Payment</p>
                                </div>
                            </div>
                            <div className="col col-12 col-sm-6 col-lg-3 text-center mb-25">
                                <div className="ld-fancy-heading">
                                    <p className="ld-fh-element mb-0 h1">  <CountUp duration={10} className="counter" end={287}/>+
                                    </p>
                                </div>
                                <div className="ld-fancy-heading uppercase">
                                    <p className="ld-fh-element mb-0/5em text-12 font-bold tracking-0/1em text-black-40">
                                        Agents around the world</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section style={{backgroundImage:"url(assets/images/career/contact-form-bg.jpg)"}} className="lqd-section contact-form bg bg-center bg-cover pt-70 pb-100 " id="contact">
                    <div className="container">
                        <div className="row items-end">
                            <div className="w-45percent sm:w-full">
                                <div className="relative w-full flex flex-col justify-end pb-40">
                                    <div className="absolute -top-145percent ltr-left-0 module-shape-1 animation-element">
                                        <div id="lqd-lottie-form-contact" className="lqd-lottie"></div>
                                    </div>
                                    <div className="home-form-big-txt ld-fancy-heading relative mask-text">
                                        <h2 className="form-line-line-animate ld-fh-element inline-block relative lqd-split-lines mb-0/5em text-70">
                                            Have a
                                            project
                                            in mind?👋</h2>
                                    </div>
                                    <div className="ld-fancy-heading relative mask-text w-410 max-w-full">
                                        <p className="form-line-line-animate ld-fh-element inline-block relative lqd-split-lines mb-0/5em text-16 leading-20">
                                            Looking for collaboration? Send an email to 
                                            <a href="#" className="text-dark">
                                                <span>
                                                    <a className="text-manual-primary" href="mailto:hr@clevero.in"> info@clevero.in </a>
                                                </span>
                                            </a>to for vailable for enquires and collaborations,
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-50percent lg:w-55percent sm:w-full home-form-animate">
                                <div className="ld-fancy-heading relative">
                                    <h2 className="ld-fh-element relative mb-1em text-32">Send a message</h2>
                                </div>
                                <div
                                    className="lqd-contact-form lqd-contact-form-inputs-underlined lqd-contact-form-button-block lqd-contact-form-button-circle lqd-contact-form-inputs-border-thick bg-white pt-45 pb-50 px-75 rounded-12 animation-element">
                                    <div role="form">
                                        <div className="screen-reader-response animation-element">
                                            <p role="status" aria-live="polite" aria-atomic="true"></p>
                                        </div>
                                            <ContactForm />
                                        <div className="lqd-cf-response-output"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
        {/* <FooterPage/> */}
    </>
  )
}