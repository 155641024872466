import React from 'react'
import { Link } from 'react-router-dom';
import { serviceContent } from "../data/serviceContent";
import { scrollToTop } from '../functions/functions'

export default function FooterPage() {

    const copyright = new Date().getFullYear()

    const serviceArr = Object.keys(serviceContent).map(key => serviceContent[key]);

    const allowedTitles = ["Web Development","Android Development","IOS Development","API Development","LOGO Designing","Digital Marketing","UI/UX Designing","Graphic Designing"];

    return (
    <>
       <footer id="site-footer" className="main-footer link-black-60">
            <section className="lqd-section footer-menu border-top border-black-10 mt-md-70 pt-110 pb-60">
                <div className="container">
                    <div className="row">
                        <div className="col col-12 col-md-4 col-xl-3  mb-30">
                            <div className="mb-1em post-rel">
                                <img className="logo-default w-75percent"
                                    src="/assets/images/logo/logo.svg" alt="Clevero Technology"/>
                            </div>
                            <div className="ld-fancy-heading relative">
                                <p className="mb-15 ld-fh-element relative">
                                    we specialize in guiding clients <br />
                                    towards highly scalable and <br />
                                    dependable software solutions <br />
                                    for their businesses.
                                </p>
                            </div>
                        </div>
                        <div className="col col-6 col-md-4 col-xl-3 mb-30 responsive-footer">
                            <div className="ld-fancy-heading relative">
                                <h3 className="text-20 mb-1/5em ld-fh-element relative">Company</h3>
                            </div>
                            <div className="lqd-fancy-menu lqd-custom-menu relative lqd-menu-td-none">
                                <ul className="reset-ul">
                                    <li className="mb-5"><Link to="/">Home</Link></li>
                                    <li className="mb-5"><Link to="/about">About</Link></li>
                                    <li className="mb-5"><Link to="/services">Services</Link></li>
                                    <li className="mb-5"><Link to="/career">Career</Link></li>
                                    {/* <li className="mb-5"><Link to="/blog">Blog</Link></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col col-6 col-md-4 col-xl-3 mb-30">
                            <div className="ld-fancy-heading relative">
                                <h3 className="text-20 mb-1/5em ld-fh-element relative">Services</h3>
                            </div>
                            <div className="lqd-fancy-menu lqd-custom-menu relative lqd-menu-td-none">
                                <ul className="reset-ul">
                                    {serviceArr?.filter(data => allowedTitles.includes(data.card.servicetitle)).map((data, ind) => {
                                        return (
                                        <li key={ind} className="xyz mb-5">
                                            <Link to={`/services/${data.card.servicetitle ? data.card.servicetitle.toLowerCase().replace(/\//g, '-').replace(/ /g, '-') : ''}`}>{data.card.servicetitle}</Link>
                                        </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="col col-12 col-xl-3 mb-30">
                            <div className="ld-fancy-heading relative">
                                <h3 className="text-20 mb-1/5em ld-fh-element relative">Contact Us</h3>
                            </div>
                            <div className="flex">
                                <div><img  src="/assets/images/home/location.png" alt=""/></div>
                                <p className="pl-10">303 Shaligram Signet, Speedwell party <br/> plot road, opp. HDFC Bank, Nana Mava, <br/>Rajkot, Gujarat 360005</p>
                            </div>
                            
                            <div className="flex">
                                <div><img src="/assets/images/home/contect.png" alt=""/></div>
                                <p className="pl-10"><a href="tel:+02269710186"> 022 6971 0186 </a></p>
                            </div>
                            <div className="flex">
                                <div><img src="/assets/images/home/mail.png" alt=""/></div>
                                <p className="pl-10"><a href="mailto:hr@clevero.in">info@clevero.in</a></p>
                            </div>
                            <div className="w-11percent flex items-start sm:w-full sm:justify-left">
                                <div className="ld-fancy-heading relative">
                                    <div className="social-icons-wrapper gap-20">
                                        <span className="grid-item">
                                            <a className="bg-white icon social-icon" href="https://x.com/CleveroTech" target="_blank" rel="noreferrer">
                                                <span className="sr-only">Twitter</span>
                                                <img src="/assets/images/home/social-media-icon/twitter.svg" alt="Twitter"/>
                                            </a>
                                        </span>
                                        <span className="grid-item">
                                            <a className="bg-white icon social-icon" href="https://www.facebook.com/cleverotech" target="_blank" rel="noreferrer">
                                                <span className="sr-only">Facebook</span>
                                                <img src="/assets/images/home/social-media-icon/facebook.svg" alt="Facebook"/>
                                            </a>
                                        </span>
                                        <span className="grid-item">
                                            <a className="bg-white icon social-icon" href="https://www.instagram.com/cleverotechnology/" target="_blank" rel="noreferrer">
                                                <span className="sr-only">Instagram</span>
                                                <img src="/assets/images/home/social-media-icon/instagram.svg" alt="instagram"/>
                                            </a>
                                        </span>
                                        <span className="grid-item">
                                            <a className="bg-white icon social-icon" href="https://in.linkedin.com/company/clevero-technology" target="_blank" rel="noreferrer">
                                                <span className="sr-only">Linkedin</span>
                                                <img src="/assets/images/home/social-media-icon/linkedin.svg" alt="Linkedin"/>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="lqd-section footer-info">
                <div className="container">
                    <div className="row">
                        <div className="col col-12 flex flex-col items-center">
                            <div className="w-full flex border-top border-black-10"></div>
                            <div className="w-full flex flex-wrap items-center justify-center py-30 module-last">
                                <div className="w-45percent flex items-center justify-center sm:w-full">
                                    <div className="ld-fancy-heading relative text-center">
                                        <p className="ld-fh-element mb-0/5em inline-block relative">{`Copyright © ${copyright} Clevero Technology. All rights reserved.`}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* go to top button */}
            <span className="fixed z-100 go-to-topbutton" id="goTopButton" onClick={()=>{scrollToTop()}}>
                <button className="Btn-go-top">
                    <svg height="1.3em" className="arrow-go-top" viewBox="0 0 512 512"><path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"></path></svg>
                </button>                
            </span>
       </footer>
    </>
  )
}