import './Style.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import ServicesPage from './components/ServicesPage';
import Searvices from './components/Searvices';
import BlogPage from './components/BlogPage';
import CareerPage from './components/CareerPage';
import FormBottom from './components/FormBottom';
import SearvicesProviderPage from './components/SearvicesProviderPage';
import ErrorPage from './components/ErrorPage';
import BlogContent from './components/BlogContent';
import Test from './Test';
import ContactPage from './components/ContactPage';
import ScrollToTop from './components/ScrollTop';
import { HelmetProvider } from 'react-helmet-async';
import FooterPage from './components/FooterPage';
import HeaderPage from './components/HeaderPage';

function App() {

  return (
    <>
      <Router>
        <HeaderPage />
        <ScrollToTop />
        <div id="wrap">
          <div className="lqd-sticky-placeholder hidden"></div>
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            {/* <Route exact path="/test" element={<Test />} /> */}
            <Route exact path="/about" element={<AboutPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/services/:title" element={<Searvices />} />
            <Route path="/services/:service/:serviceprovider" element={<SearvicesProviderPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/category/:category" element={<BlogPage />} />
            {/* <Route path="/blog/:blogcontent" element={<BlogContent />} /> */}
            <Route path="/:blogcontent" element={<BlogContent />} />
            <Route path="/career" element={<CareerPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/404" element={<ErrorPage/>} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </div>
        <FooterPage />
        <FormBottom />
      </Router>
    </>
  );
}

export default App;