import React from 'react'
import { Link } from 'react-router-dom'

function ErrorPage() {
  return (
    <>
        <div class="error-container">
            <div class="error-content">
                <h1>404</h1>
                <h2>Not Found</h2>
                <p>The resource requested could not be found on this server!</p>
                <Link to="/">Go Back</Link>
            </div>
        </div>
    </>
  )
}

export default ErrorPage;
