import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

const MetaData = ({ metaData }) => {
    const {
        pageTitle = '',
        metaDescription = '',
        metaKeywords = '',
        ogTitle = '',
        ogImage = '',
        ogDescription = ''
    } = metaData;

    return (
        <Helmet>
            
            <title>{pageTitle}</title>
            <meta property="og:url" content={window.location.href} />
            <meta name="title" property="og:title" content={ogTitle} />
            <meta name="description" property="og:description" content={ogDescription} />
            <meta property="og:image" content={ogImage} />
            <meta property="og:image:width" content="860" />
            <meta property="og:image:height" content="460" />
            <meta property="og:type" content="article" />
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={metaKeywords} />
            <link rel="canonical" href={window.location.href} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@CleveroTech" />
            <meta name="twitter:title" content={ogTitle} />
            <meta name="twitter:description" content={ogDescription} />
            <meta name="twitter:image" content={ogImage} />
        </Helmet>
    );
};

MetaData.propTypes = {
    metaData: PropTypes.shape({
        pageTitle: PropTypes.string,
        metaDescription: PropTypes.string,
        metaKeywords: PropTypes.string,
        ogTitle: PropTypes.string,
        ogImage: PropTypes.string,
        ogDescription: PropTypes.string,
    }).isRequired,
};

export default MetaData;
