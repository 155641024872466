import React, { useState } from 'react'
import { serviceContent } from '../../data/serviceContent'
import { Link, useParams } from 'react-router-dom';

function ChoosePlan(){

    const getServiceProviderData = (serviceprovider, serviceproviderSlug) => {
        const allServices = [
            ...serviceprovider.col_one,
            ...serviceprovider.col_two,
            ...serviceprovider.col_three
            ];
            return allServices.find(service => service.slug === serviceproviderSlug);
            };
            
            const { service, serviceprovider: serviceproviderSlug } = useParams();
    const serviceArr = serviceContent[service];
    const serviceprovider = serviceArr.services_provide;
    const serviceData = getServiceProviderData(serviceprovider, serviceproviderSlug);
    
    const packages = serviceData.page.packages;
    const [selectPlan ,setselectPlan] = useState("facebook");
    
  return (
    <>
        {packages?
        <section class="lqd-section pricing pb-360 bg-transparent transition-all" id="pricing">
        <div class="container">
            <div class="row justify-center">
                <div class="col col-12 col-md-6 col-lg-7 col-xl-6 relative h-full text-center">
                    <div class="absolute w-auto top-20 module-shape">
                        <div class="lqd-imggrp-single block relative">
                            <div class="w-50percent lqd-imggrp-img-container inline-flex relative items-center justify-center animation-element">
                                <figure class="w-full relative">
                                    <image width="368" height="374" src="../assets/images/demo/start-hub-1/shape-pricing.png" alt="pricing"/>
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div className="ld-fancy-heading relative lqd-unit-animation-done">
                            <h2 className="mb-0/5em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2">
                                <span> Choose a </span>
                                <mark className="lqd-highlight">
                                    <span className="lqd-highlight-txt"> Plan </span>
                                    <span className="lqd-highlight-inner"></span>
                                </mark> 
                            </h2>
                        </div>
                    <div class="ld-fancy-heading relative px-20 xl:px-0 animation-element">
                        <p class="leading-25 ld-fh-element inline-block relative">Boost Your Brand with Our Tailored Social Media Marketing Packages!</p>
                    </div>
                </div>
                <div class="col col-12 p-0" >
                    <div class="module-pricing -mb-340 pt-15 pb-10 sm:m-0">
                        <div class="lqd-tabs lqd-tabs-style-9 lqd-tabs-style-9-alt2 flex flex-col lqd-nav-underline- lqd-tabs-nav-items-not-expanded">
                             <nav class="lqd-tabs-nav-wrap ">
                                <ul class="reset-ul lqd-tabs-nav flex items-center flex-wrap justify-center relative cursor-pointer" role="tablist">
                                    <li className="sm:w-50percent xs:w-full"
                                        onClick={()=>{setselectPlan("facebook")}}>
                                        <a  className="block relative text-center bg-transparent custom-gray-100 border-orange-100 border-1 border-left-0 py-0/5em px-2/25em active"
                                            data-bs-toggle="tab">
                                            <span className="lqd-tabs-nav-txt">Facebook</span>
                                        </a>
                                    </li>
                                    <li className="sm:w-50percent xs:w-full"
                                        onClick={()=>{setselectPlan("instagram")}}>
                                        <a className="block relative text-center bg-transparent custom-gray-100 border-orange-100 border-1 border-left-0 py-0/5em px-2/25em"                                        
                                            data-bs-toggle="tab">
                                            <span className="lqd-tabs-nav-txt">Instagram</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            <div class="lqd-tabs-content relative">
                                <div id="lqd-tab-pricing-monthly" role="tabpanel"
                                    class="lqd-tabs-pane fade active show">
                                    <div class="pt-50 pb-20 px-20 lg:p-0 module-sections">
                                        <div class="container p-0">
                                            <div class="row m-0 card-flex">
                                                <div class="co-12 col-md-4 p-0 card-flex-down">
                                                    <div class="module-section mx-20 flex flex-wrap bg-white rounded-10 border-0 border-gray-100 shadow-top-sm transition-all">
                                                        <div class="w-full flex flex-col items-center border-bottom border-gray-100 transition-all pt-15 pb-5 px-35">
                                                            <div class="mb-20 ld-fancy-heading relative">
                                                                <p class="font-title text-16 font-bold leading-1 tracking-0 -mb-1em text-amber-600 ld-fh-element inline-block relative">Simple Plan</p>
                                                            </div>
                                                            <div class="ld-fancy-heading relative">
                                                                <h3 class="font-title text-46 font-bold mb-20 ld-fh-element inline-block relative">
                                                                    &#x20b9; {packages[selectPlan]?.price?.basic_plan} <small class="font-bold text-13 text-sky-900">/mo</small>
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className='pricing-wraper'>
                                                            <div className='pricing-card'>
                                                                <div class="module-section-bottom w-full flex flex-col pt-30 pb-10 px-20 items-center text-center">
                                                                    <div>
                                                                        <div class="mb-20 ld-fancy-heading relative lg:text-start">
                                                                            <p class="text-16 leading-1/5em mb-0/5em  ld-fh-element inline-block relative">Essential Social Media Boost to Kickstart Your Thriving Online Presence.</p>
                                                                        </div>

                                                                        {packages[selectPlan]?.plans?.map((data , ind)=>{
                                                                            return(
                                                                                <>                                                                                
                                                                            {
                                                                                data?.title?
                                                                                <h3 class="mb-20 text-16 font-normal m-0 pricing-title">{data?.title}</h3>
                                                                                :
                                                                                <div class="w-full mb-20 -mt-5 iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-circle custom-border">
                                                                                    <div class="iconbox-icon-wrap flex w-full mr-15">
                                                                                        {
                                                                                            data?.basic?
                                                                                            <div class={`bg-green-100  w-25 h-25 icon-blue iconbox-icon-container inline-flex relative z-1 rounded-full text-14 text-blue-800 items-center`}>
                                                                                                <i aria-hidden="true" class={`lqd-icn-ess icon-ion-ios-checkmark text-white`}></i>
                                                                                            </div>
                                                                                            :
                                                                                            <div class={`custom-red w-25 h-25 icon-blue iconbox-icon-container inline-flex relative z-1 rounded-full text-14 text-blue-800 items-center`}>
                                                                                                <i aria-hidden="true" class={`lqd-icn-ess icon-ion-ios-close text-white`}></i>
                                                                                            </div>
                                                                                        }
                                                                                        <span class="text-14 font-normal m-0 pl-10 lqd-iconbox-heading text-align-left">{data?.services}</span>
                                                                                    </div>
                                                                                    {
                                                                                    typeof data?.basic !== 'boolean' ?
                                                                                        <div class="iconbox-icon-wrap flex pt-5 w-full ml-30">
                                                                                            <h3 class="text-10 bg-primary pt-5 pb-5 pr-10 rounded-full text-white font-normal m-0 pl-10 flex-wrap lqd-iconbox-heading text-left">{data?.basic}</h3>
                                                                                        </div>
                                                                                        :
                                                                                        ""
                                                                                    }
                                                                                </div>
                                                                                
                                                                            }
                                                                            </>
                                                                            )
                                                                        })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Link to="/contact" class="btn btn-solid btn-sm btn-block btn-icon-right btn-hover-reveal module-btn-1 text-15 mt-15 rounded-6 font-medium leading-2em" style={{backgroundColor: "var(--lqd-color-orange-100)", color: "var(--lqd-color-primary)"}}>
                                                            <span class="btn-txt" data-text="Join Simple Plan">Join Simple Plan</span> <span class="btn-icon">
                                                                <i aria-hidden="true" class="lqd-icn-ess icon-md-arrow-forward"></i>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div class="co-12 col-md-4 p-0 card-flex-down">
                                                    <div
                                                        class="module-section mx-20 flex flex-wrap bg-white rounded-10 border-0 border-gray-100 shadow-top-sm transition-all">
                                                        <div
                                                            class="w-full flex flex-col items-center border-bottom border-gray-100 transition-all pt-15 pb-5 px-35">
                                                            <div class="mb-20 ld-fancy-heading relative">
                                                                <p class="font-title text-16 font-bold leading-1 tracking-0 -mb-1em text-blue-800 ld-fh-element inline-block relative"
                                                                    style={{color:"#1a3760"}}>
                                                                    Standard Plan</p>
                                                            </div>
                                                            <div class="ld-fancy-heading relative">
                                                                <h3 class="font-title text-46 font-bold mb-20 ld-fh-element inline-block relative">
                                                                    &#x20b9; {packages[selectPlan]?.price?.standard_plan} <small class="font-bold text-13 text-sky-900">/mo</small>
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className='pricing-wraper'>
                                                            <div className='pricing-card'>
                                                                <div class="module-section-bottom w-full flex flex-col pt-30 pb-10 px-20 text-center">
                                                                    <div class="mb-20 ld-fancy-heading relative lg:text-start">
                                                                        <p class="text-16 leading-1/5em mb-0/5em  ld-fh-element inline-block relative">Advanced Social Media Strategies for Steady Growth and Engagement.</p>
                                                                    </div>

                                                                    {packages[selectPlan]?.plans?.map((data , ind)=>{
                                                                        return(
                                                                            <>
                                                                               {data?.title?
                                                                                <h3 class="mb-20 text-16 font-normal m-0 pricing-title">{data?.title}</h3>
                                                                                :
                                                                                <div class="w-full mb-20 -mt-5 iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-circle custom-border">
                                                                                    <div class="iconbox-icon-wrap flex  w-full mr-15">
                                                                                        {
                                                                                            data?.standard?
                                                                                            <div class={`bg-green-100  w-25 h-25 icon-blue iconbox-icon-container inline-flex relative z-1 rounded-full text-14 text-blue-800 items-center`}>
                                                                                                <i aria-hidden="true" class={`lqd-icn-ess icon-ion-ios-checkmark text-white`}></i>
                                                                                            </div>
                                                                                            :
                                                                                            <div class={`custom-red w-25 h-25 icon-blue iconbox-icon-container inline-flex relative z-1 rounded-full text-14 text-blue-800 items-center`}>
                                                                                                <i aria-hidden="true" class={`lqd-icn-ess icon-ion-ios-close text-white`}></i>
                                                                                            </div>
                                                                                        }
                                                                                        <span class="text-14 font-normal m-0 pl-10 lqd-iconbox-heading text-align-left">{data?.services}</span>
                                                                                    </div>
                                                                                    {
                                                                                    typeof data?.standard !== 'boolean' ?
                                                                                        <div class="iconbox-icon-wrap flex pt-5 w-full ml-30">
                                                                                            <h3 class="text-10 bg-primary pt-5 pb-5 pr-10 rounded-full text-white font-normal m-0 pl-10 flex-wrap lqd-iconbox-heading text-left">{data?.standard}</h3>
                                                                                        </div>
                                                                                        :
                                                                                        ""
                                                                                    }
                                                                                </div>
                                                                                }
                                                                            </>

                                                                        )
                                                                    })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Link to="/contact" class="btn btn-solid btn-sm btn-block btn-icon-right btn-hover-reveal module-btn-1 text-15 mt-15 rounded-6 font-medium leading-2em" style={{backgroundColor: "var(--lqd-color-orange-100)", color: "var(--lqd-color-primary)"}}>
                                                            <span class="btn-txt" data-text="Join Simple Plan">Join Standard Plan</span> <span class="btn-icon">
                                                                <i aria-hidden="true" class="lqd-icn-ess icon-md-arrow-forward"></i>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div class="co-12 col-md-4 p-0 card-flex-down">
                                                    <div class="module-section mx-20 flex flex-wrap bg-white rounded-10 border-0 border-gray-100 shadow-top-sm transition-all">
                                                        <div class="w-full flex flex-col items-center border-bottom border-gray-100 transition-all pt-15 pb-5 px-35">
                                                            <div class="mb-20 ld-fancy-heading relative">
                                                                <p class="font-title text-16 font-bold leading-1 tracking-0 -mb-1em text-emerlad-500 ld-fh-element inline-block relative">Premium Plan</p>
                                                            </div>
                                                            <div class="ld-fancy-heading relative">
                                                                <h3 class="font-title text-46 font-bold mb-20 ld-fh-element inline-block relative">
                                                                    &#x20b9;  {packages[selectPlan]?.price?.premium_plan} <small class="font-bold text-13 text-sky-900">/mo</small>
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className='pricing-wraper'>
                                                            <div className='pricing-card'>
                                                                <div class="module-section-bottom w-full flex flex-col pt-30 pb-10 px-20 text-center">
                                                                    <div class="mb-20 ld-fancy-heading relative lg:text-start">
                                                                        <p class="text-16 leading-1/5em mb-0/5em  ld-fh-element inline-block relative">Comprehensive Social Media Mastery for Maximum Reach and Impact.</p>
                                                                    </div>

                                                                    {packages[selectPlan]?.plans?.map((data , ind)=>{
                                                                        return(
                                                                            <>
                                                                                {data?.title?
                                                                                <h3 class="mb-20 text-16 font-normal m-0 pricing-title">{data?.title}</h3>
                                                                                :
                                                                                <div class="w-full mb-20 -mt-5 iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-circle custom-border">
                                                                                    <div class="iconbox-icon-wrap flex w-full mr-15">
                                                                                        {
                                                                                            data?.premium?
                                                                                            <div class={`bg-green-100  w-25 h-25 icon-blue iconbox-icon-container inline-flex relative z-1 rounded-full text-14 text-blue-800 items-center`}>
                                                                                                <i aria-hidden="true" class={`lqd-icn-ess icon-ion-ios-checkmark text-white`}></i>
                                                                                            </div>
                                                                                            :
                                                                                            <div class={`custom-red w-25 h-25 icon-blue iconbox-icon-container inline-flex relative z-1 rounded-full text-14 text-blue-800 items-center`}>
                                                                                                <i aria-hidden="true" class={`lqd-icn-ess icon-ion-ios-close text-white`}></i>
                                                                                            </div>
                                                                                        }
                                                                                        <span class="text-14 font-normal m-0 pl-10 lqd-iconbox-heading text-align-left">{data?.services}</span>
                                                                                    </div>
                                                                                    {
                                                                                    typeof data?.premium !== 'boolean' ?
                                                                                        <div class="iconbox-icon-wrap flex pt-5 w-full ml-30">
                                                                                            <h3 class="text-10 bg-primary pt-5 pb-5 pr-10 rounded-full text-white font-normal m-0 pl-10 flex-wrap lqd-iconbox-heading text-left">{data?.premium}</h3>
                                                                                        </div>
                                                                                        :
                                                                                        ""
                                                                                    }
                                                                                </div>
                                                                                }
                                                                            </>
                                                                        )
                                                                    })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Link to="/contact" class="btn btn-solid btn-sm btn-block btn-icon-right btn-hover-reveal module-btn-1 text-15 mt-15 rounded-6 font-medium leading-2em" style={{backgroundColor: "var(--lqd-color-orange-100)", color: "var(--lqd-color-primary)"}}>
                                                            <span class="btn-txt" data-text="Join Simple Plan">Join Premium Plan</span> <span class="btn-icon">
                                                                <i aria-hidden="true" class="lqd-icn-ess icon-md-arrow-forward"></i>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>
        :
        ""
        }
    </>
  )
}
export default ChoosePlan