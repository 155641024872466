import React, { useEffect, useState } from 'react'
import TitlebarInner from './TitlebarInner'
import { useParams } from 'react-router'
import { BenefitsSecAnimation, careerData, creativeServices, creativeServicesMobile } from '../functions/functions';
import FaqAccordion from './FaqAccordion';
import { serviceContent } from '../data/serviceContent';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import { Link } from 'react-router-dom';
import FooterPage from './FooterPage';
import MetaData from './MetaData';
import { metaData } from '../metaData/meta';
import { Navigate } from 'react-router-dom';

export default function Searvices(props) {

    const services = useParams();

    const SearvicesAllData = serviceContent[services.title];
    let pageNotFound = 0;

    if (SearvicesAllData == undefined) {
        pageNotFound = 1;
    }

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
    }, [])

    useEffect(() => {
        SearvicesAllData?.benefits_sec?.benefits_card?.forEach((el, ind) => {
            BenefitsSecAnimation(`.searvicesanimation${ind}`)
        });
        careerData('.first-sec');
        careerData('.second-sec');
        creativeServices('services-provide-box');
    }, [services, SearvicesAllData])

    useEffect(() => {
        ScrollTrigger.matchMedia({
            // desktop
            "(min-width: 1025px)": function () {
                creativeServices('services-provide-box');
            },

            // mobile
            "(max-width: 1024px)": function () {
                creativeServicesMobile('services-provide-box');
            },

            "all": function () {

            }

        });
    }, [services])

    useEffect(() => {
        props.SearvicesAllData?.benefits_sec?.benefits_card?.forEach((el, ind) => {
            BenefitsSecAnimation(`.benefitssecanimation${ind}`)
        });
    }, [props.SearvicesAllData])

    useEffect(() => {
        ScrollTrigger.matchMedia({
            // desktop
            "(min-width: 1200px)": function () {
                gsap.timeline({
                    scrollTrigger: {
                        trigger: ".services-provide-col-1",
                        ease: 'linear',
                        start: 'top bottom',
                        end: 'bottom+=0px top',
                        scrub: 1,
                    }
                }).fromTo(".services-provide-col-1", { ease: 'linear', y: 50, }, { ease: 'linear', y: -150, });
                gsap.timeline({
                    scrollTrigger: {
                        trigger: ".services-provide-col-2",
                        ease: 'linear',
                        start: 'top bottom',
                        end: 'bottom+=0px top',
                        scrub: 1,
                    },
                }).fromTo(".services-provide-col-2", { ease: 'linear', y: -50, }, { ease: 'linear', y: 50, });
                gsap.timeline({
                    scrollTrigger: {
                        trigger: ".services-provide-col-3",
                        ease: 'linear',
                        start: 'top bottom',
                        end: 'bottom+=0px top',
                        scrub: 1,
                    },
                }).fromTo(".services-provide-col-3", { ease: 'linear', y: 50, }, { ease: 'linear', y: -150, });
            },

            // mobile
            "(max-width: 1199px)": function () {

                gsap.timeline({
                    scrollTrigger: {
                        trigger: ".services-provide-col-1",
                        ease: 'linear',
                        start: 'top bottom',
                        end: 'bottom+=0px top',
                        scrub: 1,
                    }
                }).fromTo(".services-provide-col-1", { ease: 'linear', y: 0, }, { ease: 'linear', y: -50, });
                gsap.timeline({
                    scrollTrigger: {
                        trigger: ".services-provide-col-2",
                        ease: 'linear',
                        start: 'top bottom',
                        end: 'bottom+=0px top',
                        scrub: 1,
                    },
                }).fromTo(".services-provide-col-2", { ease: 'linear', y: 0, }, { ease: 'linear', y: -50, });
                gsap.timeline({
                    scrollTrigger: {
                        trigger: ".services-provide-col-3",
                        ease: 'linear',
                        start: 'top bottom',
                        end: 'bottom+=0px top',
                        scrub: 1,
                    },
                }).fromTo(".services-provide-col-3", { ease: 'linear', y: 0, }, { ease: 'linear', y: -50, });

            },

            // all 
            "all": function () {
                // ScrollTriggers created here aren't associated with a particular media query,
                // so they persist.
            }

        });
    }, [])
  
    if (pageNotFound) {
        return <Navigate to="/404" />;
    }
    return (
        <>
            <MetaData metaData={metaData[services.title]} />

            <link rel="stylesheet" href="/assets/css/demo/start-hub-6.css" />
            <TitlebarInner servicetype={services} key={services.title} PageName={SearvicesAllData?.banner?.page_name} PageDec={SearvicesAllData?.banner?.page_dec} />
            <main className="content bg-repeat mainweb-bg-custom" id="lqd-site-content">
                <div id="lqd-contents-wrap">
                    <section className="lqd-section video-box pt-30 pb-50" id="video-tutorials">
                        <div className="container">
                            <div className="row">
                                <div className="col col-12 col-xl-6 relative text-center">
                                    <div className="lqd-imggrp-single block relative module-img" data-float="ease-in">
                                        <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                                            <figure className="w-full relative">
                                                <img width={SearvicesAllData?.first_sec?.imgwidth ? SearvicesAllData?.first_sec?.imgwidth : "1165"} height="" className="custom-aspect-ratio" src={SearvicesAllData?.first_sec?.src} alt="phone" />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-xl-6">
                                    <div className="flex flex-col p-10">
                                        <div className="lqd-imggrp-single block relative mb-5">
                                            <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                                                <figure className="w-full relative">
                                                    <div className="ld-fancy-heading relative w-auto mb-0/65em bg-orange-100 rounded-100 animation-element first-sec">
                                                        <h6 className="ld-fh-element relative m-0 py-10 px-20">Services</h6>
                                                    </div>
                                                </figure>
                                            </div>
                                        </div>
                                        <div className="ld-fancy-heading relative lqd-unit-animation-done first-sec">
                                            {SearvicesAllData?.first_sec?.heading}
                                        </div>
                                        <div className="ld-fancy-heading relative first-sec">
                                            {SearvicesAllData?.first_sec?.pera}
                                        </div>
                                        <Link to="/contact" data-name="Get a Quote" className="button-custom"></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {SearvicesAllData?.services_provide ?
                        <section className="lqd-section icon-box-colored pt-50" id="services">
                            <div className="container">
                                <div className="row">
                                    <div className="w-full flex flex-col justify-center text-center md:w-full">
                                        <div className="ld-fancy-heading relative animation-element lqd-unit-animation-done">
                                            {SearvicesAllData?.services_provide?.heading}
                                        </div>
                                    </div>

                                    <div className="col col-12 col-xl-4 pt-110 px-30 lg:p-0">
                                        {
                                            SearvicesAllData?.services_provide?.col_one?.map((data, ind) => {
                                                return (
                                                    <div key={`provide_col_one${ind}`} className={`relative mb-30 pt-45 pr-35 pb-35 pl-40 rounded-10 icon-box-bg-color transition-all ${data.bg} services-provide-box services-provide-col-1`}>
                                                        <div className="iconbox flex flex-grow-1 relative flex-col iconbox-default iconbox-heading-arrow-onhover iconbox-contents-show-onhover">
                                                            <div className="iconbox-icon-wrap">
                                                                <div className="mb-20 text-65 iconbox-icon-container inline-flex">
                                                                    <img src={`/${data.src}`} alt='' className='w-1em' />
                                                                </div>
                                                            </div>
                                                            <h3 className="lqd-iconbox-heading mb-0/7em text-24"><span>{data.title}
                                                            </span> <svg className="inline-block" xmlns="http://www.w3.org/2000/svg"
                                                                width="12" height="32" viewBox="0 0 12 32">
                                                                    <path fill="currentColor"
                                                                        d="M8.375 16L.437 8.062C-.125 7.5-.125 6.5.438 5.938s1.563-.563 2.126 0l9 9c.562.562.624 1.5.062 2.062l-9.063 9.063c-.312.312-.687.437-1.062.437s-.75-.125-1.063-.438c-.562-.562-.562-1.562 0-2.125z">
                                                                    </path>
                                                                </svg></h3>
                                                            <div className="contents">
                                                                <p className="text-16">{data.dec}</p>
                                                                {
                                                                    services.title === 'digital-marketing' && (
                                                                        <Link to={`${data.slug}`} className="view-more-btn btn font-bold text-14 uppercase tracking-0/5 whitespace-nowrap btn-naked text-primary hover:text-primary btn-hover-txt-liquid-x-alt btn-icon-right btn-hover-swp btn-has-label">
                                                                            <span className="btn-txt">view more</span>
                                                                            <span className="btn-icon">
                                                                                <i className="lqd-icn-ess icon-md-arrow-forward"></i>
                                                                            </span>
                                                                            <span className="btn-icon mr-10">
                                                                                <i className="lqd-icn-ess icon-md-arrow-forward"></i>
                                                                            </span>
                                                                        </Link>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                    <div className="col col-12 col-xl-4 p-30 lg:p-0">
                                        {
                                            SearvicesAllData?.services_provide?.col_two?.map((data, ind) => {
                                                return (
                                                    <div key={`provide_col_two${ind}`} className={`relative mb-30 pt-45 pr-35 pb-35 pl-40 rounded-10 icon-box-bg-color transition-all ${data.bg} services-provide-box services-provide-col-2`}>
                                                        <div className="iconbox flex flex-grow-1 relative flex-col iconbox-default iconbox-heading-arrow-onhover iconbox-contents-show-onhover">
                                                            <div className="iconbox-icon-wrap">
                                                                <div className="mb-20 text-65 iconbox-icon-container inline-flex">
                                                                    <img src={`/${data.src}`} alt='' className='w-1em' />
                                                                </div>
                                                            </div>
                                                            <h3 className="lqd-iconbox-heading mb-0/7em text-24"><span>{data.title}
                                                            </span> <svg className="inline-block" xmlns="http://www.w3.org/2000/svg"
                                                                width="12" height="32" viewBox="0 0 12 32">
                                                                    <path fill="currentColor"
                                                                        d="M8.375 16L.437 8.062C-.125 7.5-.125 6.5.438 5.938s1.563-.563 2.126 0l9 9c.562.562.624 1.5.062 2.062l-9.063 9.063c-.312.312-.687.437-1.062.437s-.75-.125-1.063-.438c-.562-.562-.562-1.562 0-2.125z">
                                                                    </path>
                                                                </svg></h3>
                                                            <div className="contents">
                                                                <p className="text-16">{data.dec}</p>
                                                                {
                                                                    services.title === 'digital-marketing' && (
                                                                        <Link to={`${data.slug}`} className="view-more-btn btn font-bold text-14 uppercase tracking-0/5 whitespace-nowrap btn-naked text-primary hover:text-primary btn-hover-txt-liquid-x-alt btn-icon-right btn-hover-swp btn-has-label">
                                                                            <span className="btn-txt">view more</span>
                                                                            <span className="btn-icon">
                                                                                <i className="lqd-icn-ess icon-md-arrow-forward"></i>
                                                                            </span>
                                                                            <span className="btn-icon mr-10">
                                                                                <i className="lqd-icn-ess icon-md-arrow-forward"></i>
                                                                            </span>
                                                                        </Link>)
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                    <div className="col col-12 col-xl-4 pt-110 px-30 lg:p-0">
                                        {
                                            SearvicesAllData?.services_provide?.col_three?.map((data, ind) => {
                                                return (
                                                    <div key={`provide_col_three${ind}`} className={`relative mb-30 pt-45 pr-35 pb-35 pl-40 rounded-10 icon-box-bg-color transition-all ${data.bg} services-provide-box services-provide-col-3`}>
                                                        <div className="iconbox flex flex-grow-1 relative flex-col iconbox-default iconbox-heading-arrow-onhover iconbox-contents-show-onhover">
                                                            <div className="iconbox-icon-wrap">
                                                                <div className="mb-20 text-65 iconbox-icon-container inline-flex">
                                                                    <img src={`/${data.src}`} alt='' className='w-1em' />
                                                                </div>
                                                            </div>
                                                            <h3 className="lqd-iconbox-heading mb-0/7em text-24"><span>{data.title}
                                                            </span> <svg className="inline-block" xmlns="http://www.w3.org/2000/svg"
                                                                width="12" height="32" viewBox="0 0 12 32">
                                                                    <path fill="currentColor"
                                                                        d="M8.375 16L.437 8.062C-.125 7.5-.125 6.5.438 5.938s1.563-.563 2.126 0l9 9c.562.562.624 1.5.062 2.062l-9.063 9.063c-.312.312-.687.437-1.062.437s-.75-.125-1.063-.438c-.562-.562-.562-1.562 0-2.125z">
                                                                    </path>
                                                                </svg></h3>
                                                            <div className="contents">
                                                                <p className="text-16">{data.dec}</p>
                                                                {
                                                                    services.title === 'digital-marketing' && (

                                                                        <Link to={`${data.slug}`} className="view-more-btn btn font-bold text-14 uppercase tracking-0/5 whitespace-nowrap btn-naked text-primary hover:text-primary btn-hover-txt-liquid-x-alt btn-icon-right btn-hover-swp btn-has-label">
                                                                            <span className="btn-txt">view more</span>
                                                                            <span className="btn-icon">
                                                                                <i className="lqd-icn-ess icon-md-arrow-forward"></i>
                                                                            </span>
                                                                            <span className="btn-icon mr-10">
                                                                                <i className="lqd-icn-ess icon-md-arrow-forward"></i>
                                                                            </span>
                                                                        </Link>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                        :
                        ''
                    }

                    {SearvicesAllData?.benefits_sec ?
                        <section className="lqd-section icon-box pt-50 pb-50 bg-center bg-no-repeat custom-career-section2-bg bg-separator"
                            id="help-center">
                            <div className="container-fluid">
                                <div className="row justify-center">
                                    <div className="col col-12 text-center p-0">
                                        <div className="ld-fancy-heading relative lqd-unit-animation-done">
                                            {SearvicesAllData?.benefits_sec?.heading}
                                        </div>
                                    </div>
                                    <div className="col col-12">
                                        <div className="module-container flex flex-wrap justify-between mx-auto w-1100 md:w-full">
                                            {SearvicesAllData?.benefits_sec?.benefits_card?.map((data, ind) => {
                                                return (
                                                    <div key={`benefits_card${ind}`} className="lqd-iconbox-scale w-48percent mb-65 transition-all">
                                                        <div className="iconbox flex flex-grow-1 relative iconbox-side iconbox-square iconbox-heading-arrow-onhover text-start">
                                                            <div className="iconbox-icon-wrap">
                                                                <div className="iconbox-icon-container inline-flex relative z-1 w-80 h-80 text-30 rounded-20 bg-white items-center justify-center">
                                                                    <img src={`/${data.src}`} className={`w-2600 searvicesanimation${ind}`} alt={`benefits_card${ind}`} />
                                                                </div>
                                                            </div>
                                                            <div className="contents">
                                                                <h3 className="lqd-iconbox-heading text-20 mb-0/85em">
                                                                    <span className={`searvicesanimation${ind}`}>{data.title} </span>
                                                                </h3>
                                                                <p className={`searvicesanimation${ind}`}>{data.content}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        : ""
                    }

                    {SearvicesAllData?.second_sec_left ?
                        <section className="lqd-section video-box pt-30 pb-50" id="video-tutorials1">
                            <div className="container">
                                <div className="row items-centers">
                                    <div className="col col-12 col-xl-6 order-rev">
                                        <div className="flex flex-col p-10">
                                            <div className="lqd-imggrp-single block relative mb-5">
                                                <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                                                    <figure className="w-full relative">
                                                        <div className="ld-fancy-heading relative w-auto mb-0/65em bg-orange-100 rounded-100 animation-element second-sec">
                                                            <h6 className="ld-fh-element relative m-0 py-10 px-20">Services</h6>
                                                        </div>
                                                    </figure>
                                                </div>
                                            </div>
                                            <div className="ld-fancy-heading relative lqd-unit-animation-done second-sec">
                                                {SearvicesAllData?.second_sec_left?.heading}
                                            </div>
                                            <div className="ld-fancy-heading relative second-sec">
                                                {SearvicesAllData?.second_sec_left?.pera}
                                            </div>
                                            <Link to="/contact" data-name="Get a Quote" className="button-custom"></Link>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-xl-6 relative text-center">
                                        <div className="lqd-imggrp-single block relative module-img" data-float="ease-in">
                                            <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                                                <figure className="w-full relative">
                                                    <img width="620" height="" className="custom-aspect-ratio" src={SearvicesAllData?.second_sec_left?.src} alt="phone" />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        :
                        ""
                    }

                    {SearvicesAllData?.industries_serve ?
                        <section className="lqd-section pt-50 pb-70 bg-separator" id="serve">
                            <div className="container-fluid px-5percent">
                                <div className="row">
                                    <div className="ld-fancy-heading relative animation-element flex text-center col-xl-12 col-md-12 col-12 col justify-custom-center lqd-unit-animation-done">
                                        <h2 className="ld-fh-element mb-0/5em inline-block relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2">
                                            <mark className="lqd-highlight">
                                                <span className="lqd-highlight-txt">Industries</span>
                                                <span className="lqd-highlight-inner"></span>
                                            </mark>
                                            &nbsp;We Serve
                                        </h2>
                                    </div>
                                    <div className="col col-12">
                                        <div className="row justify-content-center">
                                            {SearvicesAllData?.industries_serve?.industries?.map((data, ind) => {
                                                return (
                                                    <div key={`industries${ind}`} className="col-6 col-md-4 col-lg-2 px-30 py-15">
                                                        <div className="client-item text-center animate__animated animate__zoomIn">
                                                            <img className="common-image" src={`../${data.src}`} alt={`industries${ind}`} />
                                                            <p>{data.dec}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        :
                        ''
                    }

                    {/* FaqAccordion Section */}
                    {SearvicesAllData?.faq_data ?
                        <section className="lqd-section faq bg-white transition-all pt-30 pb-30">
                            <div className="container">
                                <div className=" items-center justify-center text-center">
                                    <div className="ld-fancy-heading relative animation-element lqd-unit-animation-done">
                                        <h2 className="mb-0/75em ld-fh-element relative  lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2">
                                            <mark className="lqd-highlight">
                                                <span className="lqd-highlight-txt">Frequently</span>
                                                <span className="lqd-highlight-inner"></span>
                                            </mark>
                                            <span>&nbsp;Asked Questions</span>
                                        </h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col col-12 p-0">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col col-12 col-md-6 p-30 sm:p-0 mb-30">
                                                    <div className="accordion accordion-md accordion-side-spacing accordion-title-circle accordion-expander-lg accordion-active-has-shadow accordion-active-has-fill" id="accordion-question-parent-1" role="tablist" aria-multiselectable="true">
                                                        <FaqAccordion key="faq_one" activeOne={0} Faq={SearvicesAllData.faq_data.faq_one} />
                                                    </div>
                                                </div>
                                                <div className="col col-12 col-md-6 p-30 sm:p-0 mb-30">
                                                    <div className="accordion accordion-md accordion-side-spacing accordion-title-circle accordion-expander-lg accordion-active-has-shadow accordion-active-has-fill" id="accordion-question-parent-2" role="tablist" aria-multiselectable="true">
                                                        <FaqAccordion key="faq_two" keys="faq_two" Faq={SearvicesAllData.faq_data.faq_two} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>
                        : ""
                    }
                </div>
            </main>
            {/* <FooterPage /> */}
        </>
    )
}