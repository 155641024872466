
// Career page ( Great People Deserve Great Benefits section ) START
export const CareerBenefitsData = [
    { src: 'icons/(1)Be-bold.png', h3: 'Be Bold', p: 'Think big, embrace risks, and voice your ideas confidently.' },
    { src: 'icons/(4)Move-fast.png', h3: 'Move Fast', p: 'Develop rapidly, embrace failure, and accelerate learning—time is valuable.' },
    { src: 'icons/(2)Focus-on-impact.png', h3: 'Focus On Impact', p: 'Provide genuine value and consistently exceed expectations.' },
    { src: 'icons/(5)Stay-Hungry.png', h3: 'Stay Hungry', p: 'Stay curious, acquire new skills, and continuously evolve.' },
    { src: 'icons/(3)Seek-excellence.png', h3: 'Seek Excellence', p: 'Strive for innovation, quality, and mastery.' },
    { src: 'icons/(6)Embrace-humanity.png', h3: 'Embrace Humanity', p: 'Show compassion, embrace diversity, and support others' },
]
