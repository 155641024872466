import React, { useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import TitlebarInner from './TitlebarInner';
import axios from 'axios';
import BlogFaqAccordion from './BlogFaqAccordion';
import { blog } from "../data/blogs";
import FooterPage from './FooterPage';
import MetaData from './MetaData';

function BlogContent() { 
    let pageNotFound = 0;

    function filterBlogsByDate(blog) {
        const currentDate = new Date();
        return blog.filter(blog => {
            const blogDate = new Date(blog.created_at);
            return blogDate <= currentDate;
        });
    }
    
    const blogs = filterBlogsByDate(blog);
    const blog_url = useParams();
    const [blogdata, setBlogdata] = useState([]);

    const generateUrl = (title) => {
        return title
            .toLowerCase()
            .replace(/\//g, '-')
            .replace(/ /g, '-')
            .replace(/:/g, '')
            .replace(/\?/g, '');
    };

    let Blogopen = '';
    for (let index = 0; index < blogs.length; index++) {
        if (generateUrl(blogs[index].title) === blog_url.blogcontent) {
            Blogopen = blogs[index];
        }
    }

    if (Blogopen === '') pageNotFound = 1;
    
    useEffect(() => {
        axios.get('https://cleverotech.com/blogs/blogs.json')
            .then(response => {
                const filteredData = response.data.blogs.filter((data) => {
                    const titleSlug = data.title.toLowerCase()
                        .replace(/\//g, '-')
                        .replace(/ /g, '-')
                        .replace(/:/g, '');
                    return titleSlug === blog_url.blogcontent;
                });

                setBlogdata(filteredData);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, [blog_url]);

    const [searchQuery, setSearchQuery] = useState("");
    
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const getHighlightedText = (text, highlight) => {
        if (!highlight.trim()) {
            return text;
        }
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return parts.map((part, index) =>
            part.toLowerCase() === highlight.toLowerCase() ? <span key={index} style={{ color: '#f48840' }}>{part}</span> : part
        );
    };

    const filteredBlogs = blogs.filter(blog =>
        blog.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const truncateTitle = (title) => {
        return title.length > 48 ? title.substring(0, 48) + "..." : title;
    };

    if (pageNotFound) {
        return <Navigate to="/404" />;
    }

    return (
        <>  
            <MetaData metaData={Blogopen.meta_tags} />
            <TitlebarInner key="blog-content" PageName="" />
            <main className="content bg-top-center bg-cover" id="lqd-blog-content">
                <div id="lqd-contents-wrap">
                    <section className="blog-posts custom-px-150">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="all-blog-posts">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="blog-post">
                                                    <div className="blog-thumb">
                                                        <img src={`../assets/images/${Blogopen.src}`} alt="images" />
                                                    </div>
                                                    <div className="down-content">
                                                        <h1 className='blog-h1'>{Blogopen.title}</h1>
                                                        <a className="post-info" href="">
                                                            <span>{Blogopen.created_at}</span>
                                                        </a>
                                                        {Blogopen.content}
                                                        <h2 className="blog-h2" style={{ backgroundColor: '#ffffff', lineHeight: '1.92', marginBottom: '0pt', marginTop: '14pt', padding: '0pt 0pt 6pt' }}> FAQ </h2>
                                                        {Blogopen.faq_data ?
                                                            <div className="row">
                                                                <div className="col col-12 p-0">
                                                                    <div className="container">
                                                                        <div className="row">
                                                                            <div className="col col-12 col-md-12">
                                                                                <div className="accordion accordion-md accordion-side-spacing accordion-title-circle accordion-expander-lg accordion-active-has-shadow accordion-active-has-fill" id="accordion-question-parent-1" role="tablist" aria-multiselectable="true">
                                                                                    <BlogFaqAccordion activeOne={0} Faq={Blogopen.faq_data.faq_one} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 custom-px-50">
                                    <div className="sidebar">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="sidebar-item search">
                                                    <form id="search_form" name="gs" method="GET" action="#">
                                                        <input
                                                            type="text"
                                                            className="searchText"
                                                            placeholder="type to search..."
                                                            autoComplete="on"
                                                            value={searchQuery}
                                                            onChange={handleSearchChange}
                                                        />
                                                    </form>
                                                </div>
                                            </div>
                                            {/* blog list start */}
                                            <div className="col-lg-12">
                                                <div className="sidebar-item recent-posts">
                                                    <div className="sidebar-heading">
                                                        <h2>All Blogs</h2>
                                                    </div>
                                                    <div className="content blog-side-content">
                                                        <ul style={{ paddingLeft: '0' }}>
                                                            {filteredBlogs.map((data, ind) => {
                                                                const url = generateUrl(data.title);
                                                                if (url === blog_url.blogcontent) {
                                                                    return null;
                                                                }
                                                                return (
                                                                    <li key={ind}>
                                                                        <Link
                                                                            className='d-flex justify-content-between'
                                                                            to={`/${url}`}
                                                                        >
                                                                            <img
                                                                                src={`../assets/images/${data.src}`}
                                                                                alt="images"
                                                                                width="150"
                                                                                height="150"
                                                                                style={{ paddingRight: "15px" }}
                                                                            />
                                                                            <div className=''>
                                                                                <h5 className='blog-li-h5'>
                                                                                    {getHighlightedText(truncateTitle(data.title), searchQuery)}
                                                                                </h5>
                                                                                <span style={{ color: "#aaaaaa" }}>{data.created_at}</span>
                                                                            </div>
                                                                        </Link>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* blog list end */}
                                            {/* category start here */}
                                            <div className="col-lg-12 col-12">
                                                <div className="sidebar-item recent-posts">
                                                    <div className="sidebar-heading">
                                                        <h2>Category</h2>
                                                    </div>
                                                    <div className="content blog-side-content">
                                                        <ul style={{ paddingLeft: '0' }} className='blogCategory-li-a'>
                                                        {
                                                            Array.from(new Set(blogs.map(data => data.categoryName))).map((categoryName, index) => {
                                                                const bgClass = categoryName === Blogopen.categoryName ? 'selected-blogCategory' : '';
                                                                return (
                                                                    <li key={index} style={{ listStyleType: 'none', color: '#1a3760d4' }}>
                                                                        <Link to={`/blog/category/${categoryName.toLowerCase().replace(/\//g, '-').replace(/ /g, '-').replace(/:/g, '')}`} style={{ color: '#1a3760d4', fontWeight: '400' }} className={`nav-link ld-fh-element relative m-0 ${bgClass}`}>
                                                                            {categoryName}
                                                                        </Link>
                                                                    </li>
                                                                );
                                                            })
                                                        }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* category end here */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            {/* <FooterPage /> */}
        </>
    );
}

export default BlogContent;
