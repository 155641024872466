import React, {useEffect} from 'react'
import TitlebarInner from './TitlebarInner'
import { useParams,Navigate } from 'react-router'
import { serviceContent } from '../data/serviceContent';
import { BenefitsSecAnimation, careerData,} from '../functions/functions';
import ChoosePlan from './searvice-content/ChoosePlan';
import FaqAccordion from './FaqAccordion';
import { Link } from 'react-router-dom';
import FooterPage from './FooterPage';
import MetaData from './MetaData';
import { metaData } from '../metaData/meta';

const getServiceProviderData = (serviceprovider, serviceproviderSlug) => {
    if (!serviceprovider) {
        console.error('Service provider is undefined');
        return null;
    }
    
    const { col_one = [], col_two = [], col_three = [] } = serviceprovider;
    
    const allServices = [
        ...col_one,
        ...col_two,
        ...col_three
    ];
    
    return allServices.find(service => service.slug === serviceproviderSlug);
};

export default function SearvicesProviderPage() {

    const url_slug = useParams();
    const { service, serviceprovider: serviceproviderSlug } = useParams();
    const serviceArr = serviceContent[service];

    let serviceData;
    if (serviceArr) {
        const serviceprovider = serviceArr.services_provide;        
        serviceData = getServiceProviderData(serviceprovider, serviceproviderSlug);
    }
    // const serviceprovider = serviceArr.services_provide;
    // const serviceData = getServiceProviderData(serviceprovider, serviceproviderSlug);

    useEffect(()=>{
        if (serviceData) {
            serviceData.page.benefits_sec.benefits_card?.forEach((el , ind) => {
                BenefitsSecAnimation(`.benefitssecanimation${ind}`);
            });
        }
    },[serviceData])

    useEffect(()=>{
        careerData('.first-sec');
        careerData('.second-sec');
    },[url_slug])
    if (!serviceArr|| !serviceData) {
        return <Navigate to="/404" />;
    }
    return (
    <>
        <MetaData metaData={metaData[url_slug.serviceprovider]} />
        <TitlebarInner key={serviceData?.page?.banner?.key} PageName={serviceData?.page?.banner?.page_name} PageDec={serviceData?.page?.banner?.page_dec} />
        <main className="content bg-top-center bg-cover blog-background-image" id="lqd-site-content">
                <div id="lqd-contents-wrap">
                    {serviceData?.page?.first_sec ?
                        <section className="lqd-section what-we-do my-50" id="what-we-do">
                            <div className="container">
                                <div className="row items-center">
                                    <div className="col col-12 col-md-5 col-xl-5 p-0 flex flex-row flex-wrap justify-center items-center ca-initvalues-applied lqd-animations-done" data-custom-animations="true" data-ca-options="{&quot;animationTarget&quot;: &quot;.lqd-imggrp-single&quot;, &quot;ease&quot;: &quot;power4.out&quot;, &quot;initValues&quot;: {&quot;scaleX&quot; : 0.8, &quot;scaleY&quot; : 0.8, &quot;opacity&quot; : 0} , &quot;animations&quot;: {&quot;scaleX&quot; : 1, &quot;scaleY&quot; : 1, &quot;opacity&quot; : 1}}">
                                        <div className="lqd-imggrp-single" data-float="ease-in">
                                            <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center ">
                                                <figure className="w-full relative">
                                                    <img width={serviceData?.page?.first_sec?.imgwidth ? serviceData?.page?.first_sec?.imgwidth : "600"} height="596" src={serviceData?.page?.first_sec?.src} alt="" />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-xl-7 col-md-7 p-0">
                                        <div className="w-full flex flex-col items-start pr-5percent pl-10percent module-content">
                                            <div className="abt-sub-head0 ld-fancy-heading relative w-auto mb-0/65em bg-orange-100 rounded-100 animation-element first-sec">
                                                <h6 className="ld-fh-element relative  m-0 py-10 px-20">Identity</h6>
                                            </div>
                                            <div className="ld-fancy-heading relative lqd-unit-animation-done first-sec">
                                                {serviceData?.page?.first_sec?.heading}
                                            </div>
                                            <div className="ld-fancy-heading relative first-sec">
                                                {serviceData?.page?.first_sec?.pera}
                                            </div>
                                            <Link to="/contact" data-name="Get a Quote" className="button-custom"></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        :
                        ""
                    }

                    {/* start benifit here */}
                    {serviceData?.page?.benefits_sec ?
                        <section className="lqd-section icon-box pt-50 bg-center bg-no-repeat custom-career-section2-bg" id="help-center">
                            <div className="container-fluid">
                                <div className="row justify-center">
                                    <div className="col col-12 text-center p-0">
                                        <div className="ld-fancy-heading relative lqd-unit-animation-done">
                                            {serviceData?.page?.benefits_sec?.heading}
                                        </div>
                                    </div>
                                    <div className="col col-12">
                                        <div className="module-container flex flex-wrap justify-between mx-auto w-1100 md:w-full">
                                            {serviceData?.page?.benefits_sec?.benefits_card?.map((data, ind) => {
                                                return (
                                                    <div key={`benefits_card${ind}`} className="lqd-iconbox-scale w-48percent mb-65 transition-all">
                                                        <div className="iconbox flex flex-grow-1 relative iconbox-side iconbox-square iconbox-heading-arrow-onhover text-start">
                                                            <div className="iconbox-icon-wrap">
                                                                <div className="iconbox-icon-container inline-flex relative z-1 w-80 h-80 text-30 rounded-20 bg-white items-center justify-center">
                                                                    <img src={`../../${data.src}`} className={`w-2600 benefitssecanimation${ind}`} alt={`benefits_card${ind}`} />
                                                                </div>
                                                            </div>
                                                            <div className="contents">
                                                                <h3 className="lqd-iconbox-heading text-20 mb-0/85em">
                                                                    <span className={`benefitssecanimation${ind}`}>{data.title} </span>
                                                                </h3>
                                                                <p className={`benefitssecanimation${ind}`}>{data.content}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        : ""
                    }
                    {/* end benifit here */}

                    {serviceData?.page?.expert_sec ?
                        <section className="lqd-section pt-45 pb-55 bg-transparent  text-box-number">
                            <div className="container-fluid">
                                <div className="row" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <div className="w-full flex flex-col relative items-center max-w-full text-center mx-auto" style={{ paddingBottom: '5%' }}>
                                        <div className="ld-fancy-heading relative mask-text lqd-unit-animation-done">
                                            {serviceData?.page?.expert_sec?.heading}
                                        </div>
                                    </div>
                                    {serviceData?.page?.expert_sec?.cards?.map((data, ind) => {
                                        return (
                                            <>
                                                <div className="col-lg-4 px-50  mt-lg-50">
                                                    <div className={`relative flex flex-col ${(ind + 1) % 3 === 2 ? 'mt-100' : ""} ${(ind + 1) % 3 === 0 ? 'mt-200' : ""}  module-box-2 animation-element md:m-0`}>
                                                        <h6 className="number-style ld-fancy-heading ld-fh-outline ld-fh-element m-0 text-190 leading-1em font-bold text-gray-100 -rotate-90 md:rotate-0">
                                                            <span className="ld-fh-txt-outline">{`${ind < 9 ? "0" : ""}` + (ind + 1)}</span>{`${ind < 9 ? "0" : ""}` + (ind + 1)}
                                                        </h6>
                                                        <div className="ld-fancy-heading relative">
                                                            <h3 className="ld-fh-element mb-0/85em">{data?.title}</h3>
                                                        </div>
                                                        <div className="ld-fancy-heading relative">
                                                            <p className="ld-fh-element text-14 leading-1/8em mb-0/5em">{data?.pera}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        </section>
                        :
                        ""
                    }

                    {serviceData?.page?.process_sec ?
                        <section class="lqd-section video-box pt-30 pb-50" id="video-tutorials1">
                            <div class="container">
                                <div class="row items-centers flex items-center content-center justify-center">
                                    <div class="col col-12 col-xl-6 relative text-center">
                                        <div class="lqd-imggrp-single block relative module-img">
                                            <div class="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                                                <figure class="w-full relative">
                                                    <img width={serviceData?.page?.process_sec?.imgwidth ? serviceData?.page?.process_sec?.imgwidth : "620"} class="custom-aspect-ratio" src={serviceData?.page?.process_sec?.src} alt="Google Ads" />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col col-12 col-xl-6 order-rev">
                                        <div class="lqd-imggrp-single block relative mb-5">
                                            <div class="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                                                <figure class="w-full relative">
                                                    <div class="ld-fancy-heading relative w-auto mb-0/65em bg-orange-100 rounded-100 animation-element">
                                                        <h6 class="ld-fh-element relative m-0 py-10 px-20">Process</h6>
                                                    </div>
                                                </figure>
                                            </div>
                                        </div>
                                        <div class="ld-fancy-heading relative lqd-unit-animation-done">
                                            <h2 class="mb-0/5em ld-fh-element inline-block relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span>Our Process for creating effective </span><mark class="lqd-highlight"><span class="lqd-highlight-txt">Google Ads Marketing </span><span class="lqd-highlight-inner"></span></mark><span>Strategies</span></h2>
                                        </div>
                                        <div class="flex flex-col p-10">
                                            <div class="ld-fancy-heading relative mask-text">
                                                {serviceData?.page?.process_sec?.pera}
                                            </div>
                                            <div class="lqd-tabs lqd-tabs-style-6 lqd-tabs-nav-iconbox lqd-tabs-nav-icon-inline flex flex-wrap lqd-tabs-nav-items-not-expanded flex-row-reverse 497627e5 lqd-nav-underline-">
                                                <nav class="lqd-tabs-nav-wrap">
                                                    <ul class="reset-ul lqd-tabs-nav flex flex-col lqd-css-sticky relative link-medium link-17"
                                                        role="tablist">
                                                        {serviceData?.page?.process_sec?.procesing?.map((data, ind) => {
                                                            return (
                                                                <li data-controls={`lqd-tab-item-${ind + 1}`} role="presentation">
                                                                    <a class={`block pt-1em pb-1em rounded-4 ${ind + 1 === 1 ? "active" : ""}`}
                                                                        href={`#lqd-tab-item-${ind + 1}`} aria-expanded="true"
                                                                        aria-controls={`lqd-tab-item-${ind + 1}`} role="tab" data-bs-toggle="tab">
                                                                        <span
                                                                            class="lqd-tabs-nav-icon-icon inline-flex items-center justify-center mr-1em rounded-full">
                                                                            <i aria-hidden="true" class={`lqd-icn-ess icon-num-${ind + 1}`}></i>
                                                                        </span>
                                                                        <span class="lqd-tabs-nav-txt">{data}</span>
                                                                    </a>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        :
                        ""
                    }

                    <ChoosePlan />

                    {serviceData?.page?.second_sec_left ?
                        <section className="lqd-section video-box pt-30 pb-50" id="video-tutorials1">
                            <div className="container">
                                <div className="row items-center">
                                    <div className="col col-12 col-xl-6 order-rev">
                                        <div className="flex flex-col p-10">
                                            <div className="lqd-imggrp-single block relative mb-5">
                                                <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                                                    <figure className="w-full relative">
                                                        <div className="ld-fancy-heading relative w-auto mb-0/65em bg-orange-100 rounded-100 animation-element second-sec">
                                                            <h6 className="ld-fh-element relative m-0 py-10 px-20">Services</h6>
                                                        </div>
                                                    </figure>
                                                </div>
                                            </div>
                                            <div className="ld-fancy-heading relative lqd-unit-animation-done second-sec">
                                                {serviceData?.page?.second_sec_left?.heading}
                                            </div>
                                            <div className="ld-fancy-heading relative second-sec">
                                                {serviceData?.page?.second_sec_left?.pera}
                                            </div>
                                            <Link to="/contact" data-name="Get a Quote" className="button-custom"></Link>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-xl-6 relative text-center">
                                        <div className="lqd-imggrp-single" data-float="ease-in">
                                            <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center ">
                                                <figure className="w-full relative">
                                                    <img width={serviceData?.page?.second_sec_left?.imgwidth ? serviceData?.page?.second_sec_left?.imgwidth : "600"} height="596" src={serviceData?.page?.second_sec_left?.src} alt="" />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        :
                        ""
                    }

                    {serviceData?.page?.faq_data ?
                        <section className="lqd-section faq bg-white transition-all pt-30 pb-30">
                            <div className="container">
                                <div className=" items-center justify-center text-center">
                                    <div className="ld-fancy-heading relative animation-element lqd-unit-animation-done">
                                        <h2 className="mb-0/75em ld-fh-element relative  lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><mark className="lqd-highlight"><span className="lqd-highlight-txt">Frequently</span> <span className="lqd-highlight-inner"></span></mark><span>&nbsp;Asked Questions</span></h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col col-12 p-0">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col col-12 col-md-6 p-30 sm:p-0 mb-30">
                                                    <div className="accordion accordion-md accordion-side-spacing accordion-title-circle accordion-expander-lg accordion-active-has-shadow accordion-active-has-fill" id="accordion-question-parent-1" role="tablist" aria-multiselectable="true">
                                                        <FaqAccordion activeOne={0} Faq={serviceData?.page?.faq_data?.faq_one} />
                                                    </div>
                                                </div>
                                                <div className="col col-12 col-md-6 p-30 sm:p-0 mb-30">
                                                    <div className="accordion accordion-md accordion-side-spacing accordion-title-circle accordion-expander-lg accordion-active-has-shadow accordion-active-has-fill" id="accordion-question-parent-2" role="tablist" aria-multiselectable="true">
                                                        <FaqAccordion Faq={serviceData?.page?.faq_data?.faq_two} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>
                        : ""
                    }

                </div>
            </main>
      
    </>
  )
}