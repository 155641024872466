import React, { useEffect, useState } from 'react';
import { Option, Select } from '@mui/joy';
import { serviceContent } from '../data/serviceContent';
import axios from 'axios';

const serviceArr = Object.keys(serviceContent).map(key => serviceContent[key]?.card?.servicetitle);

function ContactForm() {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        number: "",
        city: "",
        services: "",
        message: ""
    });

    const [formErrors, setFormErrors] = useState({
        name: false,
        email: false,
        numberValidate: false,
        city: false,
        services: false,
        message: false,
        emailValidate: false
    });

    const [showAlert, setShowAlert] = useState(false);
    const [formValid, setFormValid] = useState(false);

    const formIsValidate = () => {
        const { name, email, number, city, services, message } = formData;
        const isValid = name.trim() !== "" &&
            email.trim() !== "" &&
            number.trim() !== "" &&
            city.trim() !== "" &&
            services.trim() !== "" &&
            message.trim() !== "" &&
            !formErrors.emailValidate &&
            !formErrors.numberValidate;
        setFormValid(isValid);
    };

    useEffect(() => {
        formIsValidate();
    }, [formData, formErrors, formIsValidate]);

    const validateEmail = (email) => {
        const validEmail = email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        const isInvalidEmail = email.trim() !== "" && !validEmail;
        setFormErrors(prevErrors => ({ ...prevErrors, emailValidate: isInvalidEmail }));
    };

    const numberValidate = (number) => {
        const isInvalidNumber = number.length !== 10;
        setFormErrors(prevErrors => ({ ...prevErrors, numberValidate: isInvalidNumber }));
    };

    const clearForm = () => {
        setFormData({
            name: "",
            email: "",
            number: "",
            city: "",
            services: "",
            message: ""
        });

        setFormErrors({
            name: false,
            email: false,
            numberValidate: false,
            city: false,
            services: false,
            message: false,
            emailValidate: false
        });

        setFormValid(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const { name, email, number, city, services, message } = formData;
        const trimmedData = {
            name: name ? name.trim() : "",
            email: email ? email.trim() : "",
            number: number ? number.trim() : "",
            city: city ? city.trim() : "",
            services: services ? services.trim() : "",
            message: message ? message.trim() : ""
        };

        setFormData(trimmedData);

        const errors = {
            name: trimmedData.name === "",
            email: trimmedData.email === "",
            number: trimmedData.number === "",
            city: trimmedData.city === "",
            services: trimmedData.services === "",
            message: trimmedData.message === ""
        };

        setFormErrors(errors);

        if (!errors.email) {
            validateEmail(trimmedData.email);
        }

        if (!errors.number) {
            numberValidate(trimmedData.number);
        }
        if (formValid) {

            // console.log("Data filled and sent mail to user");

            // Perform form submission logic here
            // Example: Send form data using Axios
            /*
            axios.post('https://example.com/api/contact', formData)
                .then(response => {
                    console.log('Form submitted successfully:', response.data);
                    // Handle success response as needed
                })
                .catch(error => {
                    console.error('Error submitting form:', error);
                    // Handle error response as needed
                });
            */
            setShowAlert(true);
            clearForm();

            setTimeout(() => {
                setShowAlert(false);
            }, 3000);
        }
    };

    return (
        <>
            {showAlert && (
                <div className="alert alert-success alert-dismissible fade show fixed-top-contact" role="alert">
                    Form submitted successfully!
                </div>
            )}
            <form className="lqd-cf-form init contact-form" data-status="init" noValidate>
                <div className="row -mx-15">

                    <div className="col-lg-6">
                        <div className="col-md-12 px-15 animation-element lqd-unit-animation-done">
                            <span className="lqd-form-control-wrap name">
                                <input type="text" name="name" size="40" value={formData.name} onChange={(e) => { setFormData({ ...formData, name: e.target.value }); setFormErrors({ ...formErrors, name: false }); formIsValidate(); }} className="lqd-cf-form-control text-17 leading-1/7em text-gray-700" placeholder="Name" />
                                <div className={`border-bottom ${formErrors.name ? 'border-b-red' : ''}`} />
                            </span>
                        </div>
                        <div className="col-md-12 px-15 animation-element lqd-unit-animation-done">
                            <span className="lqd-form-control-wrap">
                                <input type="email" name="email" size="40" value={formData.email} onChange={(e) => { setFormData({ ...formData, email: e.target.value }); setFormErrors({ ...formErrors, email: false }); validateEmail(e.target.value); formIsValidate(); }} className={`lqd-cf-form-control text-17 leading-1/7em text-gray-700`} placeholder="Email" />
                                <div className={`border-bottom ${formErrors.email || formErrors.emailValidate ? 'border-b-red' : ''}`} />
                                {formErrors.emailValidate && <p className='accept-files clr-red'>Please enter a valid email address...</p>}
                            </span>
                        </div>
                        <div className="col-md-12 px-15 animation-element lqd-unit-animation-done">
                            <span className="lqd-form-control-wrap">
                                <input type="number" name="number" size="40" value={formData.number} onChange={(e) => { setFormData({ ...formData, number: e.target.value }); setFormErrors({ ...formErrors, numberValidate: false }); numberValidate(e.target.value); formIsValidate(); }} className={`lqd-cf-form-control text-17 leading-1/7em text-gray-700`} placeholder="Number" />
                                <div className={`border-bottom ${formErrors.number || formErrors.numberValidate ? 'border-b-red' : ''}`} />
                                {formErrors.numberValidate && <p className='accept-files clr-red'>Please enter 10 digits !</p>}
                            </span>
                        </div>
                        <div className="col-md-12 px-15 animation-element lqd-unit-animation-done">
                            <span className="lqd-form-control-wrap">
                                <input type="text" name="city" placeholder="City" size="40" value={formData.city} onChange={(e) => { setFormData({ ...formData, city: e.target.value }); setFormErrors({ ...formErrors, city: false }); formIsValidate(); }} className={`lqd-cf-form-control text-17 leading-1/7em text-gray-700 `} />
                                <div className={`border-bottom ${formErrors.city ? 'border-b-red' : ''}`} />
                            </span>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="col-md-12 px-15 animation-element lqd-unit-animation-done">
                            <Select
                                onChange={(e, val) => { setFormData({ ...formData, services: val }); setFormErrors({ ...formErrors, services: false }); formIsValidate(); }}
                                value={formData.services}
                                placeholder="Choose one…"
                                className={`select-box`}
                                sx={{ marginTop: "3px", borderRadius: "0", fontSize: "18px", color: "#000 !important", padding: "5px 0 5px 0", backgroundColor: "#fff", '&:hover': { backgroundColor: "#fff" } }}
                                variant="plain"
                            >
                                {
                                    serviceArr?.map((data, ind) => {
                                        return (
                                            <Option key={ind} value={data.replace(/-/g, ' ')}>{data.replace(/-/g, ' ')}</Option>
                                        );
                                    })
                                }
                            </Select>
                            <div className={`border-bottom ${formErrors.services ? 'border-b-red' : ''}`} />
                        </div>
                        <div className="col-md-12 px-15 animation-element lqd-unit-animation-done">
                            <span className="lqd-form-control-wrap message" style={{ paddingTop: "10px" }}>
                                <textarea name="message" cols="10" rows="6" value={formData.message} onChange={(e) => { setFormData({ ...formData, message: e.target.value }); setFormErrors({ ...formErrors, message: false }); formIsValidate(); }} className={`lqd-cf-form-control text-17 leading-1/7em text-gray-700`} placeholder="Message"></textarea>
                                <div className={`border-bottom ${formErrors.message ? 'border-b-red' : ''}`} />
                            </span>
                        </div>
                    </div>

                    <div className="col-md-6 mt-1/5rem px-15 animation-element lqd-unit-animation-done">
                        <p>By clicking Send a message button, you agree to use our “Form” terms And consent cookie usage in browser.</p>
                    </div>

                    <div className="col-md-6 mt-1/5rem text-md-right px-15 animation-element lqd-unit-animation-done input-filled">
                        <input onClick={handleSubmit} type="submit" value="Send a message" className={`lqd-cf-form-control has-spinner bg-primary border-0 text-14 font-bold tracking-0/5 uppercase text-white ${!formValid ? "contact-form-btn" : ""}`} />
                    </div>

                </div>

                <div className="lqd-cf-response-output" aria-hidden="true"></div>

            </form>
        </>
    );
}

export default ContactForm;
