import React, { useEffect } from 'react';
import TitlebarInner from './TitlebarInner';
import GetStarted from './GetStarted';
import gsap from 'gsap';
import { homeContentSec, } from '../functions/functions';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import CarouselAbout from './CarouselAbout';
import CountUp from "react-countup";
import { Link } from 'react-router-dom';
import FooterPage from './FooterPage';
import { metaData } from "../metaData/meta";
import MetaData from './MetaData';

export default function AboutPage() {

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
    }, [])

    useEffect(() => {
        gsap.timeline({
            scrollTrigger: {
                trigger: '.shape-quote',
                ease: 'linear',
                start: 'top bottom',
                end: 'bottom+=0px top',
                scrub: 1
            },
        }).fromTo('.shape-quote', { x: 80, y: 80 }, { x: 0, y: -110, scrub: 1, ease: 'linear' });
    }, [])

    useEffect(() => {
        homeContentSec(".abt-sub-head1", 130)
        homeContentSec(".abt-sub-head2", 130)
        homeContentSec(".abt-sub-head0", 130)
    }, []);

    useEffect(() => {
        gsap.timeline({
            scrollTrigger: {
                trigger: ".img-indicate",
                ease: 'linear',
                start: 'top bottom',
                end: 'bottom+=0px top',
                scrub: 1,
            },
        }).fromTo(".img-indicate", { ease: 'linear', y: 0, }, { ease: 'linear', y: 165, });
    }, [])

    return (
        <>
            <MetaData metaData={metaData["aboutUs"]} />

            <TitlebarInner key="AboutPage" PageName="About" PageDec="We solve our clients' problems and search for easy, smart solutions!" />
            <main className="content bg-top-center bg-cover content-Separate main-tag" id="lqd-site-content">
                <div id="lqd-contents-wrap">
                    <section className="lqd-section what-we-do my-50" id="what-we-do">
                        <div className="container">
                            <div className="row items-center">
                                <div className="col col-12 col-md-5 col-xl-5 p-0 flex flex-row flex-wrap justify-center items-center ca-initvalues-applied lqd-animations-done">
                                    <div className="lqd-imggrp-single" data-float="ease-in">
                                        <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center transform-style-3d">
                                            <figure className="w-full relative">
                                                <img width="492" height="596" src="/assets/images/about/who-we-are.svg" alt="Web Development" />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-xl-7 col-md-7 p-0">
                                    <div className="w-full flex flex-col items-start pr-5percent pl-10percent module-content">
                                        <div className="abt-sub-head0 ld-fancy-heading relative w-auto mb-0/65em bg-orange-100 rounded-100 animation-element">
                                            <h6 className="ld-fh-element relative  m-0 py-10 px-20">Identity</h6>
                                        </div>
                                        <div className="ld-fancy-heading relative lqd-unit-animation-done">
                                            <h2 className="mb-0/5em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2">
                                                <span></span>
                                                <mark className="lqd-highlight">
                                                    <span className="lqd-highlight-txt">Who </span>
                                                    <span className="lqd-highlight-inner"></span>
                                                </mark> We Are
                                            </h2>
                                        </div>
                                        <div className="ld-fancy-heading relative">
                                            <p className="text-16 mb-2/5em ld-fh-element relative">Founded in 2008, Clevero Technology is your <Link to="/about" className="text-manual-primary"> Web & Software Development Company.</Link> Clevero Technology delivers prevailing yet cost-effective Web and Software Development Services. We build compelling Android and iOS Applications. Growth-oriented eCommerce Web Development, User-friendly UI/UX Designing, Digital Marketing, and SEO Services to increase your business ROI. Here are <Link className="text-manual-primary" to="/services"> Our Services.</Link> We've served clients in various industries including healthcare, banking, finance, education, entertainment, e-commerce, logistics, retail, travel, manufacturing, legal consulting, and insurance, among others.</p>
                                        </div>
                                        <Link to="/contact" data-name="View More" className="button-custom"></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="lqd-section about py-50 bg-gray-200" id="about">
                        <div className="container">
                            <div className="row text-center">
                                <div className="col col-md-10 offset-md-1 mb-60 p-0 module-first">
                                    <div className="w-full flex flex-col items-center">
                                        <div className="ld-fancy-heading relative lqd-unit-animation-done">
                                            <h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover">
                                                <span style={{ display: 'block' }}> We Improve Businesses By Providing</span>
                                                <span> Dependable </span>
                                                <mark className="lqd-highlight"><span className="lqd-highlight-txt">Digital Solutions</span>
                                                    <span className="lqd-highlight-inner"></span></mark>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-xl-3">
                                    <div className="iconbox flex flex-grow-1 relative flex-col iconbox-default mb-30 border-right border-lightgray"
                                        style={{ paddingRight: "20px", height: "246px" }}>
                                        <div className="iconbox-icon-wrap">
                                            <div className="iconbox-icon-container inline-flex mb-25 text-86">
                                                <img src="/assets/images/about/(1)Easy-Setup.png" className="custom-w-23per"
                                                    alt="" />
                                            </div>
                                        </div>
                                        <div className="contents">
                                            <h3 className="lqd-iconbox-heading text-20 mb-20">Easy Setup</h3>
                                            <p>Simplified setup process for quick and hassle-free implementation.</p>
                                        </div>
                                        <div className="img-indicate lqd-imggrp-single block absolute right-0 top-10percent sm:hidden md:hidden about-second-section">
                                            <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                                                <figure className="w-full relative"><img width="3" height="22" src="/assets/images/Line-8664.svg" alt="3D shape line" /></figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-xl-3">
                                    <div className="mb-30 iconbox flex flex-grow-1 relative flex-col iconbox-default border-right border-lightgray"
                                        style={{ paddingRight: "20px", height: "246px" }}>
                                        <div className="iconbox-icon-wrap">
                                            <div className="iconbox-icon-container inline-flex mb-25 text-86">
                                                <img src="/assets/images/about/(2)Live-Editing.png" className="custom-w-23per" alt="" />
                                            </div>
                                        </div>
                                        <div className="contents">
                                            <h3 className="lqd-iconbox-heading text-20 mb-20">Live Editing </h3>
                                            <p>Make real-time changes and updates to your project while it's live, ensuring seamless adjustments and improvements.</p>
                                        </div>
                                        <div className="img-indicate lqd-imggrp-single block absolute right-0 top-10percent md:hidden sm:hidden about-second-section">
                                            <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                                                <figure className="w-full relative">
                                                    <img width="3" height="22" src="/assets/images/Line-8664.svg" alt="3D shape line" /></figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-xl-3">
                                    <div className="mb-30 iconbox flex flex-grow-1 relative flex-col iconbox-default border-right border-lightgray"
                                        style={{ paddingRight: "20px", height: "246px" }}>
                                        <div className="iconbox-icon-wrap">
                                            <div className="iconbox-icon-container inline-flex mb-25 text-86">
                                                <img src="/assets/images/about/(3)Browser-Support.png" className="custom-w-23per" alt="" />
                                            </div>
                                        </div>
                                        <div className="contents">
                                            <h3 className="lqd-iconbox-heading text-20 mb-20">Browser Support</h3>
                                            <p>Universal compatibility for optimal user experience on any browser.</p>
                                        </div>
                                        <div className="img-indicate lqd-imggrp-single block absolute right-0 top-10percent md:hidden sm:hidden about-second-section">
                                            <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                                                <figure className="w-full relative">
                                                    <img width="3" height="22" src="/assets/images/Line-8664.svg" alt="3D shape line" /></figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-xl-3">
                                    <div className="mb-30 iconbox flex flex-grow-1 relative flex-col iconbox-default "
                                        style={{ paddingRight: "20px", height: "246px" }}>
                                        <div className="iconbox-icon-wrap">
                                            <div className="iconbox-icon-container inline-flex mb-25 text-86">
                                                <img src="/assets/images/about/(4)Speed-Performance.png" className="custom-w-23per" alt="" />
                                            </div>
                                        </div>
                                        <div className="contents">
                                            <h3 className="lqd-iconbox-heading text-20 mb-20">Speed Performance </h3>
                                            <p>We prioritise Speeds Performance  for seamless user experiences in all our projects.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="lqd-section digital-experience-counter py-50" id="top2">
                        <div className="container">
                            <div className="row algin-items-center">
                                <div className="col col-12 col-xl-5">
                                    <div className="w-full flex flex-col items-start lg:text-center lg:items-center">
                                        <div className="abt-sub-head1 ld-fancy-heading relative w-auto mb-0/65em bg-orange-100 rounded-100">
                                            <h6 className="ld-fh-element relative  m-0 py-10 px-20">Ongoing</h6>
                                        </div>
                                        <div className="ld-fancy-heading relative lqd-unit-animation-done">
                                            <h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover">
                                                <span> Hundreds of Completed Projects, Still </span>
                                                <mark className="lqd-highlight">
                                                    <span className="lqd-highlight-txt"> Counting.. </span>
                                                    <span className="lqd-highlight-inner"></span>
                                                </mark>
                                            </h2>
                                        </div>
                                        <div className="ld-fancy-heading relative">
                                            <p className="text-16 leading-1/5em mb-2/5em ld-fh-element relative">We've
                                                successfully completed hundreds of projects and continue to deliver
                                                outstanding results for our clients. Our track record speaks for itself,
                                                showcasing our commitment to excellence and customer satisfaction.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-xl-6 offset-xl-1 p-0">
                                    <div
                                        className="px-10percent flex items-center align-content-center h-full relative module-counter">
                                        <section
                                            className="lqd-section counter-boxes bg-white text-center box-shadow-bottom transition-all rounded-16 z-2 relative w-full">
                                            <div className="container-fluid p-0">
                                                <div className="row m-0">
                                                    <div className="col col-12 col-md-6 p-0">
                                                        <div
                                                            className="flex flex-col w-full h-full align-contents-center justify-center border-right border-black-10 sm:border-right-0">
                                                            <div
                                                                className="counter-box text-title m-0 py-35 border-bottom border-black-10">
                                                                <div className="lqd-counter relative center lqd-counter-default">

                                                                    <div className="text-48 font-bold mb-15 lqd-counter-element relative counter-wrapper">
                                                                        <span className="block">
                                                                            <CountUp start={0} end={19} duration={10} className="counter" />+
                                                                        </span>
                                                                    </div>
                                                                    <span className="lqd-counter-text text-text">Total Top
                                                                        Services</span>
                                                                </div>
                                                            </div>
                                                            <div className="counter-box text-title m-0 py-35 sm:border-bottom border-black-10">
                                                                <div className="lqd-counter relative center lqd-counter-default">
                                                                    <div className="text-48 font-bold mb-15 lqd-counter-element relative">
                                                                        <span className="block">
                                                                            <CountUp duration={10} className="counter" end={98} />%
                                                                        </span>
                                                                    </div>
                                                                    <span className="lqd-counter-text text-text">Positive
                                                                        Feedback</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col col-12 col-md-6 p-0">
                                                        <div className="flex flex-col w-full h-full align-contents-center justify-center">
                                                            <div className="counter-box text-title m-0 py-35 border-bottom border-black-10">
                                                                <div className="lqd-counter relative center lqd-counter-default">
                                                                    <div className="text-48 font-bold mb-15 lqd-counter-element relative">
                                                                        <span className="block"><CountUp duration={10} className="counter" end={27} />+</span>
                                                                    </div>
                                                                    <span className="lqd-counter-text text-text">Countries</span>
                                                                </div>
                                                            </div>
                                                            <div className="counter-box text-title m-0 py-35">
                                                                <div className="lqd-counter relative center lqd-counter-default">
                                                                    <div className="text-48 font-bold mb-15 lqd-counter-element relative">
                                                                        <span className="block">
                                                                            <CountUp duration={10} className="counter" end={2663} />
                                                                        </span>
                                                                    </div>
                                                                    <span className="lqd-counter-text text-text">Usual
                                                                        Users</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <div className="absolute ltr-left-0 bottom-0 w-auto z-1">
                                            <div className="lqd-imggrp-single block relative">
                                                <div
                                                    className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                                                    <figure className="w-full relative"><img
                                                        src="/assets/images/shape-dots.svg"
                                                        alt="shape" /></figure>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="lqd-section testimonials pt-60 pb-50" id="testimonials">
                        <div className="container">
                            <div className="row">
                                <div className="col col-12 col-xl-6">
                                    <div className="w-full flex flex-col items-start text-start">
                                        <div className="abt-sub-head2 ld-fancy-heading relative w-auto mb-0/65em bg-orange-100 rounded-100 animation-element">
                                            <h6 className="ld-fh-element relative m-0 py-10 px-20">Testimonials</h6>
                                        </div>
                                        <div className="ld-fancy-heading relative lqd-unit-animation-done">
                                            <h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2">
                                                <mark className="lqd-highlight">
                                                    <span className="lqd-highlight-txt">Feedback</span>
                                                    <span className="lqd-highlight-inner" style={{ transitionDelay: '0ms' }}></span>
                                                </mark>
                                                <span> From Our Clients</span>
                                            </h2>
                                        </div>
                                        <CarouselAbout key="CarouselAbout" />
                                    </div>
                                </div>
                                <div className="col col-12 col-xl-6 lg:order-first module-last">
                                    <div className="flex align-content-center items-center relative w-full h-full">
                                        <div className="module-shape-1 absolute top-65 left-20">
                                            <div className="lqd-imggrp-single block relative">
                                                <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                                                    <figure className="w-full relative">
                                                        <img src="/assets/images/shape-quote.svg" className='shape-quote' alt="quote" />
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-parallax="true" className="z-2 w-auto -mr-10percent">
                                            <div className="lqd-imggrp-single block relative" data-float="ease-in">
                                                <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                                                    <figure className="w-full relative">
                                                        <img width="1650" height="972" src="/assets/images/about/feedback.svg" alt="testimonials" />
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <GetStarted pera="As an award-winning company, we continually challenge ourselves for improvement. Trust us to be your authentic brand." />
                </div>
            </main>
            {/* <FooterPage/> */}
        </>
    );
}
