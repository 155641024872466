import React from 'react'

export default function FormBottom() {

    // const [cookies , setcookies] = useState(true);

  return (
    <>
    {/* cookies */}
    {/* <div id="lqd-gdpr" className={`lqd-gdpr ${cookies? 'lqd-gdpr-visible' : "lqd-gdpr-hidden"} lqd-gdpr-ready`}>
        <div className="lqd-gdpr-inner flex items-center">
            <div className="lqd-gdpr-left text-14">🍪 This website uses cookies to improve your web experience.</div>
            <div className="lqd-gdpr-right"><button onClick={()=>{setcookies(false)}} className="lqd-gdpr-accept tracking-0/3 uppercase font-bold">Accept</button>
            </div>
        </div>
    </div> */}

    <div id="contact-modal" className="lity-modal lqd-modal lity-hide" data-modal-type="fullscreen">
        <div className="lqd-modal-inner">
            <div className="lqd-modal-head"></div>
            <section className="lqd-section project-start-bg lqd-modal-content link-black bg-center bg-cover bg-norepeat">
                <div className="container p-0">
                    <div className="row m-0 min-h-100vh items-center">
                        <div className="col col-12 col-md-6 pr-100 module-content">
                            <div className="ld-fancy-heading">
                                <h2 className="ld-fh-element mb-0/75em text-122 leading-0/8em font-medium text-black">Send a
                                    <span> message.</span>
                                </h2>
                            </div>
                            <div className="ld-fancy-heading">
                                <p className="text-18 ld-fh-element">We're here to answer any question you may have.</p>
                            </div>
                            <div className="spacer w-full sm:hidden">
                                <div className="w-full h-140"></div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-5 offset-md-1">
                            <div
                                className="lqd-contact-form lqd-contact-form-inputs-underlined lqd-contact-form-button-lg lqd-contact-form-button-block">
                                <div role="form">
                                    <div className="screen-reader-response">
                                        <p role="status" aria-live="polite" aria-atomic="true"></p>
                                    </div>
                                    <form className="lqd-cf-form" novalidate="novalidate" data-status="init" id="firm-form">
                                        <p><span className="lqd-form-control-wrap">
                                                <input
                                                    className="border-black-20 text-black text-14 font-normal bg-transparent"
                                                    type="text" placeholder="Your Name"/>
                                            </span>
                                            <span className="lqd-form-control-wrap">
                                                <input
                                                    className="border-black-20 text-black text-14 font-normal bg-transparent"
                                                    inputmode="numeric" type="number" maxlength="10"
                                                    placeholder="Your Contact number"/>
                                            </span>
                                            <span className="lqd-form-control-wrap">
                                                <input
                                                    className="border-black-20 text-black text-14 font-normal bg-transparent"
                                                    type="email"
                                                    placeholder="Your email address"/>
                                            </span>
                                            <span className="lqd-form-control-wrap">
                                                <input
                                                    className="border-black-20 text-black text-14 font-normal bg-transparent"
                                                    type="text" placeholder="Subject"/>
                                            </span>
                                            <span className="lqd-form-control-wrap">
                                                <textarea className="border-black-20 text-black text-13" name="message"
                                                    id="hmessage" cols="10" rows="6" placeholder="Your message"></textarea>
                                            </span>
                                            <span className="lqd-form-control-wrap">
                                                <span className="lqd-cf-acceptance">
                                                    <span>
                                                        <label>
                                                            <input type="checkbox" name="acceptance" value="1"
                                                                aria-invalid="false"/>
                                                            <span className="lqd-cf-list-item-label">
                                                                I am bound by the terms of the Service I accept Privacy
                                                                Policy
                                                            </span>
                                                        </label>
                                                    </span>
                                                </span>
                                            </span>
                                            <button type="submit" id="firm_button"
                                                className="has-spinner lqd-cf-submit border-none text-16 font-bold uppercase tracking-0/5 text-white bg-primary hover:bg-primary">Send
                                                email</button>
                                        </p>
                                    </form>
                                    <div className="lqd-cf-response-output"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="lqd-modal-foot"></div>
        </div>
    </div>

    <div className="lqd-cc lqd-cc--inner fixed pointer-events-none"></div>

    <div className="lqd-cc--el lqd-cc-solid lqd-cc-explore flex items-center justify-center rounded-full fixed pointer-events-none">
        <div className="lqd-cc-solid-bg flex absolute lqd-overlay"></div>
        <div className="lqd-cc-solid-txt flex justify-center text-center relative">
            <div className="lqd-cc-solid-txt-inner">Explide</div>
        </div>
    </div>

    <div
        className="lqd-cc--el lqd-cc-solid lqd-cc-drag flex items-center justify-center rounded-full fixed pointer-events-none">
        <div className="lqd-cc-solid-bg flex absolute lqd-overlay"></div>
        <div className="lqd-cc-solid-ext lqd-cc-solid-ext-left inline-flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className='fix-h-w'>
                <path fill="currentColor"
                    d="M19.943 6.07L9.837 14.73a1.486 1.486 0 0 0 0 2.25l10.106 8.661c.96.826 2.457.145 2.447-1.125V7.195c0-1.27-1.487-1.951-2.447-1.125z">
                </path>
            </svg></div>
        <div className="lqd-cc-solid-txt flex justify-center text-center relative">
            <div className="lqd-cc-solid-txt-inner">Drag</div>
        </div>
        <div className="lqd-cc-solid-ext lqd-cc-solid-ext-right inline-flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className='fix-h-w'>
                <path fill="currentColor"
                    d="M11.768 25.641l10.106-8.66a1.486 1.486 0 0 0 0-2.25L11.768 6.07c-.96-.826-2.457-.145-2.447 1.125v17.321c0 1.27 1.487 1.951 2.447 1.125z">
                </path>
            </svg></div>
    </div>

    <div className="lqd-cc--el lqd-cc-arrow inline-flex items-center fixed top-0 left-0 pointer-events-none"><svg width="80"
            height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M60.4993 0V4.77005H8.87285L80 75.9207L75.7886 79.1419L4.98796 8.35478V60.4993H0V0H60.4993Z" />
        </svg>
    </div>

    <div className="lqd-cc--el lqd-cc-custom-icon rounded-full fixed pointer-events-none">
        <div className="lqd-cc-ci inline-flex items-center justify-center rounded-full relative">
            <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" className='fix-h-w'> <path fill="currentColor" fillRule="evenodd" clipRule="evenodd"
                    d="M16.03 6a1 1 0 0 1 1 1v8.02h8.02a1 1 0 1 1 0 2.01h-8.02v8.02a1 1 0 1 1-2.01 0v-8.02h-8.02a1 1 0 1 1 0-2.01h8.02v-8.01a1 1 0 0 1 1.01-1.01z">
                </path>
            </svg></div>
    </div>

    <div className="lqd-cc lqd-cc--outer fixed top-0 left-0 pointer-events-none"></div>

    <template id="lqd-temp-sticky-header-sentinel">
        <div className="lqd-sticky-sentinel invisible absolute pointer-events-none"></div>
    </template>

    {/* start project model */}
    <div className="lity" role="dialog" aria-label="Dialog Window (Press escape to close)" tabIndex="-1"
        data-modal-type="fullscreen">
        <div className="lity-wrap" data-lity-close role="document">
            <div className="lity-loader" aria-hidden="true">Loading...</div>
            <div className="lity-container">
                <div className="lity-content"></div>
            </div><button className="lity-close" type="button" aria-label="Close (Press escape to close)"
                data-lity-close>&times;</button>
        </div>
    </div>
    </>
  )
}