import { Link } from "react-router-dom";

export const serviceContent = {
    //#################### web development page 1 #######################
    'web-development': {
        first_sec: {
            src: "/assets/images/service/sub-services/web-development.svg",
            heading: <><h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span>Driving Your Business Forward With Clevero</span><mark className="lqd-highlight"><span className="lqd-highlight-txt">Web Development</span><span className="lqd-highlight-inner"></span></mark></h2></>,
            pera: <><p className="ld-fh-element inline-block relative mb-1em text-16">For over 16 years, <Link to="/"><strong className="text-primary"> Clevero Technology</strong></Link> has been at the forefront of building innovative web products. We specialise in delivering comprehensive web development services that leverage the latest technological advancements. Our team of expert developers excels in executing diverse project types, from customer-facing web apps to internal management portals and e-commerce stores featuring augmented reality capabilities. Our unique focus is on delivering high-quality apps through a transparent and efficient development process.</p></>
        },
        card: {
            iconsrc: '(1)Web-Development.png',
            servicetitle: 'Web Development',
            servicecontent: 'Our web development services focus on creating visually appealing and user-friendly websites tailored to meet your specific business needs. From responsive designs to seamless navigation, we ensure that your online presence stands out.'
        },
        banner: {
            page_name: "Web Development Services",
            page_dec: "Web Development Services",
            key: "web development"
        },
        services_content: { // services_content section  1
            src: "assets/images/service/sub-services/web-development.svg",
            heading: {
                span1: `Driving Your Business Forward With Clevero`,
                mark: `Web Development`,
                span2: ``
            },
            dec: `For over 16 years, <strong className="text-primary"> Clevero Technology </strong> has been at the forefront of building innovative web products. We specialise in delivering comprehensive web development services that leverage the latest technological advancements. Our team of expert developers excels in executing diverse project types, from customer-facing web apps to internal management portals and e-commerce stores featuring augmented reality capabilities. Our unique focus is on delivering high-quality apps through a transparent and efficient development process.`,
        },
        benefits_sec: { // benefits_sec section  2
            heading: <><h2 className="mb-0/75em ld-fh-element relative items-center justify-center lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><mark className="lqd-highlight"><span className="lqd-highlight-txt"> Web Development</span><span className="lqd-highlight-inner"></span></mark><span> Benefits </span></h2></>,
            benefits_card: [
                { src: "assets/images/service/icon/web-development-banefits-icons/improved-user-experience.svg", title: 'Improved User Experience', content: 'Provide visitors with a seamless and intuitive browsing experience, leading to increased engagement and satisfaction.' },
                { src: "assets/images/service/icon/web-development-banefits-icons/better-branding-oportunities.svg", title: 'Better Branding Opportunities', content: 'Showcase your brand identity and values effectively, creating a memorable and recognizable brand image.' },
                { src: "assets/images/service/icon/web-development-banefits-icons/cost-effectiveness.svg", title: 'Cost-Effectiveness', content: 'Maximise your ROI with a cost-effective and efficient web development solution that delivers tangible results for your business.' },
                { src: "assets/images/service/icon/web-development-banefits-icons/global-reach.svg", title: 'Global Reach', content: 'Expand your reach beyond geographical boundaries and tap into new markets with an accessible and responsive website that caters to a global audience.' },
                { src: "assets/images/service/icon/web-development-banefits-icons/access-to-analytics-and-nsights.svg", title: 'Access to Analytics and Insights', content: 'Gain valuable insights into visitor behaviour and preferences, allowing you to optimise your website and marketing strategies for better results.' },
                { src: "assets/images/service/icon/web-development-banefits-icons/enhanced-security-seasures.svg", title: 'Enhanced Security Measures', content: 'Prioritize user data protection with cutting-edge security features to preserve the integrity and credibility of your brand while fostering trust and defending against cyber threats.' }
            ]
        },
        faq_data: { // faq_data section  3
            faq_one: [
                { title: "What is web development?", content: "Web development refers to the process of creating and maintaining websites. It involves various tasks such as web design, coding, content creation, and website optimization." },
                { title: "How do you choose the right web development technology for my project?", content: "The choice of web development technology depends on factors such as project requirements, budget, scalability, and timeline. Our team evaluates these factors and recommends the most suitable technology stack for your project." },
                { title: "Can you redesign an existing website?", content: "Yes, we offer website redesign services to update and improve the look, functionality, and user experience of existing websites. Our team works closely with clients to understand their goals and implement design changes that align with their brand and objectives." },
            ],
            faq_two: [
                { title: "What is the process for launching a website?", content: "The process for launching a website involves several steps, including planning, design, development, testing, and deployment. Our team works closely with clients throughout each phase of the project to ensure a smooth and successful launch." },
                { title: "What are the different types of web development services?", content: "The different types of web development services include front-end development, back-end development, full-stack development, e-commerce development, CMS (Content Management System) development, and custom web application development." },
                { title: "What is the difference between front-end and back-end development?", content: "Front-end development involves designing and coding the visual elements of a website that users interact with, such as layouts, buttons, and forms. Back-end development involves building the server-side components of a website, such as databases, servers, and application logic." },
            ]
        },
    },

    //#################### crm development page 3 #######################
    'crm-development': {
        first_sec: {
            src: "/assets/images/service/sub-services/crm-development.svg",
            heading: <><h2 className="mb-0/5em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"> <span>Empower Your Business <br /> with</span> <mark className="lqd-highlight"><span className="lqd-highlight-txt">Custom CRM</span> <span className="lqd-highlight-inner"></span></mark><br /> <span> Solutions</span></h2></>,
            pera: <><p className="ld-fh-element inline-block relative mb-1em text-16">CRM development services encompass the creation, customization, integration, and maintenance of Customer Relationship Management (CRM) systems tailored to meet the specific needs of businesses across various industries. These services typically involve the utilisation of cutting-edge technologies and methodologies to design and implement CRM solutions that optimise customer interactions, streamline processes, and enhance overall efficiency. Additionally, CRM development services may include ongoing support, training, and updates to ensure the CRM system remains aligned with evolving business requirements and objectives.</p></>
        },
        card: {
            iconsrc: '(3)CRM-Development.png',
            servicetitle: 'CRM Development',
            servicecontent: 'We specialise in developing customised Customer Relationship Management (CRM) systems to help businesses effectively manage and nurture their customer relationships. Our solutions streamline processes and enhance customer satisfaction.'
        },
        banner: {
            page_name: "CRM Development Services",
            page_dec: "CRM Development Services",
            key: "crm development"
        },
        services_content: { // services_top_content section  1
            src: "assets/images/service/sub-services/crm-development.svg",
            heading: {
                span1: `Empower Your Business with`,
                mark: `Custom CRM`,
                span2: `Solutions`
            },
            dec: `CRM development services encompass the creation, customization, integration, and maintenance of Customer Relationship Management (CRM) systems tailored to meet the specific needs of businesses across various industries. These services typically involve the utilisation of cutting-edge technologies and methodologies to design and implement CRM solutions that optimise customer interactions, streamline processes, and enhance overall efficiency. Additionally, CRM development services may include ongoing support, training, and updates to ensure the CRM system remains aligned with evolving business requirements and objectives.`
        },

        // services_provide: {},

        benefits_sec: { // benefits_sec section  3
            heading: <><h2 className="mb-0/75em ld-fh-element relative items-center justify-center text-center lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span>Benefits of Our</span> <br /> <mark className="lqd-highlight"><span className="lqd-highlight-txt">CRM Development</span> <span className="lqd-highlight-inner"></span></mark><span>Services</span></h2></>,
            benefits_card: [
                { src: "assets/images/service/icon/crm-development-benefits/improved-customer-relationships.svg", title: 'Improved Customer Relationships', content: 'CRM development services enable businesses to centralise customer data, interactions, and preferences in one place. This allows for more personalised communication and better understanding of customer needs, leading to stronger relationships and increased customer satisfaction.' },
                { src: "assets/images/service/icon/crm-development-benefits/increased-efficiency.svg", title: 'Increased Efficiency', content: 'CRM development services automate routine tasks such as data entry, lead management, and sales tracking, freeing up time for employees to focus on more strategic activities. This boosts overall productivity and efficiency within the organisation.' },
                { src: "assets/images/service/icon/crm-development-benefits/centralised-data-management.svg", title: 'Centralised Data Management', content: ' CRM development services centralised customer data, making it easily accessible to relevant teams across the organisation. This ensures consistency and accuracy of information, reducing errors and duplication of efforts.' },
                { src: "assets/images/service/icon/crm-development-benefits/scalability.svg", title: 'Scalability', content: 'As businesses grow, CRM systems can scale to accommodate increasing volumes of data, users, and transactions. This ensures that the CRM solution remains effective and efficient in supporting business operations over time.' },
                { src: "assets/images/service/icon/crm-development-benefits/better-lead-management.svg", title: 'Better Lead Management', content: 'CRM systems help businesses track and manage leads throughout the sales pipeline, from initial contact to conversion. This ensures that no opportunities fall through the cracks and allows for more effective lead nurturing and follow-up.' },
                { src: "assets/images/service/icon/crm-development-benefits/insightful-reporting-and-analytics.svg", title: 'Insightful Reporting and Analytics', content: 'CRM systems generate detailed reports and analytics dashboards that provide valuable insights into sales performance, customer behaviour, and market trends. These insights empower businesses to refine strategies, optimize processes, and drive continuous improvement.' },
            ]
        },
        faq_data: { // faq_data section  3
            faq_one: [
                { title: "What is CRM?", content: "CRM, short for Customer Relationship Management, refers to both a strategy and a set of technologies utilized by businesses to manage and analyze interactions with customers throughout their journey with the company. The overarching goal of CRM is to enhance customer relationships, satisfaction, and ultimately drive business growth." },
                { title: "What are the 3 types of CRM?", content: "The three types of CRM are Operational CRM, which focuses on automating sales, marketing, and service processes; Analytical CRM, which involves analysing customer data for insights; and Collaborative CRM, which emphasises communication and collaboration with customers across multiple channels." },
                { title: "What types of businesses can benefit from CRM?", content: "Businesses of all sizes and industries can benefit from CRM. Whether you're a small startup or a large enterprise, CRM can help you better understand and serve your customers, streamline operations, and drive business growth." }
            ],
            faq_two: [
                { title: "How to Choose the Right CRM for Your Business ?", content: "To choose the right CRM for your business, start by assessing your specific needs and goals. Consider factors such as your business size, industry, budget, and required features. Research different CRM options, evaluate their capabilities, and compare pricing and customer reviews. Additionally, seek input from key stakeholders and consider scalability and integration capabilities. Ultimately, choose a CRM solution that aligns with your business requirements and provides the flexibility and scalability to support your growth." },
                { title: "Can CRM be customised to suit my business needs?", content: "Yes, many CRM systems offer customization options to tailor the platform to your specific business needs. You can customise workflows, data fields, reports, dashboards, and more to align with your unique processes and requirements." }
            ]
        },
    },

    //#################### api development page 4 #######################
    'api-development': {
        first_sec: {
            src: "/assets/images/service/sub-services/api-development.svg",
            heading: <><h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"> <mark className="lqd-highlight"><span className="lqd-highlight-txt">Seamless Integration,</span> <span className="lqd-highlight-inner"></span></mark> <span> Infinite Possibilities: Our <br />API Services</span></h2></>,
            pera: <><p className="ld-fh-element inline-block relative mb-1em text-16"> We specialise in crafting custom API solutions tailored to meet your unique business needs. Our team of experienced developers works closely with you to understand your requirements and design APIs that seamlessly integrate with your existing systems and applications. Whether you need to streamline internal processes, enhance data exchange with external partners, or create new revenue streams through API monetization, we have the expertise to deliver innovative solutions that drive business growth and success. With a focus on reliability, scalability, and security, we ensure that your custom API development project is executed to the highest standards, delivering tangible results and empowering your business for the future.</p></>
        },
        card: {
            iconsrc: '(4)API-Development.png',
            servicetitle: 'API Development',
            servicecontent: 'Our API development services focus on building robust and scalable Application Programming Interfaces (APIs) that seamlessly integrate with your existing systems. We ensure smooth data exchange and enhanced functionality.'
        },
        banner: {
            page_name: "API Development Services",
            page_dec: "API Development Services",
            key: "api development"
        },
        services_content: { // services_content section  1
            src: "assets/images/service/sub-services/api-development.svg",
            heading: {
                span1: ``,
                mark: `Seamless Integration,`,
                span2: `Infinite Possibilities: Our API Services`
            },
            dec: `We specialise in crafting custom API solutions tailored to meet your unique business needs. Our team of experienced developers works closely with you to understand your requirements and design APIs that seamlessly integrate with your existing systems and applications. Whether you need to streamline internal processes, enhance data exchange with external partners, or create new revenue streams through API monetization, we have the expertise to deliver innovative solutions that drive business growth and success. With a focus on reliability, scalability, and security, we ensure that your custom API development project is executed to the highest standards, delivering tangible results and empowering your business for the future.`
        },
        services_provide: { // services_provide section  2
            heading: <><h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span>Clevero Technology </span><mark className="lqd-highlight"><span className="lqd-highlight-txt"> API Development</span> <span className="lqd-highlight-inner" ></span></mark><span> Services</span></h2></>,

            col_one: [
                { bg: "bg-pink-100", src: "assets/images/service/icon/api-development-services/tailored-api-integration-solutions.svg", title: "Tailored API Integration Solutions", dec: <>Our custom API integration services cover every aspect from development, integration, publishing, documentation, deployment, to continuous maintenance. <Link to="/"> <strong className="text-primary"> Clevero Technology's </strong></Link> seasoned API developers excel in meeting diverse business needs by leveraging both open source and third-party APIs.</> },
            ],
            col_two: [
                { bg: "bg-red-100", src: "assets/images/service/icon/api-development-services/cloud-based-api-development.svg", title: "Cloud-Based API Development", dec: <>Empower your software with cloud APIs that facilitate seamless data retrieval from various services through direct, indirect, vendor-specific, or cross-platform interfaces. Our experts at <Link to="/"> <strong className="text-primary"> Clevero Technology </strong></Link> specialise in utilising cloud-based APIs to seamlessly integrate applications into the cloud environment.</> },
            ],
            col_three: [
                { bg: "bg-yellow-100", src: "assets/images/service/icon/api-development-services/mobile-app-api-development.svg", title:  "Mobile App API Development", dec: <>Unlock the full potential of your mobile app with our API development services. From checking data usage and retrieving account balances to facilitating bill payments, scheduling appointments, our experienced API developers at <Link to="/"> <strong className="text-primary"> Clevero Technology  </strong> </Link> ensure that your app delivers an exceptional user experience with a wide range of features.</> }
            ]

        },
        benefits_sec: { // benefits_sec section  3
            heading: <><h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span>Benefits of Our</span> <mark className="lqd-highlight"><span className="lqd-highlight-txt">API Development</span> <span className="lqd-highlight-inner"></span></mark> <span>Services</span></h2></>,
            benefits_card: [
                { src: "assets/images/service/icon/api-development-benefits/better-system-scalability.svg", title: 'Better System Scalability', content: 'Our API development services facilitate seamless scalability, allowing different system components to be easily scaled up or down without disrupting the overall system performance or reliability. This means you can add new servers or resources without needing to modify your website code, ensuring smooth scalability as your business grows.' },
                { src: "assets/images/service/icon/api-development-benefits/flexible-system-updates.svg", title: 'Flexible System Updates', content: 'With APIs, system updates become more flexible and hassle-free. Developers can effortlessly add new features and capabilities to your applications or systems without causing disruptions. This agility ensures that your technology remains up-to-date and adaptable to evolving market demands.' },
                { src: "assets/images/service/icon/api-development-benefits/faster-marketability.svg", title: 'Faster Marketability', content: 'Leveraging our API development services enables you to bring your products or services to market more quickly. APIs can facilitate the creation of marketplaces, simplifying connections between buyers and sellers. Additionally, integrations between different platforms become smoother, allowing for seamless data exchange and enhancing user experience.' },
                { src: "assets/images/service/icon/api-development-benefits/lower-development-cost.svg", title: 'Lower Development Cost', content: 'By abstracting away the complexity of underlying systems, APIs make development more efficient and cost-effective. Our API solutions enable developers to focus on data exchange and functionality without getting bogged down by implementation details. This streamlined approach accelerates development timelines and reduces overall costs.' },
                { src: "assets/images/service/icon/api-development-benefits/better-developer-experience.svg", title: 'Better Developer Experience', content: 'Our APIs ensure a superior developer experience by providing easy access to the functionality of other software programs. Developers can integrate with our APIs seamlessly, spending less time on coding and more time on innovating and building new features. This results in enhanced productivity and creativity within your development team.' },
                { src: "assets/images/service/icon/api-development-benefits/fault-tolerant-system.svg", title: 'Fault Tolerant System', content: 'APIs foster fault tolerance within your systems by facilitating communication and interaction between different software components. This means that even in the event of failure, your system can continue to function effectively. Our API development services prioritise reliability and resilience, ensuring uninterrupted operation even under challenging conditions.' },
            ]
        },
        faq_data: { // faq_data section  4
            faq_one: [
                { title: "What is API development?", content: "API development involves creating Application Programming Interfaces (APIs) that allow different software applications to communicate and interact with each other. APIs define the methods and protocols for how different software components should interact." },
                { title: "Can you develop APIs for both web and mobile applications?", content: "Yes, we develop APIs that are compatible with both web and mobile applications, ensuring seamless communication and integration between different platforms." },
                { title: "What programming languages and technologies do you use for API development?", content: "We use a variety of programming languages and technologies for API development, including but not limited to Java, Python, Node.js, and PHP." }
            ],
            faq_two: [
                { title: "Can you help with API documentation and testing?", content: "Yes, we provide comprehensive API documentation and testing services to ensure that APIs are well-documented, easy to understand, and thoroughly tested for functionality, reliability, and security." },
                { title: "What is the typical timeline for API development projects?", content: "The timeline for API development projects varies depending on factors such as project complexity, scope, and client requirements. Our team provides an estimated timeline during the project planning phase, and we strive to deliver projects on time and within budget." }
            ]
        },
    },

    //#################### graphic designing page 5 #######################
    'graphic-designing': {
        first_sec: {
            src: "/assets/images/service/sub-services/graphic-design.svg",
            heading: <><h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"> <mark className="lqd-highlight"><span className="lqd-highlight-txt">Our Customised</span> <span className="lqd-highlight-inner"></span></mark><b /> <span> <br /> Approach, Tailored to</span> <span><br />Your Brand</span></h2></>,
            pera: <><p className="ld-fh-element inline-block relative mb-1em text-16 lqd-unit-animation-done"> <Link to="/"><strong className="text-primary"> Clevero Technology</strong></Link> stands as a premier graphic design company, dedicated to crafting the finest graphic design services for cultivating a strong brand image. Specialising in a range of offerings including Quality Web Designs, Graphic Designs, Print Designs, Web-Based Designs, Logo and Brochure Designs, and more, we excel in helping your brand shine with clarity, excellence, and global resonance. Our team comprises seasoned and exceptionally skilled graphic designers and web designers, adept at blending expertise, innovation, and years of experience to provide bespoke graphic design services that cater to your specific business needs.</p></>
        },
        card: {
            iconsrc: '(5)Graphic-Designing.png',
            servicetitle: 'Graphic Designing',
            servicecontent: 'Our graphics design services revolve around creating visually appealing and impactful designs that resonate with your target audience. From logos to branding materials, we help you make a lasting impression.'
        },
        banner: {
            page_name: "Graphic Design Services",
            page_dec: "Graphic Design Services",
            key: "graphic design"
        },
        services_content: { // services_content section  1
            src: "assets/images/service/sub-services/graphic-design.svg",
            heading: {
                span1: ``,
                mark: `Our Customised`,
                span2: `Approach, Tailored to <br/> Your Brand`
            },
            dec: `<strong className="text-primary"> Clevero Technology </strong> stands as a premier graphic design company, dedicated to crafting the finest graphic design services for cultivating a strong brand image. Specialising in a range of offerings including Quality Web Designs, Graphic Designs, Print Designs, Web-Based Designs, Logo and Brochure Designs, and more, we excel in helping your brand shine with clarity, excellence, and global resonance. Our team comprises seasoned and exceptionally skilled graphic designers and web designers, adept at blending expertise, innovation, and years of experience to provide bespoke graphic design services that cater to your specific business needs.`
        },
        services_provide: { // services_provide section  2
            heading: <><h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"> <span>Our</span> <mark className="lqd-highlight" style={{ 'margin-right': '13px' }}> <span className="lqd-highlight-txt"> Graphic Design </span> <span className="lqd-highlight-inner"></span></mark> <span> Services </span></h2></>,
            col_one: [
                { bg: "bg-pink-100", src: "assets/images/service/icon/graphic-design-services/logo-design.svg", title: "Logo Design", dec: `Your logo is more than just a symbol – it's the face of your brand. Let our talented graphic designers create a distinctive and memorable logo that captures the essence of your brand's identity.` },
                { bg: "bg-purple-100", src: "assets/images/service/icon/graphic-design-services/branding-collateral.svg", title: "Branding Collateral", dec: `From brochures and banners to business cards and letterheads, we provide a range of branding collateral that reflects your brand's personality and resonates with your audience.` },
            ],
            col_two: [
                { bg: "bg-red-100", src: "assets/images/service/icon/graphic-design-services/digital-graphics.svg", title: "Digital Graphics", dec: `Enhance your online presence with eye-catching digital graphics. We specialise in creating visuals for websites, social media platforms, email campaigns, and more, ensuring a strong and cohesive brand representation across the digital landscape.` },
                { bg: "bg-slate-100", src: "assets/images/service/icon/graphic-design-services/ux-design.svg", title: "UX Design", dec: `Delight your users with intuitive experiences that keep them engaged. Our expert UX design transforms complexity into simplicity, ensuring users return for more. Partner with us to craft smooth and enjoyable user journeys.` },
            ],
            col_three: [
                { bg: "bg-yellow-100", src: "assets/images/service/icon/graphic-design-services/ui-development.svg", title: "UI Development", dec: `Great UI design drives engagement and enhances user satisfaction. Our team focuses on creating stunning interfaces with intuitive navigation and seamless functionality. Prioritizing user experience, we help clients build meaningful connections with their audience and achieve business goals.` }
            ]

        },
        faq_data: { // faq_data section  3
            faq_one: [
                { title: "What is graphic design?", content: "Graphic design is the art and practice of visual communication using typography, imagery, colour, and layout techniques to convey ideas or messages." },
                { title: "What services do graphic designers offer?", content: "Graphic designers offer a wide range of services including logo design, branding, web design, print design (such as brochures, posters, and business cards), digital graphics for social media and websites, and more." },
                { title: "Why is graphic design important for businesses?", content: "Graphic design plays a crucial role in establishing brand identity, communicating messages effectively, and creating a memorable visual presence in the market. It helps businesses stand out, attract customers, and build trust and credibility." }
            ],
            faq_two: [
                { title: "What is the difference between graphic design and web design?", content: "While graphic design focuses on creating visual elements such as logos, illustrations, and layouts, web design involves designing and building websites, including aspects such as user experience (UX) design, interface design, and coding." },
                { title: "What are some current trends in graphic design?", content: "Current trends in graphic design include minimalism, bold typography, vibrant colour schemes, geometric shapes, asymmetrical layouts, immersive illustrations, sustainable design, dark mode designs, mixed media collages, and neomorphism." }
            ]
        },
    },

    //#################### android app development page 7 #######################
    'android-development': {
        first_sec: {
            src: "/assets/images/service/sub-services/android-development.svg",
            heading: <><h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2 is-in-view lqd-unit-animation-done"> <span></span> <mark className="lqd-highlight"><span className="lqd-highlight-txt">Customer-Friendly,</span> <span className="lqd-highlight-inner"></span></mark> <br /> High-Performance And Result-Driven Android Apps Development</h2></>,
            pera: <><p className="ld-fh-element inline-block relative mb-1em text-16 lqd-unit-animation-done" >Our Android applications are crafted to address genuine challenges across diverse businesses, industries, and professions. We prioritise customer satisfaction, recognizing that user-focused Android apps are essential for delivering exceptional user experiences.</p></>
        },
        card: {
            iconsrc: '(7)Android-Development.png',
            servicetitle: 'Android Development',
            servicecontent: 'Unlock the potential of your business with our Android development services. Our expert team crafts bespoke Android applications tailored to your needs, ensuring seamless performance and user satisfaction.'
        },
        banner: {
            page_name: "Android App Development Services",
            page_dec: "Android App Development Services",
            key: "android development"
        },
        services_content: { // services_content section  1
            src: "assets/images/service/sub-services/android-development.svg",
            heading: {
                span1: ``,
                mark: `Customer-Friendly,`,
                span2: `High-Performance And Result-Driven Android Apps Development`
            },
            dec: `Our Android applications are crafted to address genuine challenges across diverse businesses, industries, and professions. We prioritise customer satisfaction, recognizing that user-focused Android apps are essential for delivering exceptional user experiences.`
        },
        services_provide: { // services_provide section  2
            heading: <><h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2 is-in-view lqd-unit-animation-done"><span> Our </span> <mark className="lqd-highlight" style={{ 'margin-right': '13px' }}><span className="lqd-highlight-txt">Android App</span> <span className="lqd-highlight-inner"></span></mark><span> Applications <br /> for Your Business Development Services</span></h2></>,
            col_one: [
                { bg: "bg-pink-100", src: "assets/images/service/icon/android-development-services/android-ui-and-ux-design.svg", title: "Android UI and UX Design", dec: `Emphasising the importance of user interface (UI) and user experience (UX) design shows your commitment to creating apps that not only function well but also provide an intuitive and enjoyable experience for users. Strong UI/UX design can significantly enhance user engagement and satisfaction.` },
                { bg: "bg-purple-100", src: "assets/images/service/icon/android-development-services/android-e-commerce-app-development.svg", title: "Android E-Commerce App Development", dec: `E-commerce is a rapidly growing sector, and having a dedicated Android app can greatly expand the reach of online stores. By focusing on developing e-commerce apps, you're enabling businesses to tap into the vast potential of mobile commerce and offer seamless shopping experiences to their customers.` },
            ],
            col_two: [
                { bg: "bg-red-100", src: "assets/images/service/icon/android-development-services/native-android-application-development.svg", title: "Native Android Application Development", dec: `Native app development ensures optimal performance and full access to device features, providing a tailored experience for Android users. Your expertise in creating custom-tailored apps for various industries showcases your versatility and ability to meet diverse client needs.` },
                { bg: "bg-slate-100", src: "assets/images/service/icon/android-development-services/hybrid-android-applications.svg", title: "Hybrid Android Applications", dec: `Offering hybrid app development services allows you to leverage web technologies to build cross-platform apps that can run on multiple operating systems, not just Android. This approach provides cost-effectiveness and broader reach without compromising on user experience.` },
            ],
            col_three: [
                { bg: "bg-yellow-100", src: "assets/images/service/icon/android-development-services/android-app-qa-and-testing.svg", title: "Android App QA and Testing", dec: `Quality assurance (QA) and testing are essential to ensure that apps perform reliably and meet users' expectations. By offering comprehensive QA and testing services, you're demonstrating your commitment to delivering high-quality, bug-free apps that provide a seamless user experience.` }
            ]
        },
        benefits_sec: { // benefits_sec section  3
            heading: <><h2 className="mb-0/75em ld-fh-element relative  lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"> <span>Benefits of</span> <mark className="lqd-highlight"><span className="lqd-highlight-txt">Android</span> <span className="lqd-highlight-inner"></span></mark> App <br /> for Your Business</h2></>,
            benefits_card: [
                { src: "assets/images/service/icon/android-development-benefits/cost-effective-solution.svg", title: 'Cost-Effective Solution', content: "Android's open-source nature means developers can access the Android software development kit without any fees, making app development more budget-friendly." },
                { src: "assets/images/service/icon/android-development-benefits/customisable-to-your-needs.svg", title: 'Customizable to Your Needs', content: "Android's flexibility allows for easy customization of applications according to your business requirements, ensuring tailored solutions that meet your specific needs." },
                { src: "assets/images/service/icon/android-development-benefits/wide-market-reach.svg", title: 'Wide Market Reach', content: "With approximately 80% market share in the smartphone industry, Android provides access to a vast user base across various economic demographics, facilitating direct engagement with diverse audiences." },
                { src: "assets/images/service/icon/android-development-benefits/secure-distribution-channels.svg", title: 'Secure Distribution Channels', content: "Android applications boast high levels of security, protecting them from malware and ensuring safe distribution through reliable channels, enhancing user trust and confidence." }
            ]
        },
        faq_data: { // faq_data section  4
            faq_one: [
                { title: "What is Android app development?", content: "Android app development involves creating software applications specifically designed to run on devices powered by the Android operating system." },
                { title: "What services do you offer in Android app development?", content: "We offer comprehensive Android app development services, including concept ideation, UI/UX design, native or cross-platform development, testing, deployment, and ongoing maintenance and support." },
                { title: "Can you develop apps for both smartphones and tablets?", content: "Yes, we develop Android apps that are compatible with both smartphones and tablets, ensuring a seamless user experience across different devices." }
            ],
            faq_two: [
                { title: "What programming languages do you use for Android app development?", content: "We primarily use Java and Kotlin programming languages for native Android app development. We also utilise frameworks like React Native or Flutter for cross-platform development if required." },
                { title: "What is the typical timeline for Android app development projects?", content: "The timeline for Android app development projects varies depending on factors such as project complexity, scope, and client requirements. Our team provides an estimated timeline during the project planning phase, and we strive to deliver projects on time and within budget." }
            ]
        },
    },

    //#################### iOS app development page 8 #######################
    'ios-development': {
        first_sec: {
            src: "/assets/images/service/sub-services/ios-development.svg",
            heading: <><h2 className="mb-0/5em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2 is-in-view lqd-unit-animation-done"> <span>Elevate Your </span><mark className="lqd-highlight"><span className="lqd-highlight-txt">Business</span> <span className="lqd-highlight-inner" ></span></mark> <span> With Top-tier iOS App Development Solutions Company</span></h2></>,
            pera: <><p className="ld-fh-element inline-block relative mb-1em text-16 lqd-unit-animation-done">Since the first release of iPhone SDK, we are delivering various iOS app development services with dreamt quality and performance. so we are. We try to blend Apple’s blessings into the app carefully that results in exceptional user experiences to gain an edge over the competition. We design and develop iOS apps for its unique use-cases for improved iPhone devices to deliver optimum user experiences and maximum return of investment.</p></>
        },
        card: {
            iconsrc: '(8)IOS-Development.png',
            servicetitle: 'IOS Development',
            servicecontent: 'Our team of experts specialises in premium iOS app development, crafted precisely to meet your unique requirements. Whether you seek an intuitive user interface, seamless performance, or cutting-edge features, we`ve got you covered.'
        },
        banner: {
            page_name: <>IOS App <br />Development Services</>,
            page_dec: "IOS App Development Services",
            key: "ios development"
        },
        services_content: { // services_content section  1
            src: "assets/images/service/sub-services/ios-development.svg",
            heading: {
                span1: `Elevate Your`,
                mark: `Business`,
                span2: `With Top-tier iOS App Development Solutions Company`
            },
            dec: `Since the first release of iPhone SDK, we are delivering various iOS app development services with dreamt quality and performance. so we are. We try to blend Apple’s blessings into the app carefully that results in exceptional user experiences to gain an edge over the competition. We design and develop iOS apps for its unique use-cases for improved iPhone devices to deliver optimum user experiences and maximum return of investment.`
        },
        services_provide: { // services_provide section  2
            heading: <><h2 className="mb-0/5em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2 is-in-view lqd-unit-animation-done"><span>Our </span> <mark className="lqd-highlight" style={{ 'margin-right': '13px' }}><span className="lqd-highlight-txt">iOS</span> <span className="lqd-highlight-inner"></span></mark>App Development Services</h2></>,

            col_one: [
                { bg: "bg-pink-100", src: "assets/images/service/icon/ios-app-development-services/native-ios-app-development.svg", title: "Native iOS App Development", dec: `Our team excels in crafting native iOS applications that offer an unparalleled user experience. Leveraging the latest technologies and best practices, we ensure that your app stands out in the crowded App Store.` },
                // { bg: "bg-purple-100", src: "assets/images/service/icon/ios-app-development-services.svg", title: "Intune Compliance Solutions", dec: `Security is paramount in today's digital landscape. Our iOS apps adhere to Intune compliance standards, covering essential aspects such as email security, device health monitoring, system security, and more.` },
            ],
            col_two: [
                { bg: "bg-red-100", src: "assets/images/service/icon/ios-app-development-services/customised-app-development.svg", title: "Customised App Development", dec: `Every business is unique, and so are its app requirements. We offer customised iOS app development services tailored to your specific needs, ensuring that your app reflects your brand identity and meets your business objectives.` },
            ],
            col_three: [
                { bg: "bg-slate-100", src: "assets/images/service/icon/ios-app-development-services/iphone-app-development.svg", title: "iPhone App Development", dec: `Reach millions of users worldwide with our iPhone app development services. Whether you're targeting consumers or enterprise users, we have the expertise to create innovative iPhone apps that drive results.` },
                // { bg: "bg-yellow-100", src: "assets/images/service/icon/ios-app-development-services.svg", title: "iPad App Development", dec: `Take advantage of the larger screen real estate and unique capabilities of the iPad with our iPad app development services. From productivity tools to entertainment apps, we help you capitalise on the full potential of Apple's tablet.` }
            ]
        },
        faq_data: { // faq_data section  3
            faq_one: [
                { title: "What is iOS app development?", content: "iOS app development involves creating mobile applications specifically for Apple's iOS operating system, which powers devices such as iPhones, iPads, and iPod Touch." },
                { title: "What services do you offer in iOS app development?", content: "We offer comprehensive iOS app development services, including concept ideation, UI/UX design, programming, testing, deployment, and ongoing maintenance and support." }
            ],
            faq_two: [
                { title: "Can you develop apps for both iPhone and iPad?", content: "Yes, our team is proficient in developing apps for both iPhone and iPad devices. Whether you need a mobile app optimised for smaller screens or a tablet app with a larger interface, we have the expertise to deliver." },
                { title: "How do you ensure the security and performance of iOS apps?", content: "We follow industry best practices for iOS app development, including implementing encryption, secure authentication, and data protection measures to ensure the security of user data. Additionally, we conduct thorough testing and performance optimization to ensure that iOS apps deliver a smooth and responsive user experience." }
            ]
        },
    },

    //#################### e-commerce development page 9 #######################
    'e-commerce-services': {
        first_sec: {
            src: "/assets/images/service/sub-services/e-commerce-development.svg",
            heading: <><h2 className="mb-0/5em ld-fh-element inline-block relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2 is-in-view lqd-unit-animation-done"><span> Top Rated </span><mark className="lqd-highlight"><span className="lqd-highlight-txt"> E-Commerce </span> <span className="lqd-highlight-inner"></span></mark><span> Development Services For Better End-To-End Functionality</span></h2></>,
            pera: <><p className="ld-fh-element inline-block relative mb-1em text-16 lqd-unit-animation-done">E-commerce solutions encompass products and services that enable businesses to conduct electronic transactions. From designing and operating websites to navigating the complexities of programming platforms and marketing strategies, our expert knowledge guides you through every step of the process. At <Link to="/"><strong className="text-primary"> Clevero Technology</strong></Link>, we offer tailored e-commerce solutions to meet your business needs, ensuring secure payment gateways and expert guidance at affordable rates. Kinergy Tech takes pride in providing comprehensive e-commerce solutions for online stores, prioritising security and reliability in an ever-evolving digital landscape</p></>
        },
        card: {
            iconsrc: '(9)E-commerce-Services.png',
            servicetitle: 'E-commerce Services',
            servicecontent: 'Need e-commerce services? Our team delivers tailored solutions for online stores, from design to optimization, to drive sales and enhance user experience. Let`s elevate your business in the digital marketplace.'
        },
        banner: {
            page_name: "E-Commerce Development Services",
            page_dec: "E-Commerce Development Services",
            key: "ecommerce development"
        },
        services_content: { // services_content section  1
            src: "assets/images/service/sub-services/e-commerce-development.svg",
            heading: {
                span1: `Top Rated`,
                mark: `E-Commerce`,
                span2: `Development Services For Better End-To-End Functionality`
            },
            dec: `E-commerce solutions encompass products and services that enable businesses to conduct electronic transactions. From designing and operating websites to navigating the complexities of programming platforms and marketing strategies, our expert knowledge guides you through every step of the process. At <strong className="text-primary"> clevero Technology </strong>, we offer tailored e-commerce solutions to meet your business needs, ensuring secure payment gateways and expert guidance at affordable rates. Kinergy Tech takes pride in providing comprehensive e-commerce solutions for online stores, prioritising security and reliability in an ever-evolving digital landscape.`
        },
        services_provide: { // services_provide section  2
            heading: <><h2 className="mb-0/5em ld-fh-element inline-block relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2 is-in-view lqd-unit-animation-done"><span> Our Different </span> <mark className="lqd-highlight" style={{ 'margin-right': '13px' }}><span className="lqd-highlight-txt"> E-Commerce </span> <span className="lqd-highlight-inner"></span></mark><br /><span> Website Development Services </span></h2></>,
            col_one: [
                { bg: "bg-pink-100", src: "assets/images/service/icon/e-commerce-development-services/e-commerce-application-development.svg", title: "E-Commerce Application Development", dec: `We specialise in creating advanced e-commerce applications using cutting-edge technology and robust system architecture to help you dominate your industry.` },
                { bg: "bg-purple-100", src: "assets/images/service/icon/e-commerce-development-services/e-commerce-maintenance.svg", title: "E-Commerce Maintenance And Support", dec: `Our dedicated team offers round-the-clock maintenance and support, conducting regular bug inspections and fixes to ensure seamless operation for our valued clients.     ` },
            ],
            col_two: [
                { bg: "bg-red-100", src: "assets/images/service/icon/e-commerce-development-services/plug-ins-and-module-development.svg", title: "Plug-Ins And Module Development", dec: `To stay ahead in the dynamic e-commerce landscape, we utilise advanced plug-ins and modules to attract more users to your website and enhance its functionality.` },
                { bg: "bg-slate-100", src: "assets/images/service/icon/e-commerce-development-services/e-commerce-cart-development.svg", title: "E-Commerce Cart Development", dec: `We design e-commerce shopping carts with modern features to give your website a competitive edge. Our hassle-free navigation and intricate features ensure a flawless shopping experience for your customers.` },
            ],
            col_three: [
                { bg: "bg-yellow-100", src: "assets/images/service/icon/e-commerce-development-services/payment-gateway-integration.svg", title: "Payment Gateway Integration", dec: `We prioritise maximum security when integrating payment gateways such as PayPal, Stripe, Braintree, WorldPay, etc., into your website. With our expertise, you can trust that your transactions are safe and secure.` }
            ]
        },
        industries_serve: { // INDUSTRIES_SERVE section  3
            industries: [
                { src: "assets/images/service/icon/industries-we-serve/food-restaurant.svg", dec: "Food & Restaurant" },
                { src: "assets/images/service/icon/industries-we-serve/tours-travelling.svg", dec: "Tours & Travelling" },
                { src: "assets/images/service/icon/industries-we-serve/social-networking.svg", dec: "Social Networking" },
                { src: "assets/images/service/icon/industries-we-serve/retail-e-commerce.svg", dec: "Retail, E-commerce" },
                { src: "assets/images/service/icon/industries-we-serve/healthcare-fitness.svg", dec: "Healthcare & Fitness" },
                { src: "assets/images/service/icon/industries-we-serve/education-e-learning.svg", dec: "Education & E-Learning" }
            ]
        },
        faq_data: { // faq_data section  4
            faq_one: [
                { title: "What is eCommerce development?", content: "eCommerce development is the creation of online platforms for businesses to sell products or services to customers over the internet." },
                { title: "Why is eCommerce development important for businesses?", content: "eCommerce development is crucial for businesses as it allows them to reach a wider audience, increase sales, and provide customers with convenient online shopping experiences." }
            ],
            faq_two: [
                { title: "What are the key features of an eCommerce website?", content: "Key features of an eCommerce website include product listings, shopping cart functionality, secure payment gateways, user account management, order tracking, and inventory management." },
                { title: "How do you optimise eCommerce websites for search engines?", content: "We employ SEO best practices such as keyword optimization, meta tags, content optimization, site speed optimization, mobile responsiveness, and backlink building to improve search engine rankings and drive organic traffic to eCommerce websites." }
            ]
        },
    },

    //#################### php development page 10 #######################
    'php-development': {
        first_sec: {
            src: "/assets/images/service/sub-services/php-development.svg",
            imgwidth: "480",
            heading: <><h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2 is-in-view lqd-unit-animation-done"> <span> Budget Friendly </span><mark className="lqd-highlight"><span className="lqd-highlight-txt">PHP </span> <span className="lqd-highlight-inner"></span></mark> <span> Development Services</span></h2></>,
            pera: <><p className="ld-fh-element inline-block relative mb-1em text-16 lqd-unit-animation-done">We've successfully provided mission-critical PHP solutions to top brands and renowned organisations worldwide. Leveraging our expertise in design, coding, and integration of cutting-edge technologies, we ensure optimal outcomes for our clients</p></>
        },
        card: {
            iconsrc: '(10)Php-Development.png',
            servicetitle: 'Php Development',
            servicecontent: 'Need PHP development expertise? Our skilled team offers custom solutions for web development projects, ensuring high-quality, scalable solutions. Let`s turn your ideas into reality with our PHP development services.'
        },
        banner: {
            page_name: "PHP Development Services",
            page_dec: "PHP Development Services",
            key: "php development"
        },
        services_content: { // services_content section  1
            src: "assets/images/service/sub-services/php-development.svg",
            heading: {
                span1: `Budget Friendly`,
                mark: `PHP`,
                span2: `Development Services`
            },
            dec: `We've successfully provided mission-critical PHP solutions to top brands and renowned organisations worldwide. Leveraging our expertise in design, coding, and integration of cutting-edge technologies, we ensure optimal outcomes for our clients.`
        },
        services_provide: { // services_provide section  2
            heading: <><h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2 is-in-view lqd-unit-animation-done"><span> Select From A Range Of </span> <br /><mark className="lqd-highlight" style={{ 'margin-right': '13px' }}><span className="lqd-highlight-txt"> Custom PHP </span> <span className="lqd-highlight-inner" ></span></mark>Web Solutions</h2></>,
            col_one: [
                { bg: "bg-pink-100", src: "assets/images/service/icon/php-web-solutions/tailor-made-php-solutions.svg", title: "Tailor-Made PHP Solutions", dec: `This highlights the ability of PHP to be customised to meet specific business needs, creating unique solutions.` },
                { bg: "bg-purple-100", src: "assets/images/service/icon/php-web-solutions/seamless-integration.svg", title: "Seamless Integration", dec: `This refers to PHP's capability to easily integrate with other systems and applications, ensuring smooth interoperability and functionality.` },
            ],
            col_two: [
                { bg: "bg-red-100", src: "assets/images/service/icon/php-web-solutions/cost-effectiveness.svg", title: "Cost-Effectiveness", dec: `PHP development is budget-friendly due to its open-source nature and widespread adoption, offering quality solutions at affordable rates.` },
                { bg: "bg-slate-100", src: "assets/images/service/icon/php-web-solutions/scalability-and-simple-documentation.svg", title: "Scalability and Simple Documentation", dec: `PHP accommodates business growth by easily scaling with additional servers or clusters, while its straightforward documentation streamlines development processes for enhanced efficiency.` },
            ],
            col_three: [
                { bg: "bg-yellow-100", src: "assets/images/service/icon/php-web-solutions/compatibility-and-rich-libraries.svg", title: "Compatibility and Rich Libraries", dec: `PHP's compatibility across various platforms, including Windows, Mac OS, Linux, and mobile OS, coupled with its extensive libraries, accelerates development and ensures robust, feature-rich applications.` },
                { bg: "bg-blue-100", src: "assets/images/service/icon/php-web-solutions/easy-maintenance-and-updates.svg", title: "Easy Maintenance and Updates", dec: `PHP-based projects are effortless to maintain and update thanks to its understandable syntax, simplifying tasks such as bug fixes and implementation of new features.` }
            ]
        },
        benefits_sec: { // benefits_sec section  3
            heading: <><h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><mark className="lqd-highlight"><span className="lqd-highlight-txt">Business Benefits </span><span className="lqd-highlight-inner"></span></mark><span> of <br /> PHP Technology</span></h2></>,
            benefits_card: [
                { src: "assets/images/service/icon/php-technology-benefits/flexible.svg", title: 'Flexible', content: "PHP is highly adaptable, catering to diverse business requirements by providing compatibility, support for various databases, and advanced functionality." },
                { src: "assets/images/service/icon/php-technology-benefits/user-friendly.svg", title: 'User-Friendly', content: "PHP-based web applications are known for their speed and ease of navigation, ensuring a seamless user experience. The platform's simplicity and streamlined functionality make it a preferred choice for users." },
                { src: "assets/images/service/icon/php-technology-benefits/cross-platform-compatibility.svg", title: 'Cross-Platform Compatibility', content: "PHP applications are compatible with leading operating systems such as Windows, Mac, and Linux, enabling businesses to reach potential customers across various industries." },
                { src: "assets/images/service/icon/php-technology-benefits/scalability.svg", title: 'Scalability', content: "With PHP, businesses can develop scalable applications capable of handling rapid surges in business operations, ensuring seamless growth and expansion." },
                { src: "assets/images/service/icon/php-technology-benefits/third-party-integrations.svg", title: 'Third-Party Integrations', content: "PHP facilitates seamless integration of third-party tools and APIs, optimising website performance and enhancing robustness." },
                { src: "assets/images/service/icon/php-technology-benefits/efficient-development.svg", title: 'Efficient Development', content: "PHP's faster processing speed, server configuration options, and timely updates contribute to efficient web development, enabling businesses to build flexible and versatile applications." },
            ]
        },
        faq_data: { // faq_data section  4
            faq_one: [
                { title: "What is a PHP development service?", content: "PHP development service involves creating, customising, and maintaining websites and web applications using the PHP programming language." },
                { title: "What are the advantages of using PHP for web development?", content: "PHP offers advantages such as flexibility, scalability, open-source nature, extensive community support, and compatibility with various operating systems and databases." },
                { title: "What types of projects can be built using PHP?", content: "PHP can be used to build a wide range of projects, including dynamic websites, content management systems (CMS), e-commerce platforms, web applications, and APIs." }
            ],
            faq_two: [
                { title: "Do I need coding experience to use PHP?", content: "While having coding experience can be beneficial, PHP is relatively easy to learn and user-friendly, making it accessible to beginners as well as experienced developers." },
                { title: "Is PHP suitable for building large-scale applications?", content: "Yes, PHP is suitable for building large-scale applications when combined with proper architecture, design patterns, and development practices." }
            ]
        },
    },

    //#################### wordpress development page 11 #######################
    'wordpress-development': {
        first_sec: {
            src: "/assets/images/service/sub-services/wordpress-development.svg",
            imgwidth: "540",
            heading: <><h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2 is-in-view lqd-unit-animation-done"><span> Give Your Business A Powerful Global</span> <mark className="lqd-highlight"><span className="lqd-highlight-txt">Digital Presence</span> <span className="lqd-highlight-inner"></span></mark></h2></>,
            pera: <><p className="ld-fh-element inline-block relative mb-1em text-16 lqd-unit-animation-done">WordPress simplifies the complexities of website management, making it easy to handle. As the preferred choice for CMS-based websites, WordPress powers one in every 6 websites and is favoured by over 50% of CMS users for website development.</p></>
        },
        card: {
            iconsrc: '(11)Wordpress-Development.png',
            servicetitle: 'Wordpress Development',
            servicecontent: 'Looking for WordPress development solutions? Our experienced team offers custom WordPress websites and plugins tailored to your needs. Let us empower your online presence with our expertise in WordPress development.'
        },
        banner: {
            page_name: "Wordpress Development",
            page_dec: "WordPress Development",
            key: "wordpress development"
        },
        services_content: { // services_content section  1
            src: "assets/images/service/sub-services/wordpress-development.svg",
            heading: {
                span1: `Give Your Business A Powerful Global`,
                mark: `Digital Presence`,
                span2: ``
            },
            dec: `WordPress simplifies the complexities of website management, making it easy to handle. As the preferred choice for CMS-based websites, WordPress powers one in every 6 websites and is favoured by over 50% of CMS users for website development.`
        },
        services_provide: { // services_provide section  2
            heading: <><h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2 is-in-view lqd-unit-animation-done"><span> Our Full-Scale</span> <mark className="lqd-highlight" style={{ 'margin-right': '13px' }}><span className="lqd-highlight-txt">WordPress</span> <span className="lqd-highlight-inner"></span></mark><br /> Website Design Services</h2></>,
            col_one: [
                { bg: "bg-pink-100", src: "assets/images/service/icon/wordpress-website-design-services/wordpress-theme-design.svg", title: "WordPress Theme Design and Development", dec: `Crafting visually stunning, user-friendly websites is our forte. With our themes and designs, coupled with enticing features, your visitors will keep returning. We ensure pixel-perfect resolution for seamless viewing across various devices and browsers.` },
                { bg: "bg-purple-100", src: "assets/images/service/icon/wordpress-website-design-services/wordpress-website-seo.svg", title: "WordPress Website SEO", dec: `Elevate your website's visibility with our tailored SEO strategies, placing you prominently in Google search results. Simply having a WordPress site isn't enough; we implement top-notch SEO practices and optimise plugins to outshine the competition.` },
            ],
            col_two: [
                { bg: "bg-red-100", src: "assets/images/service/icon/wordpress-website-design-services/wordpress-plugin-development.svg", title: "WordPress Plugin Development", dec: `For additional functionalities, WordPress plugins are indispensable. Our seasoned developers specialise in plugin installation, customization, and development, making your website scalable, adaptable, and aligned with your business requirements.` },
                { bg: "bg-slate-100", src: "assets/images/service/icon/wordpress-website-design-services/wordpress-migration-services.svg", title: "WordPress Migration Services", dec: `Looking to upgrade to WordPress for a fresh digital presence? Our seamless migration services facilitate hassle-free transfer of your website to new hosting, ensuring you stay up-to-date with current trends.` },
            ],
            col_three: [
                { bg: "bg-yellow-100", src: "assets/images/service/icon/wordpress-website-design-services/wordpress-website-optimization.svg", title: "WordPress Website Optimization", dec: `Say goodbye to sluggish websites! Our optimization techniques ensure optimal performance across all screen sizes, without compromising on speed, security, or scalability.` },
                { bg: "bg-blue-100", src: "assets/images/service/icon/wordpress-website-design-services/wordpress-installation.svg", title: "WordPress Installation, Configuration, and API Integration", dec: `Effortlessly set up, upgrade, and configure your WordPress website with our expertise. We also enable seamless integration of third-party APIs like Google, PayPal, LinkedIn, and more, enhancing functionality and customer engagement.` }
            ]
        },
        benefits_sec: { // benefits_sec section  3
            heading: <><h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span>Why Choose </span><mark className="lqd-highlight"><span className="lqd-highlight-txt"> Clevero Technology </span><span className="lqd-highlight-inner"></span></mark><span><br />For Custom WordPress Development?</span></h2></>,
            benefits_card: [
                { src: "assets/images/service/icon/wordpress-development-benefits/free-demo-proof-of-concept.svg", title: 'Free Demo/Proof of Concept', content: "Clevero Technology offers all clients a complimentary demo or proof of concept, demonstrating how we'll implement your custom requirements." },
                { src: "assets/images/service/icon/wordpress-development-benefits/highly-customizable-ui.svg", title: 'Highly Customizable UI', content: "In today's digital landscape, a sleek and fast-loading UI is essential for any website. Our WordPress development services prioritise UI development, ensuring every website we design is fully customizable to meet your needs." },
                { src: "assets/images/service/icon/wordpress-development-benefits/fully-responsive-mobile-friendly-wordpress-website.svg", title: 'Fully Responsive Mobile-Friendly WordPress Website', content: "With mobile web traffic on the rise, we ensure your website is optimised for all devices. Our UI and development teams collaborate to create seamless mobile experiences across various screen resolutions." },
                { src: "assets/images/service/icon/wordpress-development-benefits/compliant-with-wordpress-coding-standards.svg", title: 'Compliant With WordPress Coding Standards', content: "Our experienced WordPress development team adheres to coding standards, guaranteeing robust and reliable websites." },
                { src: "assets/images/service/icon/wordpress-development-benefits/flexibility-in-functionalities.svg", title: 'Flexibility in Functionalities', content: "Need additional features? Our agile team can swiftly accommodate any requirements during or after development." },
                { src: "assets/images/service/icon/wordpress-development-benefits/secure-wordpress-websites.svg", title: 'Secure WordPress Websites', content: "Security is paramount. Our websites are built with resilience against common cyber threats, and we can implement advanced security features as needed." },
                { src: "assets/images/service/icon/wordpress-development-benefits/best-in-the-industry-pricing.svg", title: 'Best in the Industry Pricing', content: "Clevero Technology offers unbeatable value with affordable WordPress development services, ensuring quality without breaking the bank." },
                { src: "assets/images/service/icon/wordpress-development-benefits/priority-support.svg", title: 'Priority Support', content: "Customer satisfaction is our priority. We provide dedicated support to address all client queries and concerns promptly." },
            ]
        },
        industries_serve: { // INDUSTRIES_SERVE section  3
            industries: [
                { src: "assets/images/service/icon/industries-we-serve/food-restaurant.svg", dec: "Food & Restaurant" },
                { src: "assets/images/service/icon/industries-we-serve/tours-travelling.svg", dec: "Tours & Travelling" },
                { src: "assets/images/service/icon/industries-we-serve/social-networking.svg", dec: "Social Networking" },
                { src: "assets/images/service/icon/industries-we-serve/retail-e-commerce.svg", dec: "Retail, E-commerce" },
                { src: "assets/images/service/icon/industries-we-serve/healthcare-fitness.svg", dec: "Healthcare & Fitness" },
                { src: "assets/images/service/icon/industries-we-serve/education-e-learning.svg", dec: "Education & E-Learning" }
            ]
        },
        faq_data: { // faq_data section  5
            faq_one: [
                { title: "What is WordPress development service?", content: "WordPress development service involves the creation, customization, and maintenance of websites using the WordPress platform. It includes designing and developing websites, themes, plugins, and other functionalities to meet the specific needs of businesses or individuals." },
                { title: "What are the benefits of using WordPress for website development?", content: "WordPress offers several benefits, including ease of use, flexibility, scalability, and a wide range of themes and plugins. It allows for quick setup and customization, making it suitable for various types of websites, from blogs and portfolios to e-commerce stores and corporate websites." },
                { title: "Can I customise my WordPress website?", content: "Yes, there are extensive customization options available." }
            ],
            faq_two: [
                { title: "What types of websites can be built using WordPress?", content: "WordPress can be used to create a wide range of websites, including blogs, corporate websites, e-commerce stores, portfolios, forums, membership sites, and more. Its flexibility and extensive features make it suitable for virtually any type of website." },
                { title: "Do I need coding skills to use WordPress?", content: "While basic knowledge of HTML, CSS, and PHP can be helpful, you don't necessarily need coding skills to use WordPress. Its user-friendly interface and intuitive content management system (CMS) allow users to create and manage websites without writing code. However, having coding skills can enable you to customise and extend WordPress functionalities further." },
                { title: "How can I optimise my WordPress website for SEO?", content: "Use SEO-friendly themes and plugins, optimise content, and improve site speed." }
            ]
        },
    },

    //#################### ui/ux designing page 12 #######################
    'ui-ux-designing': {
        first_sec: {
            src: "/assets/images/service/sub-services/uiux-designing.svg",
            heading: <><h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2 is-in-view lqd-unit-animation-done"><span>Empower Your <br /> Brand with Stunning </span> <mark className="lqd-highlight" style={{ 'margin-right': '13px' }}><span className="lqd-highlight-txt"> UI/UX Web Designs </span> <span className="lqd-highlight-inner"></span></mark></h2></>,
            pera: <><p className="ld-fh-element inline-block relative mb-1em text-16 lqd-unit-animation-done">Looking for expert UI/UX design services to elevate your digital presence? Look no further! Our team specialises in crafting seamless user experiences and captivating interfaces tailored to your needs. From sleek mobile apps to intuitive web platforms, we blend creativity with functionality to deliver designs that engage and delight users. With a focus on user research, wireframing, prototyping, and visual design, we ensure your product not only looks stunning but also provides an effortless journey for your audience. Let us bring your vision to life with our top-notch UI/UX design services</p></>
        },
        card: {
            iconsrc: '(12)UIUX-Designing.png',
            servicetitle: 'UI/UX Designing',
            servicecontent: 'Looking for top-notch UX/UX design? Our expert team creates visually stunning and user-friendly interfaces that enhance user experiences. Let us transform your vision into captivating designs that resonate with your audience.'
        },
        banner: {
            page_name: "UI/UX Web Designing Services",
            page_dec: "UI/UX Web Designing Services",
            key: "uiux designing"
        },
        services_content: { // services_content section  1
            src: "assets/images/service/sub-services/uiux-designing.svg",
            heading: {
                span1: `Empower Your <br/> Brand with Stunning`,
                mark: `UI/UX Web Designs`,
                span2: ``
            },
            dec: `Looking for expert UI/UX design services to elevate your digital presence? Look no further! Our team specialises in crafting seamless user experiences and captivating interfaces tailored to your needs. From sleek mobile apps to intuitive web platforms, we blend creativity with functionality to deliver designs that engage and delight users. With a focus on user research, wireframing, prototyping, and visual design, we ensure your product not only looks stunning but also provides an effortless journey for your audience. Let us bring your vision to life with our top-notch UI/UX design services.`
        },
        services_provide: { // services_provide section  2
            heading: <><h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2 is-in-view lqd-unit-animation-done"><span> Our </span><mark style={{ 'margin-right': '13px' }} className="lqd-highlight"><span className="lqd-highlight-txt"> Comprehensive </span> <span className="lqd-highlight-inner" ></span></mark><span> <br /> Services Include </span> </h2></>,
            col_one: [
                { bg: "bg-pink-100", src: "assets/images/service/icon/ui-ux-services/user-research-and-analysis.svg", title: "User Research and Analysis", dec: `Conducting in-depth research to understand user behaviours, needs, and pain points, ensuring design decisions are informed by data.` },
                { bg: "bg-purple-100", src: "assets/images/service/icon/ui-ux-services/wireframing-and-prototyping.svg", title: "Wireframing and Prototyping", dec: `Creating low-fidelity wireframes and interactive prototypes to visualise the structure and flow of the user interface before development begins.` },
            ],
            col_two: [
                { bg: "bg-red-100", src: "assets/images/service/icon/ui-ux-services/ui-design.svg", title: "UI Design", dec: `Designing visually appealing user interfaces that prioritise usability, accessibility, and consistency across all digital touchpoints.` },
                { bg: "bg-slate-100", src: "assets/images/service/icon/ui-ux-services/ux-design.svg", title: "UX Design", dec: `Crafting seamless and intuitive user experiences through thoughtful information architecture, interaction design, and user journey mapping.` },
            ],
            col_three: [
                { bg: "bg-yellow-100", src: "assets/images/service/icon/ui-ux-services/usability-testing.svg", title: "Usability Testing", dec: `Conducting usability tests to evaluate the effectiveness of the design solution and identify areas for improvement based on user feedback.` },
                { bg: "bg-blue-100", src: "assets/images/service/icon/ui-ux-services/design-system-development.svg", title: "Design System Development", dec: `Creating and maintaining design systems that establish consistent design patterns, styles, and components to streamline the design and development process.` }
            ]
        },
        faq_data: { // faq_data section  3
            faq_one: [
                { title: "What are UI/UX design services?", content: "UI/UX design services involve creating user interfaces (UI) and user experiences (UX) for digital products such as websites, mobile apps, and software applications. These services focus on enhancing usability, accessibility, and overall user satisfaction." },
                { title: "What services do you offer in UI/UX design?", content: "We offer comprehensive UI/UX design services, including user research, wireframing, prototyping, visual design, and usability testing. Our goal is to create intuitive and visually appealing interfaces that enhance user engagement and drive business success." },
                { title: "Why is UI/UX design important for digital products?", content: "UI/UX design plays a crucial role in the success of digital products by ensuring that they are user-friendly, visually appealing, and easy to navigate. Good UI/UX design can enhance brand perception, increase user satisfaction, and drive conversions and retention rates." }
            ],
            faq_two: [
                { title: "Can you redesign existing UI/UX designs?", content: "Yes, we offer UI/UX redesign services to improve the usability and visual appeal of existing digital products. Whether it's updating outdated interfaces, addressing usability issues, or rebranding, we can help elevate your digital presence with a fresh and modern design." },
                { title: "Can you provide examples of your previous UI/UX design work?", content: "Yes, we can provide examples of our previous UI/UX design projects to showcase our expertise and capabilities. Whether it's websites, mobile apps, or software interfaces, we have experience designing a wide range of digital products for various industries and clients." }
            ]
        },
    },
    //#################### digital marketing page 6 #######################
    'digital-marketing': {
        first_sec: {
            src: "/assets/images/service/sub-services/digital-marketing.svg",
            heading: <><h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"> <span>Why Are</span> <mark className="lqd-highlight"><span className="lqd-highlight-txt">Digital Services</span> <span className="lqd-highlight-inner"></span></mark><b /> <span> <br /> Important?</span></h2></>,
            pera: <><p className="ld-fh-element inline-block relative mb-1em text-16 lqd-unit-animation-done" > In today's rapidly evolving digital landscape, a company's online presence plays a crucial role in determining its success. At Clevero Technology, we understand the immense value of effective digital marketing strategies for businesses in Rajkot and neighboring regions. As a trusted and leading digital marketing agency in Rajkot, we offer a diverse range of services dedicated to helping businesses thrive in the online realm. Our team of experts is equipped to cater to a wide range of objectives, be it increasing brand recognition, driving website traffic or boosting revenue. As a renowned provider of digital marketing solutions, we craft personalized strategies that align with your unique business goals. Trust <Link to="/"><strong className="text-primary"> Clevero Technology</strong></Link> for top-notch digital marketing services in Rajkot, and let us assist you in building a formidable online presence.</p></>
        },
        card: {
            iconsrc: '(6)Digital-Marketing.png',
            servicetitle: 'Digital Marketing',
            servicecontent: 'Our digital marketing services help businesses reach and engage their target audience effectively. From SEO to social media marketing, we drive results-driven strategies that boost your online presence and drive growth.'
        },
        banner: {
            page_name: "Digital Marketing Services",
            page_dec: "Digital Marketing Services",
            key: "digital marketing"
        },
        services_content: { // services_content section  1
            src: "assets/images/service/sub-services/digital-marketing.svg",
            heading: {
                span1: `Why Are`,
                mark: `Digital Services`,
                span2: `Important?`
            },
            dec: `In today's rapidly evolving digital landscape, a company's online presence plays a crucial role in determining its success. At <strong className="text-primary"> Clevero Technology, </strong> we understand the immense value of effective digital marketing strategies for businesses in Rajkot and neighboring regions. As a trusted and leading digital marketing agency in Rajkot, we offer a diverse range of services dedicated to helping businesses thrive in the online realm. Our team of experts is equipped to cater to a wide range of objectives, be it increasing brand recognition, driving website traffic or boosting revenue. As a renowned provider of digital marketing solutions, we craft personalized strategies that align with your unique business goals. Trust <strong className="text-primary"> Clevero Technology </strong> for top-notch digital marketing services in Rajkot, and let us assist you in building a formidable online presence.`
        },
        services_provide: { // services_provide section  2
            heading: <><h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><mark className="lqd-highlight" style={{ 'margin-right': '13px' }}><span className="lqd-highlight-txt">Digital Marketing </span> <span className="lqd-highlight-inner"></span></mark> Services</h2></>,
            col_one: [
                {
                    bg: "bg-pink-100",
                    src: "assets/images/service/icon/digital-marketing-services/seo.svg",
                    title: "SEO",
                    slug: "seo",
                    dec: <>At <Link to="/"><strong className="text-primary"> Clevero Technology</strong></Link>, we understand that SEO is crucial for enhancing your online visibility and driving organic traffic. Our comprehensive SEO strategies are tailored to meet the unique needs of your business, ensuring your website ranks highly in search engine results. From keyword research and on-page optimization to link building and content creation, our expert team employs the latest techniques to increase your search engine presence and help you reach your target audience effectively.</>,
                    page: {
                        banner: {
                            page_name: "Search Engine Optimization",
                            page_dec: "We elevate your visibility with smart SEO strategies, driving organic traffic and growth!",
                            key: "seo"
                        },
                        first_sec: {
                            src: "/assets/images/service/sub-services/seo-development.svg",
                            imgwidth : 690 ,
                            heading: <><h2 className="mb-0/5em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span>Boost Your Online Presence with </span><mark className="lqd-highlight"><span className="lqd-highlight-txt"> Expert SEO Services </span><span className="lqd-highlight-inner"></span></mark></h2></>,
                            pera: <><p className="text-16 mb-2/5em ld-fh-element relative"><strong className="text-primary"> Clevero Technology </strong>, Rajkot's best SEO services provider, can help you improve your internet footprint. Clevero Technology's skilled team specializes in improving your internet visibility and driving organic traffic to your website. As Rajkot's best SEO agency, we provide complete solutions targeted to your specific business requirements. Whether you are a small business or a large corporation, our SEO services are designed to boost your brand's online visibility and encourage growth. Partner with Clevero Technology, Rajkot's premier SEO company, and see how our SEO marketing tactics may benefit your organization.</p></>
                        },
                        short_desc: 'provider Short Desc',
                        benefits_sec: {
                            heading: <><h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span> Benefits of Clevero Technology's </span><br/><mark className="lqd-highlight"><span className="lqd-highlight-txt"> SEO Solutions </span><span className="lqd-highlight-inner"></span></mark></h2></>,
                            benefits_card: [
                                {
                                    src: "assets/images/service/icon/seo-benefits/increased-website-traffic.svg",
                                    title: 'Increase Website Traffic',
                                    content: "SEO improves your site's visibility on search engines, resulting in more organic traffic."
                                },
                                {
                                    src: "assets/images/service/icon/seo-benefits/higher-search-engine-rankings.svg",
                                    title: 'Higher Search Engine Rankings',
                                    content: "By optimizing your website, you can improve its rating on search engine results pages (SERPs), improving your visibility to potential clients."
                                },
                                {
                                    src: "assets/images/service/icon/seo-benefits/improved-brand-reputation.svg",
                                    title: 'Improved Brand Reputation',
                                    content: "A better search engine ranking boosts your company's reputation and trustworthiness in the eyes of buyers."
                                },
                                {
                                    src: "assets/images/service/icon/seo-benefits/improved-user-experience.svg",
                                    title: 'Improved User Experience',
                                    content: "SEO involves optimizing your website's user experience, making it easier for visitors to navigate and locate what they're looking for."
                                },
                                {
                                    src: "assets/images/service/icon/seo-benefits/cost-effective-marketing.svg",
                                    title: 'Cost-Effective Marketing',
                                    content: "When compared to traditional marketing strategies, SEO is a less expensive strategy to promote your business and generate targeted traffic."
                                },
                                {
                                    src: "assets/images/service/icon/seo-benefits/long-term-results.svg",
                                    title: 'Long-Term Results',
                                    content: "With SEO, you can achieve long-term results, ensuring that your website continues to rank well on search engines over time."
                                },
                            ]
                        },
                        expert_sec: {
                            heading: <><h2 className="ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-bottom lqd-highlight-reset-onhover lqd-split-lines mb-0/5em mt-0/25em"> <span> Your Premier </span><mark className="lqd-highlight"> <span className="lqd-highlight-txt"> SEO Expert </span> <span className="bottom-0 left-0 lqd-highlight-inner bg-purple-100"></span> </mark></h2></>,
                            cards: [
                                { title: "Technical SEO.", pera: "Technical SEO focuses on your website's backend to ensure that it meets search engines' technical requirements. Clevero Technology's Technical SEO services in Rajkot prioritize important elements such as website speed, mobile friendliness, indexing, and crawlability. As a top SEO agency in Rajkot, we use cutting-edge tactics to improve your website's infrastructure and make it more search engine friendly. Our SEO experts work hard to improve your website's performance, resulting in increased visibility and rating on search engine results pages (SERPs).", },
                                { title: "On Page SEO", pera: "On-page SEO is the process of optimizing individual web pages so that they rank higher and receive more relevant traffic from search engines. Clevero Technology's On-Page SEO services in Rajkot are designed to improve your website's content, meta tags, headings, and internal linking structure. Our SEO experts conduct thorough keyword research to identify the most relevant keywords for your business. By optimizing your on-page elements, we help you attract more organic traffic and improve your website's ranking on search engines.", },
                                { title: "Off Page SEO.", pera: "Off-page SEO refers to operations done outside of your website to improve the search engine rankings. Clevero Technology's Off-Page SEO services in Rajkot focus on building high-quality backlinks and improving your website's authority and credibility. To increase the visibility and organic traffic to your website, our SEO professionals use tried-and-true techniques like guest blogging, social bookmarking, and influencer outreach. As the best SEO service provider in Rajkot, we are committed to helping your business succeed online through effective off-page SEO techniques.", },
                            ]
                        },
                        second_sec_left: {
                            src: "/assets/images/service/sub-services/your-partner-for-unique-seo-solutions.svg",
                            heading: <><h2 className="mb-0/5em ld-fh-element inline-block relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span>Your Partner for Unique </span><mark className="lqd-highlight"><span className="lqd-highlight-txt"> SEO Solutions </span> <span className="lqd-highlight-inner"></span></mark></h2></>,
                            pera: <><p className="ld-fh-element inline-block relative mb-1em text-16">Our SEO specialists in Rajkot are committed to staying ahead of the competition by researching new trends and technology. With us, you can be confident that you are more than just a client, but a valuable collaborator in our mission to redefine digital excellence. Whether you're a little business or a major organization, our SEO services will help you achieve your online objectives. Partner with Clevero Technology today to see the difference our innovative digital solutions can make for your organization.</p></>
                        },
                        faq_data: { // faq_data section  5
                            faq_one: [
                                { title: "What are SEO services, and why are they so important?", content: "SEO services are tactics and techniques for increasing a website's visibility on search engines such as Google. They are vital because they help businesses rank higher in search results, increase organic traffic, and improve their online presence." },
                                { title: "What distinguishes Clevero Technology from other SEO companies in Rajkot?", content: "Clevero Technology distinguishes itself from other SEO companies in Rajkot by having a distinct approach to SEO. We focus understanding your business objectives and personalizing our strategies to meet them efficiently. Our open communication and commitment to perfection distinguish us as the best choice for SEO services." },
                                { title: "How does Clevero Technology differ from other SEO companies in Rajkot?", content: "Clevero Technology distinguishes itself from other SEO companies in Rajkot by employing a unique approach to SEO. We focus on knowing your business objectives and tailoring our methods to attain them effectively. Our open communication and commitment to excellence make us the finest choice for SEO services." }
                            ],
                            faq_two: [
                                { title: "Can Clevero Technology help with SEO marketing for small businesses?", content: "Yes, Clevero Technology is focused on providing SEO marketing services to small businesses. Our cheap solutions are designed to help small businesses increase their online visibility and attract more customers." },
                                { title: "What qualifies Clevero Technology as an SEO expert?", content: "Clevero Technology is recognized as an SEO expert for our in-depth knowledge and expertise in the field. We have a proven track record of helping businesses achieve higher rankings and drive more organic traffic, making us the go-to choice for all your SEO needs." }
                            ]
                        }
                    }
                },
                {
                    bg: "bg-purple-100",
                    src: "assets/images/service/icon/digital-marketing-services/social-media-management.svg",
                    title: "Social Media Management",
                    slug: "social-media-management",
                    dec: <>Our Social Media Management services at <Link to="/"><strong className="text-primary"> Clevero Technology</strong></Link> are designed to amplify your brand's online presence and engage with your audience on a deeper level. We manage all aspects of your social media strategy—from content creation and posting to community management and analytics. By understanding your brand voice and audience, we craft compelling campaigns that boost engagement, increase followers, and drive conversions, allowing you to connect with customers across platforms like Facebook, Instagram, Twitter, and LinkedIn.</>,
                    page: {
                        banner: {
                            page_name: "Social Media Marketing Services",
                            page_dec: "We boost your online presence with creative and engaging social media marketing solutions!",
                            key: "social-media-management"
                        },
                        first_sec: {
                            src: "/assets/images/service/sub-services/expert-for-social-media-marketing.svg",
                            heading: <><h2 className="mb-0/5em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span>Expert for</span><mark className="lqd-highlight"><span className="lqd-highlight-txt"> Social Media Marketing</span><span className="lqd-highlight-inner"></span></mark><br /><span> Maximum ROI</span></h2></>,
                            pera: <><p className="text-16 mb-2/5em ld-fh-element relative">As a leading social media marketing firm in Rajkot, we specialize in developing bespoke plans to increase your brand's exposure on platforms such as Facebook and Instagram. Our dedicated social media gurus and Facebook ad experts excel at navigating the complexity of SMM marketing, ensuring that your campaigns achieve maximum engagement and ROI. Whether you're searching for complete Instagram marketing services or targeted Facebook marketing techniques, our business is a top social media marketing company in Rajkot. We take pride in our ability to provide personalized solutions that not only improve your online presence but also produce concrete commercial outcomes, establishing us as the go-to social media management company for organizations looking to achieve digital domination.</p></>
                        },
                        short_desc: 'provider Short Desc',
                        benefits_sec: {
                            heading: <><h2 className="mb-0/5em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span> Benefits from our </span><br /><mark className="lqd-highlight"><span className="lqd-highlight-txt"> Social Media Management Services </span><span className="lqd-highlight-inner"></span></mark></h2></>,
                            benefits_card: [
                                {
                                    src: "assets/images/service/icon/social-media-marketing-banefits/strategic-expertise.svg",
                                    title: 'Strategic Expertise',
                                    content: "Receive personalized Facebook and Instagram strategies developed by social media marketing experts to boost your online presence and engagement."
                                },
                                {
                                    src: "assets/images/service/icon/social-media-marketing-banefits/targeted-advertising.svg",
                                    title: 'Targeted Advertising',
                                    content: "Use advanced targeting strategies to reach specific demographics and boost the effectiveness of your Facebook and Instagram marketing efforts."
                                },
                                {
                                    src: "assets/images/service/icon/social-media-marketing-banefits/professional-branding.svg",
                                    title: 'Professional Branding',
                                    content: "Consistent branding across all social media platforms will boost your brand's awareness and reputation."
                                },
                                {
                                    src: "assets/images/service/icon/social-media-marketing-banefits/efficiency-and-time-savings.svg",
                                    title: 'Efficiency and time savings',
                                    content: "By outsourcing social media management to experts, you will save time and resources and be able to focus on other critical company duties."
                                },
                                {
                                    src: "assets/images/service/icon/social-media-marketing-banefits/comprehensive-analytics.svg",
                                    title: 'Comprehensive Analytics',
                                    content: "Detailed analytics and reporting provide insights into campaign performance, enabling data-driven decision-making and continuous optimization."
                                },
                                {
                                    src: "assets/images/service/icon/social-media-marketing-banefits/content-creation-excellence.svg",
                                    title: 'Content Creation Excellence',
                                    content: "Get engaging, high-quality content for Facebook and Instagram that aligns with your brand and drives higher user interaction."
                                },
                            ]
                        },
                        expert_sec: {
                            heading: <><h2 className="ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-bottom lqd-highlight-reset-onhover lqd-split-lines mb-0/5em mt-0/25em"> <span> Dominate the Digital Sphere <br /> with </span><mark className="lqd-highlight"> <span className="lqd-highlight-txt"> Social Media Experts </span> <span className="bottom-0 left-0 lqd-highlight-inner bg-purple-100"></span> </mark></h2></>,
                            cards: [
                                { title: "Instagram Marketing", pera: "Instagram is a visual story powerhouse, thus it's critical for business visibility. Clevero Technology enhances your presence with enticing content and purposeful engagement, leading to improved growth and client loyalty." },
                                { title: "Facebook Marketing", pera: " As the largest social network, Facebook has unparalleled reach. Clevero Technology applies its expertise to develop captivating campaigns that will help your brand stand out and generate considerable interaction." },
                                { title: "Twitter Marketing", pera: "The real-time nature of Twitter makes it great for improving brand visibility and communicating with your target audience. Clevero Technology uses unique approaches to strengthen your message and connect with your target audience." },
                                { title: "LinkedIn Marketing", pera: "LinkedIn is the most popular site for B2B networking and professional interaction. Clevero Technology's personalized LinkedIn marketing allows you to create thought leadership and generate high-quality leads." },
                                { title: "YouTube Marketing", pera: "In terms of video content, YouTube reigns supreme. Clevero Technology creates engaging video ads that resonate with your target audience, increasing traffic and sales." },
                                { title: "Pinterest Marketing", pera: "Pinterest is a visual discovery platform that is ideal for advertising products and improving purchase intent. Clevero Technology creates visually attractive campaigns that excite and convert Pinterest users into buyers." },
                            ]
                        },
                        packages:{
                            "facebook":{
                                price:{
                                    basic_plan:  7999,
                                    standard_plan: 9999,
                                    premium_plan:12999,
                                },
                                plans:[
                                    {title:"Organic Facebook Management Plan"},
                                    {services : "Facebook Page Creation",basic: true ,standard: true ,premium: true},
                                    {services : "Facebook Cover And Profile Pic Creation",basic: true ,standard: true ,premium: true},
                                    {services : "Facebook Tabs Creation",basic: false ,standard: true ,premium: true},
                                    {services : "Facebook Page Optimization",basic: true ,standard: true ,premium: true},
                                    {services : "Post on Page(Weekly Numbers)",basic: '1 Post' ,standard: '2 Posts',premium: '3 Posts'},
                                    {services : "Creation Of Facebook Polls/ Quiz",basic: false ,standard: true ,premium: true},
                                    {services : "Sharing Post In Groups",basic: false ,standard: false ,premium: true},
                                    {services : "Responding To Comments",basic: false ,standard: false ,premium: true},
                                    {services : "Call To Action Button Creation",basic: true ,standard: true ,premium: true},
                                    {services : "Hashtag Research",basic: false ,standard: true ,premium: true},
                                    {services : "Influencer Research",basic: false ,standard: false ,premium: true},
                                    {services : "Video Creation (Monthly Numbers)",basic: false ,standard: '1 (30 sec)' ,premium: '2 (45 Sec)'},
                                    {services : "Video Posting (Monthly Numbers)",basic: false ,standard: '1 (30 sec)' ,premium: '2 (45 Sec)'},
                                    {services : "Page Monitoring",basic: false ,standard: true ,premium: true},
                                    {services : "Competitors Analysis",basic: false ,standard: true ,premium: true},

                                    {title:"Facebook Ads Management"},
                                    {services : "Suggested Ad Spend Monthly (in INR)",basic: '₹ 3000' ,standard: '₹ 5000' ,premium: '₹ 10000'},
                                    {services : "Facebook Business Manager Set Up",basic: true ,standard: true ,premium: true},
                                    // {services : "Facebook Ads Campaign (Numbers)",basic: true ,standard: true ,premium: true},
                                    {services : "Pixel Installation",basic: true ,standard: true ,premium: true},
                                    {services : "Custom Conversion Creation",basic: false ,standard: false ,premium: true},
                                    {services : "Creation Of Automated Rules",basic: false ,standard: true ,premium: true},
                                    {services : "Facebook Analytics Report Creation",basic: false ,standard: false ,premium: true},
                                    {services : "Ad Campaign Monitoring",basic: true ,standard: true ,premium: true},
                                    {services : "Catalogue Creation",basic: "Depends On Your Business" ,standard: "Depends On Your Business" ,premium: "Depends On Your Business"},
                                    {services : "Customization Of Ad Placements",basic: false ,standard: false ,premium: true},
                                    {services : "A/B Testing Of Ad Set, Creative And Placement",basic: false ,standard: true ,premium: true},
                                    {services : "Carousel And Collection Ads",basic: false ,standard: false ,premium: true},
                                    {services : "Traffic Monitoring",basic: true ,standard: true ,premium: true},
                                    {services : "Ad (Image Creation)",basic: false ,standard: true ,premium: true},
                                    {services : "Dedicated Account Manager (Support)-",basic: false ,standard: false ,premium: true},
                                ]
                            },
                            "instagram":{
                                price:{
                                    basic_plan:  7999,
                                    standard_plan: 9999,
                                    premium_plan:12999,
                                },
                                plans:[
                                    {title:"Organic Instagram Marketing"},
                                    {services : "Business Profile Creation",basic: true ,standard: true ,premium: true},
                                    {services : "Optimization Of Business Profile",basic: true ,standard: true ,premium: true},
                                    {services : "Instagram Stories Creation(weekly)",basic: false ,standard: '1 Storie' ,premium: '3 Stories'},
                                    {services : "Post Creation For Instagram Profile (Weekly Number)",basic: '1 Post' ,standard: '2 Posts' ,premium: '3 Posts'},
                                    // {services : "Post Creation For Instagram Profile (1 per week)",basic: false ,standard: '1 (30 sec)' ,premium: "2(45 Sec)"},
                                    // {services : "Reels Posting (Monthly Numbers)",basic: false ,standard: true ,premium: true},
                                    {services : "Reels Creation (Monthly Numbers)",basic: false ,standard: '1 (30 sec)' ,premium: "2 (45 Sec)"},
                                    {services : "Reels Posting (Monthly Numbers)",basic: false ,standard: '1 (30 sec)' ,premium: "2 (45 Sec)"},
                                    {services : "Competitors Analysis",basic: false ,standard: true ,premium: true},
                                    {services : "Responding to comment",basic: false ,standard: false ,premium: true},
                                    {services : "Engagement Strategy",basic: false ,standard: false ,premium: true},
                                    {services : "Outreach With Influencers",basic: false ,standard: false ,premium: true},
                                    {services : "Link With Facebook Page",basic: true ,standard: true ,premium: true},
                                    {services : "Video Uploading On IGTV(1- Provided by client)",basic: true ,standard: true ,premium: true},
                                    {services : "Instagram Analytics & Monitoring",basic: false ,standard: false ,premium: true},
                                    {services : "Monthly Reporting",basic: false ,standard: false ,premium: true},
                                    {title:"Instagram Ads"},
                                    {services : "Suggested Ad Spend Monthly (in INR)",basic: "₹ 3000" ,standard: "₹ 5000" ,premium: '₹ 10000'},
                                    {services : "Ad (Image Creation)",basic: false ,standard: true ,premium: true},
                                    {services : "Audience Research",basic: false ,standard: true ,premium: true},
                                    {services : "Instagram Ads Campaign Set up",basic: true ,standard: true ,premium: true},
                                    {services : "Instagram Ads Monitoring And Management",basic: true ,standard: true ,premium: true},
                                    {services : "Dedicated Account Manager (Support)-",basic: false ,standard: false ,premium: true},
                                
                                    // {services : "Instagram Ads Campaign (Numbers)",basic: true ,standard: true ,premium: true},
                                    // {services : "Audience Research",basic: false ,standard: true ,premium: false},
                                    // {services : "Instagram Ads Campaign Set up",basic: true ,standard: true ,premium: true},
                                    // {services : "Instagram Ads Creative Creation & Design",basic: false ,standard: true ,premium: true},
                                    // {services : "Instagram Ads Monitoring And Management",basic: false ,standard: true ,premium: true},
                                ]
                            }
                        },
                        second_sec_left: {
                            src: "/assets/images/service/sub-services/social-media-partners.svg",
                            heading: <><h2 className="mb-0/5em ld-fh-element inline-block relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span> Driving Digital <br /> Growth: Your Trusted </span><mark className="lqd-highlight"><span className="lqd-highlight-txt">  Social Media Partners </span> <span className="lqd-highlight-inner"></span></mark></h2></>,
                            pera: <><p className="ld-fh-element inline-block relative mb-1em text-16">As a top social media marketing agency, we specialize in Facebook and Instagram marketing services that increase your brand's visibility. Our skilled social media specialists create bespoke plans to increase engagement and generate results. As a leading social media marketing firm in Rajkot, we specialize in social media management and provide services that enhance your brand's influence. Partner with us for comprehensive Facebook and Instagram marketing solutions, and see the difference with our dedicated Facebook marketing professionals. Allow us to be your go-to social media marketing agency for outstanding outcomes.</p></>
                        },
                        faq_data: {
                            faq_one: [
                                { title: "Why should you choose Clevero Technology's Facebook marketing services?", content: "Clevero Technology's targeted Facebook advertisements aim to increase brand visibility and interaction. Our experience develops eye-catching commercials that get results, allowing you to meet your company's objectives more efficiently." },
                                { title: "How can Clevero Technology help with Instagram marketing?", content: "Clevero Technology specializes in Instagram marketing and can assist you in building genuine relationships with your audience. Our techniques aim to boost your brand's visibility and interaction on the platform." },
                                { title: "What makes Clevero Technology the best social media marketing company in Rajkot?", content: "Clevero Technology's track record of accomplishment and client satisfaction positions us as a top choice for social media marketing in Rajkot. We prioritize understanding your goals in order to align our strategies with your vision for success." }
                            ],
                            faq_two: [
                                { title: "Why should I utilize Clevero Technology's marketing solutions for Facebook and Instagram?", content: "Clevero Technology offers complete Facebook and Instagram marketing services to increase your brand's online presence. Our social media experts include Facebook and Instagram marketing experts who know how to optimise these channels for optimal ROI." },
                                { title: "How does Clevero Technology help firms in Rajkot with social media marketing?", content: "Clevero Technology, a top social media marketing firm in Rajkot, offers comprehensive solutions to fulfill your digital marketing demands. From content production to campaign management, we have the experience to help your company succeed in the competitive online world." }
                            ]
                        }
                    }
                },
            ],
            col_two: [
                {
                    bg: "bg-red-100",
                    src: "assets/images/service/icon/digital-marketing-services/google-ads-marketing.svg",
                    title: "Google Advertising",
                    slug: "google-advertising",
                    dec: <>Maximize your ROI with <Link to="/"><strong className="text-primary"> Clevero Technology</strong></Link> Google Ads Marketing services. Our certified professionals excel in crafting targeted ad campaigns that place your brand in front of customers who are ready to convert. We focus on cost-effective strategies and continuous optimization of ads to ensure the best possible outcomes. Whether it’s search ads, display networks, or remarketing, we manage your campaigns with precision, aiming to achieve the highest returns on your advertising spend.</>,
                    page: {
                        banner: {
                            page_name: "Google Ads Services",
                            page_dec: "We maximize your ROI with expertly managed Google Ads campaigns, tailored for success!",
                            key: "google-advertising"
                        },
                        first_sec: {
                            src: "/assets/images/service/sub-services/google-ads-services-for-maximum-roi.svg",
                            heading: <><h2 className="mb-0/5em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span> Expert </span><mark className="lqd-highlight"><span className="lqd-highlight-txt"> Google Ads Services </span><span className="lqd-highlight-inner"></span></mark><br /><span> for Maximum ROI </span></h2></>,
                            pera: <><p className="text-16 mb-2/5em ld-fh-element relative">Clevero Technology takes pride in offering top-tier Google Ads solutions that deliver a high return on investment for your company. As a reputable Google Ads agency, our team of accredited specialists in PPC campaign management and advertising is unrivaled, ensuring that your ad budget is optimized for maximum effectiveness. Regardless of the size of your business, our customized marketing tactics for Google Ads will effectively target your desired audience. Collaborate with our skilled Google Ads gurus and discover why we are the go-to choice for companies looking to achieve unparalleled growth and triumph in the online world.</p></>
                        },
                        short_desc: 'provider Short Desc',
                        benefits_sec: {
                            heading: <><h2 className="mb-0/5em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span> Benefits you get from Our </span><br /><mark className="lqd-highlight"><span className="lqd-highlight-txt"> Google Ads Services </span><span className="lqd-highlight-inner"></span></mark></h2></>,
                            benefits_card: [
                                {
                                    src: "assets/images/service/icon/google-ads-benefits/precise-targeting.svg",
                                    title: 'Precise Targeting',
                                    content: "Our Google Ads services ensure your ads reach the right audience at the right time. Our Google Ads specialists use advanced targeting techniques to maximize your campaign’s impact."
                                },
                                {
                                    src: "assets/images/service/icon/google-ads-benefits/cost-effective-advertising.svg",
                                    title: 'Cost-Effective Advertising',
                                    content: "With our PPC advertising solutions, you control your budget and only pay for results. Our Google Ads company ensures that your investment generates the maximum ROI through intelligent ad placements."
                                },
                                {
                                    src: "assets/images/service/icon/google-ads-benefits/instant-results.svg",
                                    title: 'Instant Results',
                                    content: "With Google Ads marketing, your website gains immediate visibility and visitors. Unlike organic techniques, our Google Ads services produce immediate and measurable results that help your business develop."
                                },
                                {
                                    src: "assets/images/service/icon/google-ads-benefits/enhanced-brand-awareness.svg",
                                    title: 'Enhanced Brand Awareness',
                                    content: "Our targeted PPC campaign management helps increase your brand’s online presence. By leveraging Google Ads marketing, we enhance your brand’s visibility across the web."
                                },
                                {
                                    src: "assets/images/service/icon/google-ads-benefits/detailed-analytics.svg",
                                    title: 'Detailed Analytics',
                                    content: "Our Google Ads services include comprehensive performance tracking and reporting. Our Google Ads specialists provide insights and data-driven strategies to refine your campaigns and achieve better outcomes."
                                },
                                {
                                    src: "assets/images/service/icon/google-ads-benefits/competitive-advantage.svg",
                                    title: 'Competitive Advantage',
                                    content: "Stay ahead of your competition with our expert Google Ads services. Our Google Ads agency uses the latest tools and techniques to give your business the competitive edge it needs in the digital marketplace."
                                },
                            ]
                        },
                        expert_sec: {
                            heading: <><h2 className="ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-bottom lqd-highlight-reset-onhover lqd-split-lines mb-0/5em mt-0/25em"> <span> Revolutionize Your Online Presence<br /> with </span><mark className="lqd-highlight"> <span className="lqd-highlight-txt"> Google Ads Marketing </span> <span className="bottom-0 left-0 lqd-highlight-inner bg-purple-100"></span> </mark></h2></>,
                            cards: [
                                { title: "Search Ads", pera: "Google search results that allow businesses to target consumers who are actively looking for related products or services. Trust our Google Ads experts to properly manage your PPC campaigns." },
                                { title: "Display Ads", pera: "Display Ads are visual adverts that display throughout the Google Display Network and are efficient at increasing brand recognition. Our Google Ads agency ensures that your PPC advertisements reach their desired audience." },
                                { title: "Video Ads", pera: "These ads show on YouTube and Google's video partners, engaging users with high-quality content. Count on our Google Ads company to create successful Google Ads marketing campaigns." },
                                { title: "Shopping Ads", pera: "These advertising show product photographs and prices right away in search results, resulting in more qualified leads. Our Google Ads services dramatically boost your e-commerce performance." },
                                { title: "App Promotion Ads", pera: "These ads encourage users to download and interact with your app on the Google Play Store and elsewhere. Our Google ad professionals improve your campaigns to increase downloads." },
                                { title: "Local Service Ads", pera: "Local Service Ads connect you to local clients looking for services in your region. Trust our Google Ads specialists to handle targeted PPC campaigns that generate local leads." },
                                { title: "Smart Campaigns", pera: "These ads are created and optimized automatically, making them ideal for small businesses. Allow our Google ads firm to handle your PPC advertising for best results." },
                                { title: "Discovery Ads", pera: "These ads show on YouTube, Gmail, and the Discover stream, catching attention with tailored content. Our Google Ads agency specializes in creating engaging Google Ads marketing campaigns." },
                            ]
                        },
                        // process_sec: {
                        //     src : "/assets/images/service/sub-services/google-ads-process.jpg",
                        //     imgwidth: "",
                        //     heading: <><h2 class="mb-0/5em ld-fh-element inline-block relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span>Our Process for creating effective </span><mark class="lqd-highlight"><span class="lqd-highlight-txt">Google Ads Marketing </span><span class="lqd-highlight-inner"></span></mark><span>Strategies</span></h2></>,
                        //     pera: <p class="ld-fh-element inline-block relative lqd-split-lines mb-1/5em text-17 leading-1/3em">At <Link to="/"><strong class="text-primary">Clevero Technology,</strong></Link> our process for crafting impactful digital marketing strategies is thorough and results-driven.</p>,
                        //     procesing : ['Keyword Research','Competitor Campaign Analysis','Ad & Campaign Creation','Landing Page Design','Weekly Updates & Monthly Reports']
                        // },
                        second_sec_left: {
                            src: "/assets/images/service/sub-services/google-ads-marketing-2.svg",
                            heading: <><h2 className="mb-0/5em ld-fh-element inline-block relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span> Leading </span><mark className="lqd-highlight"><span className="lqd-highlight-txt">  Google Ads Solutions </span> <span className="lqd-highlight-inner"></span></mark><br /><span> for Outstanding Results </span></h2></>,
                            pera: <><p className="ld-fh-element inline-block relative mb-1em text-16">Our comprehensive Google Ads services are overseen by skilled specialists who excel at campaign creation and optimization. As a leading Google advertising agency, we prioritize thorough PPC campaign management to guarantee your advertising reach the right people at the right time. Whether you require strategic PPC advertising, targeted Google Ads marketing, or expert assistance from a Google Ads company, our team of Google ad experts is here to help you maximise your ROI and drive business growth.</p></>
                        },
                        faq_data: { // faq_data section  5
                            faq_one: [
                                { title: "How experienced are your Google Ads specialists?", content: "Our team is composed of certified Google Ads specialists who have extensive experience managing successful campaigns across various industries. Each Google ad expert at Clevero Technology is skilled in the latest Google Ads techniques and committed to optimizing your campaigns for maximum performance." },
                                { title: "What makes your PPC campaign management different from other agencies?", content: "Clevero Technology sets itself apart through a personalized approach to PPC campaign management. Unlike other agencies, we delve deeper into understanding your unique business needs and market challenges, enabling us to craft highly customized strategies that drive real, measurable results." },
                                { title: "Can you explain the benefits of choosing Clevero Technology as my Google Ads company?", content: "Choosing us means partnering with a Google Ads company that truly cares about your success. We integrate creativity with analytical precision to deliver cost-effective and impactful advertising solutions. Our proactive optimizations and strategic insights help you stay ahead in a competitive digital landscape." }
                            ],
                            faq_two: [
                                { title: "What results can I expect from your PPC advertising services?", content: "By leveraging our PPC advertising expertise, clients typically see significant improvements in traffic, click-through rates, and conversions. Our goal-oriented approach ensures that every ad dollar you spend contributes towards achieving your business objectives efficiently." },
                                { title: "What distinguishes Clevero Technology in the competitive market of Google Ads marketing?", content: "What sets us apart in the Google Ads marketing field is our commitment to customization and client satisfaction. We don’t just run campaigns; we build strong, lasting partnerships with our clients by being consistently reliable, proactive, and innovative in our strategies." }
                            ]
                        }
                    }
                },
                {
                    bg: "bg-slate-100",
                    src: "/assets/images/service/icon/digital-marketing-services/e-commerce-marketing.svg",
                    title: "E-Commerce Marketing",
                    slug: "e-commerce-marketing",
                    dec: <>Elevate your online store’s performance with <Link to="/"><strong className="text-primary"> Clevero Technology</strong></Link> E-Commerce Marketing services. We specialize in driving sales and growing your e-commerce business through tailored strategies that attract and retain customers. From product optimization and SEO to targeted ads and social media campaigns, our approach is designed to enhance user experience and convert visitors into loyal customers, ensuring your e-commerce platform stands out in a competitive marketplace.</>,
                    page: {
                        banner: {
                            page_name: "E-Commerce Marketing Services",
                            page_dec: "We drive sales and growth with comprehensive e-commerce marketing solutions!",
                            key: "e-Commerce-marketing"
                        },
                        first_sec: {
                            src: "/assets/images/service/sub-services/e-commerce-marketing.svg",
                            heading: <><h2 className="mb-0/5em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span> Get Noticed, Get Sales: Our </span><mark className="lqd-highlight"><span className="lqd-highlight-txt"> E-Commerce Marketing </span><span className="lqd-highlight-inner"></span></mark><span> Expertise </span></h2></>,
                            pera: <><p className="text-16 mb-2/5em ld-fh-element relative">Clevero Technology, a leading e-commerce marketing business, will help you achieve your e-commerce goals. Our skilled team specializes in full e-commerce digital marketing services that can help your company stand out in the competitive online industry. As a leading e-commerce solution provider, we provide customized plans for Shopify marketing and other platforms to help you maximize your online presence and revenue. Partner with us, the top e-commerce marketing business, and let our e-commerce marketing experts boost your brand with unique solutions and measurable results.</p></>
                        },
                        short_desc: 'provider Short Desc',
                        benefits_sec: {
                            heading: <><h2 className="mb-0/5em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span>  Benefits from expert  </span><br /><mark className="lqd-highlight"><span className="lqd-highlight-txt"> E-Commerce marketing </span><span className="lqd-highlight-inner"></span></mark></h2></>,
                            benefits_card: [
                                {
                                    src: "assets/images/service/icon/e-commerce-marketing-benefits/increased-visibility.svg",
                                    title: 'Increased visibility',
                                    content: "By utilizing our e-commerce marketing expertise, your online store will stand out from the crowd, increasing visibility and attracting more potential customers."
                                },
                                {
                                    src: "assets/images/service/icon/e-commerce-marketing-benefits/targeted-plans.svg",
                                    title: 'Targeted plans',
                                    content: "As a top e-commerce service provider, we create personalized marketing plans that are specific to your company's needs, assuring maximum effect and ROI."
                                },
                                {
                                    src: "assets/images/service/icon/e-commerce-marketing-benefits/increased-sales.svg",
                                    title: 'Increased Sales',
                                    content: "Our e-commerce marketing firm focuses on increasing conversions and sales, allowing your business to prosper in the competitive online market."
                                },
                                {
                                    src: "assets/images/service/icon/e-commerce-marketing-benefits/expertise-in-shopify-marketing.svg",
                                    title: 'Expertise in Shopify Marketing',
                                    content: "We optimize your store for success by boosting traffic and increasing conversions."
                                },
                                {
                                    src: "assets/images/service/icon/e-commerce-marketing-benefits/comprehensive-services.svg",
                                    title: 'Comprehensive Services',
                                    content: "It provides a variety of e-commerce digital marketing services, such as SEO, social media marketing, and email campaigns, all aimed at improving your online presence and driving growth."
                                },
                                {
                                    src: "assets/images/service/icon/e-commerce-marketing-benefits/proven-results.svg",
                                    title: 'Proven Results',
                                    content: "As ecommerce marketing professionals, we have a track record of providing great results for our clients, making us the ideal choice for your online marketing requirements."
                                },
                            ]
                        },
                        expert_sec: {
                            heading: <><h2 className="mb-0/5em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span> Maximize Your </span><mark className="lqd-highlight"><span className="lqd-highlight-txt"> E-commerce </span><span className="lqd-highlight-inner"></span></mark><br /><span> Potential with </span><mark className="lqd-highlight"><span className="lqd-highlight-txt"> Clevero Technology </span><span className="lqd-highlight-inner"></span></mark></h2></>,
                            cards: [
                                { title: "Business-to-consumer (B2C)", pera: "Clevero Technology provides specialized e-commerce marketing strategies to increase your B2C sales, improve customer engagement, and generate internet traffic, making us your preferred e-commerce service provider." },
                                { title: "Business-to-business (B2B)", pera: "Our e-commerce marketing professionals specialize in developing focused programs that strengthen business connections, maximize lead creation, and boost sales for B2B businesses." },
                                { title: "Business-to-government (B2G)", pera: "Clevero Technology offers specialist e-commerce digital marketing services to assist firms in reaching and engaging government bodies while maintaining compliance and maximising contract prospects." },
                                { title: "Consumer-to-consumer (C2C)", pera: "As the finest e-commerce marketing firm, we create new marketing tactics to improve exposure, trust, and transactions on C2C platforms, hence increasing user engagement and sales." },
                                { title: "Consumer-to-business (C2B)", pera: "Our e-commerce marketing company helps individuals promote their products or services to businesses through smart marketing programs that leverage our knowledge in Shopify marketing and online services." },
                                { title: "Consumer-to-government (C2G)", pera: "Clevero Technology provides full e-commerce online marketing services to help individuals efficiently sell their products or services to government agencies, resulting in successful engagement and transactions." },
                            ]

                        },
                        // packages:{
                        //     "facebook":{
                        //         price:{
                        //             simple_plan:  85,
                        //             popular_plan: 85,
                        //             premium_plan :23,
                        //         },
                        //         plans:[
                        //             {services : "Number of Products",basic: true ,standard: false ,premium: true},
                        //             {services : "Design Customization",basic: true ,standard: true ,premium: true},
                        //             {services : "Responsive Design",basic: true ,standard: true ,premium: false},
                        //             {services : "Content Management System",basic: true ,standard: true ,premium: true},
                        //             {services : "SEO Friendly",basic: false ,standard: true ,premium: true},
                        //             {services : "Social Media Integration",basic: false ,standard: false ,premium: true},
                        //             {services : "Analytics Setup",basic: true ,standard: true ,premium: true},
                        //             {services : "Web Hosting",basic: false ,standard: true ,premium: true},
                        //             {services : "Technical Support",basic: true ,standard: false ,premium: true},
                        //             {services : "SSL Certificate",basic: true ,standard: false ,premium: true},
                        //             {services : "Monthly Updates",basic: true ,standard: true ,premium: true},
                        //             {services : "Backup & Security",basic: false ,standard: true ,premium: false},
                        //             {services : "Lead Generation Tools",basic: true ,standard: true ,premium: true},
                        //             {services : "Chat Feature",basic: false ,standard: true ,premium: true},
                        //             {services : "Dedicated Account Manager (Support)",basic: false ,standard: true ,premium: true},
                        //         ]
                        //     },
                        // },
                        second_sec_left: {
                            src: "/assets/images/service/sub-services/e-commerce-marketing-2.svg",
                            heading: <><h2 className="mb-0/5em ld-fh-element inline-block relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span> Ignite Your </span><mark className="lqd-highlight"><span className="lqd-highlight-txt"> E-commerce </span> <span className="lqd-highlight-inner"></span></mark><br /><span> Success with Expert Marketing Strategies </span></h2></>,
                            pera: <><p className="ld-fh-element inline-block relative mb-1em text-16">The leading e-commerce marketing agency committed to moving your company forward. As the industry's leading e-commerce service provider, we specialize in developing customized strategies to increase traffic, sales, and online exposure. Our e-commerce marketing experts provide comprehensive digital marketing services, such as Shopify marketing, SEO, and targeted advertising. Clevero Technology is recognized as the top e-commerce marketing business, delivering amazing results with our data-driven strategy and unique solutions. Trust us for high-quality e-commerce web marketing services designed to maximize your ROI and achieve unprecedented growth.</p></>
                        },
                        faq_data: { // faq_data section  5
                            faq_one: [
                                { title: "What separates Clevero Technology as a promoting organization for internet business?", content: "The explanation Clevero Technology is the best web based business showcasing organization is that we esteem information driven methodologies and particular strategies. Focusing on understanding of your business' procedure permits us to foster modified internet business showcasing arrangements that yield recognizable results." },
                                { title: "Why should you use Clevero Technology as your e-commerce solution provider?", content: "Clevero Technology, a leading e-commerce marketing company, offers a wide range of services, such as Shopify marketing and SEO, to help you improve your online presence and increase sales. Our e-commerce marketing experts are dedicated to your business and make certain that every campaign is optimized for maximum ROI." },
                                { title: "How can Clevero Technology assist my firm with e-commerce digital marketing services?", content: "Clevero Technology offers e-commerce digital marketing services geared to your individual requirements, whether you want to increase traffic, improve conversions, or boost brand visibility. Our expertise in internet marketing methods ensures that your e-commerce business remains competitive." }
                            ],
                            faq_two: [
                                { title: "For what reason is Clevero Technology the ideal decision for online business web showcasing administrations?", content: "We are the best e-commerce marketing company because of our dedication to excellence and innovation. We blend state of the art innovation in with creative answers for give extraordinary outcomes to your internet business, helping you in prevailing in the cutthroat internet based market." },
                                { title: "How is Clevero Technology beneficial for any company looking to succeed in the competitive e-commerce market?", content: "Clevero Technology's expertise in e-commerce marketing and dedication to client success make us the ideal partner for any company looking to thrive in the competitive e-commerce market. Our customized strategies, coupled with our commitment to delivering results, ensure that your e-commerce business achieves its full potential." }
                            ]
                        }
                    }
                },
            ],
            col_three: [
                {
                    bg: "bg-yellow-100",
                    src: "assets/images/service/icon/digital-marketing-services/e-mail-marketing.svg",
                    title: "E-Mail Marketing",
                    slug: "e-mail-marketing",
                    dec: <>With <Link to="/"><strong className="text-primary"> Clevero Technology</strong></Link> Email Marketing services, engage your audience with personalized, action-driving communications. We create custom email campaigns that resonate with your customers, from promotional messages and newsletters to automated sequences that nurture leads through the sales funnel. Our approach focuses on crafting compelling content and designs, segmenting lists for targeted delivery, and analyzing performance to continuously improve engagement rates and conversions.</>,
                    page: {
                        banner: {
                            page_name: "E-Mail Marketing Services",
                            page_dec: "We connect you with your audience through targeted, impactful email marketing campaigns!",
                            key: "email-marketing"
                        },
                        first_sec: {
                            src: "/assets/images/service/sub-services/email-marketing.svg",
                            heading: <><h2 className="mb-0/5em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span> Get the Best Returns with </span><mark className="lqd-highlight"><span className="lqd-highlight-txt"> Professional E-Mail Campaign Management </span><span className="lqd-highlight-inner"></span></mark></h2></>,
                            pera: <><p className="text-16 mb-2/5em ld-fh-element relative">Improve your company's success by utilizing our expert e-mail campaign management services. We have been hailed as one of the top email marketing companies in the field. Our email marketing service stands out as we tailor each campaign to bring in substantial returns. At our email marketing agency, we have a fantastic group of experts who are well-versed in the latest strategies and technologies to help your messages shine. Our services range from captivating email marketing campaigns to top-of-the-line email blasts that will enhance your reach. We assure you that our team will elevate your marketing strategy to new heights. Our goal is to become your preferred partner for email marketing. Our meticulously crafted campaigns are tailored for optimal success and guarantee a high return on your investment.</p></>
                        },
                        short_desc: 'provider Short Desc',
                        benefits_sec: {
                            heading: <><h2 className="mb-0/5em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span> Benefits you get with </span><br /><mark className="lqd-highlight"><span className="lqd-highlight-txt"> E-Mail Marketing Expert </span><span className="lqd-highlight-inner"></span></mark></h2></>,
                            benefits_card: [
                                {
                                    src: "assets/images/service/icon/email-marketing-benefits/personalized-customer-engagement-1.svg",
                                    title: 'Personalized Customer Engagement',
                                    content: "Customize your messaging to reflect individual tastes, enhancing relevancy and engagement rates."
                                },
                                {
                                    src: "assets/images/service/icon/email-marketing-benefits/cost-effective-marketing-2.svg",
                                    title: 'Cost-Effective Marketing',
                                    content: "Reach a wide audience for a fraction of the cost of traditional marketing strategies, maximizing your ROI."
                                },
                                {
                                    src: "assets/images/service/icon/email-marketing-benefits/measurable-results-3.svg",
                                    title: 'Measurable Results',
                                    content: "Monitor open rates, click-through rates, and conversion rates to gather useful insights and always improve your campaigns."
                                },
                                {
                                    src: "assets/images/service/icon/email-marketing-benefits/increased-brand-loyalty-4.svg",
                                    title: 'Increased Brand Loyalty',
                                    content: "Communicate with your target audience on a frequent basis to build relationships and encourage long-term loyalty."
                                },
                                {
                                    src: "assets/images/service/icon/email-marketing-benefits/targeted-campaigns-5.svg",
                                    title: 'Targeted Campaigns',
                                    content: "Segment your audience based on their activity, preferences, or demographics to ensure that your communications reach their intended recipients every time."
                                },
                                {
                                    src: "assets/images/service/icon/email-marketing-benefits/automated-efficiency-6.svg",
                                    title: 'Automated Efficiency',
                                    content: "Use automated email workflows to save time and ensure consistent communication, letting you focus on strategic growth."
                                },
                            ]
                        },
                        expert_sec: {
                            heading: <><h2 className="mb-0/5em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span> Optimize Your Campaigns with </span><mark className="lqd-highlight"><span className="lqd-highlight-txt"> Professional E-Mail Marketing Services </span><span className="lqd-highlight-inner"></span></mark></h2></>,
                            cards: [
                                { title: "Promotional Emails", pera: "Promotional emails are designed to promote sales, special offers, new product releases, and events. They want to inspire immediate action, such as purchases or event registrations." },
                                { title: "Informational Emails", pera: <><strong>Newsletters:</strong> It provides regular updates on new material, product developments, and business news. <br/><strong>Announcements:</strong> Emails with key corporate updates or news, such as a new collaboration, milestone, or product release.</> },
                                { title: "Transactional Emails", pera: <><strong>Order Confirmations:</strong> These are sent after a purchase to confirm the transaction details.<br/><strong>Shipping Notifications:</strong> Update clients on the status of their order's shipment.<br/><strong>Account Alerts:</strong> Notifications of changes to account status or balance adjustments.</> },
                                { title: "Behavioral Emails", pera: <><strong>Cart Abandonment Reminders:</strong> Target users who have left items in their shopping cart to encourage completion of the purchase.<br/><strong>Re-engagement Emails:</strong> Designed to rekindle interest in users who haven't communicated with the company in a while.<br/><strong>Personalized Recommendations:</strong> These emails recommend products or services based on previous purchases or browsing history.</> },
                                { title: "Lead Nurturing Emails", pera: "Used in lead generation efforts, these emails are sent in a sequence to guide potential prospects through the sales funnel, giving them with relevant information and incentives at each stage." },
                                { title: "Seasonal Campaigns", pera: "Emails sent during holidays or certain seasons (such as back-to-school or Black Friday) that are designed to capitalize on increased shopping activity." },
                            ]
                        },
                        second_sec_left: {
                            src: "/assets/images/service/sub-services/email-marketing-2.svg",
                            heading: <><h2 className="mb-0/5em ld-fh-element inline-block relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span> Fuel Your Digital Growth Engine with Our </span><mark className="lqd-highlight"><span className="lqd-highlight-txt"> Email Campaigns </span> <span className="lqd-highlight-inner"></span></mark></h2></>,
                            pera: <><p className="ld-fh-element inline-block relative mb-1em text-16">With our skillfully developed email campaigns, you may achieve exceptional digital growth. As a top email marketing service provider and agency, we specialize in providing the best email marketing solutions for your specific needs. Our team of experienced specialists ensures that each email marketing campaign is properly planned and executed to maximize outcomes. Whether you want to launch your first email campaign or upgrade your present ones, our comprehensive email marketing services have you covered. We identify ourselves as one of the best email marketing companies by consistently delivering outstanding results. Trust us to fuel your digital growth engine and propel your business to new heights.</p></>
                        },
                        faq_data: { // faq_data section  5
                            faq_one: [
                                { title: "What is the definition of email marketing?", content: "Email marketing is a digital marketing approach that involves sending commercial communications to a specific group of people via email. Promotional content, product updates, newsletters, and other types of messages are common." },
                                { title: "What are the benefits of email marketing for my business?", content: "Email marketing is vital for firms of all sizes since it allows for direct client contact, increases customer loyalty, drives sales, and provides measurable marketing results." },
                                { title: "How does Clevero Technology differ from other email marketing companies?", content: "Clevero Technology sets itself apart by taking a creative approach to email marketing, leveraging cutting-edge technology and industry best practices to generate exceptional results. Our dedication to customer satisfaction, combined with our extensive expertise and understanding, ensures that we consistently exceed expectations." }
                            ],
                            faq_two: [
                                { title: "What kind of email marketing services does Clevero Technology provide?", content: "Clevero Technology offers a comprehensive suite of email marketing services, including campaign planning, content development, list segmentation, A/B testing, automation, and analytics. We customize our services to match your specific business goals and objectives." },
                                { title: "How can I start using Clevero Technology's email marketing services?", content: "Getting started with Clevero Technology's email marketing services is straightforward. Simply contact us to schedule a consultation, and our team will work with you to develop a personalized strategy based on your company's needs and objectives. " }
                            ]
                        }
                    }
                },
                // {
                //     bg: "bg-blue-100",
                //     src: "assets/images/service/icon/digital-marketing-services/website-design-and-development.svg",
                //     title: "Website Design and Development",
                //     // slug: "website-design-and-development",
                //     slug: "#",
                //     dec: <>Transform your online presence with <Link to="/"><strong className="text-primary"> Clevero Technology</strong></Link> Website Design and Development services. Our team of experienced designers and developers builds websites that are not only visually appealing but also optimized for performance and user experience. We focus on creating responsive, fast-loading, and SEO-friendly websites tailored to your business needs and objectives. Whether you’re looking to launch a new site or revamp an existing one, our comprehensive web solutions are designed to make a powerful impact on your audience and enhance your digital footprint.</>,
                //     page: {
                        
                //     }
                // }
            ]
        },

        benefits_sec: { // benefits_sec section  3
            heading: <><h2 className="mb-0/75em ld-fh-element relative items-center justify-center text-center lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><mark className="lqd-highlight"><span className="lqd-highlight-txt">Digital Marketing</span> <span className="lqd-highlight-inner"></span></mark><span> Benefits </span></h2></>,
            benefits_card: [
                { src: "assets/images/service/icon/digital-marketing-benefits/enhanced-visibility.svg", title: 'Enhanced Visibility', content: "With focused digital marketing services in Rajkot, you may reach a wider audience and raise the profile of your company." },
                { src: "assets/images/service/icon/digital-marketing-benefits/cost-effectiveness.svg", title: 'Cost-Effectiveness', content: "Digital marketing services are a more affordable option for companies when compared to traditional marketing techniques." },
                { src: "assets/images/service/icon/digital-marketing-benefits/targeted-advertising.svg", title: 'Targeted Advertising', content: "To reach your ideal audience, use digital marketing to target particular demographics, regions, and hobbies." },
                { src: "assets/images/service/icon/digital-marketing-benefits/measurable-outcomes.svg", title: 'Measurable Outcomes', content: "With the help of comprehensive analytics from digital marketing companies, you can simply monitor and assess the performance of your efforts." },
                { src: "assets/images/service/icon/digital-marketing-benefits/enhanced-engagement.svg", title: 'Enhanced Engagement', content: "To create enduring relationships, interact with your audience via emails, social media, and other digital platforms." },
                { src: "assets/images/service/icon/digital-marketing-benefits/increased-conversion-rates.svg", title: 'Increased Conversion Rates', content: "Use tailored digital marketing techniques to convert leads into customers more successfully." },
            ]
        },
        second_sec_left: {
            src: "/assets/images/service/sub-services/digital-marketing.svg",
            heading: <><h2 className="mb-0/5em ld-fh-element inline-block relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2"><span>Your Partner for unique </span><mark className="lqd-highlight"><span className="lqd-highlight-txt"> Digital Services </span> <span className="lqd-highlight-inner"></span></mark></h2></>,
            pera: <><p className="ld-fh-element inline-block relative mb-1em text-16">Being your partner for innovative digital solutions is something we at <Link to="/"><strong className="text-primary"> Clevero Technology</strong></Link> take great pride in. Our consistent commitment to originality and innovation is what makes us stand out in this cutthroat industry. We go above and beyond traditional approaches to provide solutions that are unique to your needs. In order to secure your online success, our team of professionals is committed to remaining on the cutting edge and is always investigating new trends and technology. You can be confident that, with <Link to="/"><strong className="text-primary"> Clevero Technology</strong></Link> you're a valued partner in our endeavor to redefine digital excellence, not just another client.</p></>
        },
        faq_data: { // faq_data section  5
            faq_one: [
                { title: "What sets Clevero Technology apart as a digital marketing agency in Rajkot?", content: "As a leading digital marketing agency in Rajkot, Clevero Technology offers a comprehensive range of services tailored to meet the unique needs of each client. Our team of experts is dedicated to delivering innovative solutions that drive real results for your business." },
                { title: "How can digital marketing services help my business grow?", content: "Digital marketing services can help your business grow by increasing your online visibility, driving more traffic to your website, and generating leads. By utilizing various digital channels, you can reach a larger audience and engage with them more effectively." },
                { title: "What are the advantages of hiring a digital marketing service provider in Rajkot?", content: "Hiring a digital marketing service provider in Rajkot can provide your business with access to a team of experts who are knowledgeable about the local market and can help you create targeted campaigns that resonate with your audience." }
            ],
            faq_two: [
                { title: "How do I choose the best digital marketing agency for my business?", content: "To choose the best digital marketing agency for your business, consider your specific goals and budget, and look for an agency that has a proven track record of delivering results for clients in your industry." },
                { title: "What makes Clevero Technology the best choice for digital marketing services in Rajkot?", content: "Clevero Technology is the best choice for digital marketing services in Rajkot because we offer a comprehensive range of services, including SEO, social media marketing, and PPC advertising, tailored to meet the unique needs of each client. Our team of experts is dedicated to delivering innovative solutions that drive real results for your business." }
            ]
        }
    },
}