import React, { useState } from "react";
import { Link } from "react-router-dom";
import { scrollFunction } from "../functions/functions";
import { CSSTransition } from 'react-transition-group';
import { serviceContent } from "../data/serviceContent";

const serviceArr = Object.keys(serviceContent).map(key => serviceContent[key]);

export default function HeaderPage() {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isAccordionOpen, setAccordionOpen] = useState(false);
    const [scrolltop, setScrollTop] = useState(0);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const closeNavbar = () => {
        setIsCollapsed(true);
    };

    const handleAccordionClick = (e) => {
        e.preventDefault();
        setAccordionOpen(!isAccordionOpen);
    };

    const [subDropdownOpen, setSubDropdownOpen] = useState(null);
    const [subDropdownErrow, setsubDropdownErrow] = useState(false);

    const renderSubDropdown = (servicesCard) => {
        const columns = ['col_one', 'col_two', 'col_three'];
        return (
            <ul className="sub-dropdown-menu">
                {columns.map((col) =>
                    servicesCard[col]?.map((item, index) => (
                        <li key={`${col}-${index}`}>
                            <Link className="text-blue-300 block px-4 py-2 hover:bg-gray-200" to={`/services/digital-marketing/${item.slug ? item.slug.toLowerCase().replace(/\//g, '-').replace(/ /g, '-') : ''}`}>
                                {item.title}
                            </Link>
                        </li>
                    ))
                )}
            </ul>
        );
    };

    const closeAccordion = (e) => {
        e.preventDefault();
        setAccordionOpen(!isAccordionOpen);
    };

    const [MenuSow, setMenuSow] = useState(false);

    const handleHover = () => { setMenuSow(true); };
    const handleLeave = () => { setMenuSow(false); };

    window.onscroll = function () { setScrollTop(scrollFunction()) };

    return (
        <>
            <header id="site-header" className={`main-header main-header-overlay ${scrolltop > 100 ? "is-stuck" : ""}`} >
            <section className="lqd-section w-full flex flex-row items-center justify-between pr-45 pl-25 md:hidden">
                    <div className="w-65percent flex justify-start items-stretch lg:w-full">
                        <div className="ld-module-sd ld-module-sd-hover ld-module-sd-left self-center">
                            <button className="border-none bg-transparent nav-trigger flex relative items-center justify-center style-2 collapsed pointer-events-auto p-0"
                                onClick={() => { handleHover() }}
                            >
                                <span className="bars inline-block relative z-1">
                                    <span className="bars-inner flex flex-col w-full h-full">
                                        <span className="bar inline-block relative"></span>
                                        <span className="bar inline-block relative"></span>
                                        <span className="bar inline-block relative"></span>
                                    </span>
                                </span>
                            </button>
                            <div className={`ld-module-dropdown ${MenuSow ? "is-active" : ""} collapse absolute`} id="lqd-drawer-seide-left">
                                <div className="ld-sd-wrap">
                                    <div className="ld-sd-inner flex-row">
                                        <section id="sidebar" className="lqd-section link-black link-leading-1/5em flex flex-col items-start justify-between p-10">

                                            <i onClick={() => { handleLeave() }} class="lqd-icn-ess icon-ion-ios-close close-custom"></i>

                                            <div className="py-30 flex navbar-brand-plain">
                                                <Link className="navbar-brand flex p-0 relative" to="/" rel="home">
                                                    <span className="navbar-brand-inner post-rel">
                                                        <img className="logo-default" src="/assets/images/logo/logo.svg" alt="Clevero Technology" />
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className="lqd-fancy-menu lqd-custom-menu relative lqd-menu-td-none link-30">
                                                <ul className="reset-ul">
                                                    <li className="mb-20 is-active">
                                                        <Link to='/'>
                                                            <span className="link-icon inline-flex hide-if-empty left-icon icon-next-to-label"></span>
                                                            <span>Home</span>
                                                        </Link>
                                                    </li>
                                                    <li className="mb-20">
                                                        <Link to='/about'>
                                                            <span className="link-icon inline-flex hide-if-empty left-icon icon-next-to-label"></span>
                                                            <span>About</span>
                                                        </Link>
                                                    </li>
                                                    <li className="mb-20">
                                                        <Link to='/services'>
                                                            <span className="link-icon inline-flex hide-if-empty left-icon icon-next-to-label"></span>
                                                            <span>Services</span>
                                                        </Link>
                                                    </li>
                                                    <li className="mb-20">
                                                        <Link to='/career'>
                                                            <span className="link-icon inline-flex hide-if-empty left-icon icon-next-to-label"></span>
                                                            <span>Career</span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to='/blog'>
                                                            <span className="link-icon inline-flex hide-if-empty left-icon icon-next-to-label"></span>
                                                            <span>Blog</span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="flex flex-col">
                                                <div className="ld-fancy-heading relative">
                                                    <p className="ld-fh-element mb-1/5em relative text-black text-20"> <a className="text-manual-primary" href="mailto:hr@clevero.in">info@clevero.in</a></p>
                                                </div>
                                                <div className="social-icons-wrapper gap-20">
                                                    <span className="grid-item">
                                                        <a className="bg-white icon social-icon" href="https://x.com/CleveroTech" target="_blank" rel="noreferrer">
                                                            <span className="sr-only">Twitter</span>
                                                            <img src="/assets/images/home/social-media-icon/twitter.svg" alt="Twitter" />
                                                        </a>
                                                    </span>
                                                    <span className="grid-item">
                                                        <a className="bg-white icon social-icon" href="https://www.facebook.com/cleverotech" target="_blank" rel="noreferrer">
                                                            <span className="sr-only">Facebook</span>
                                                            <img src="/assets/images/home/social-media-icon/facebook.svg" alt="Facebook" />
                                                        </a>
                                                    </span>
                                                    <span className="grid-item">
                                                        <a className="bg-white icon social-icon" href="https://www.instagram.com/cleverotechnology/" target="_blank" rel="noreferrer">
                                                            <span className="sr-only">Instagram</span>
                                                            <img src="/assets/images/home/social-media-icon/instagram.svg" alt="instagram" />
                                                        </a>
                                                    </span>
                                                    <span className="grid-item">
                                                        <a className="bg-white icon social-icon" href="https://in.linkedin.com/company/clevero-technology" target="_blank" rel="noreferrer">
                                                            <span className="sr-only">Linkedin</span>
                                                            <img src="/assets/images/home/social-media-icon/linkedin.svg" alt="Linkedin" />
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <div onClick={() => { handleLeave() }} className="lqd-module-backdrop"></div>
                        </div>
                        <div className="navbar-brand-plain flex py-35 pr-50 pl-25 module-logo">
                            <Link className="navbar-brand flex p-0 relative" to={'/'}>
                                <span className="navbar-brand-inner post-rel">
                                    <img className="logo-default" src="/assets/images/logo/logo.svg" alt="Clevero Technology" />
                                </span>
                            </Link>
                        </div>
                        <div className="module-primary-nav flex lg:w-full">
                            <div className="navbar-collapse inline-flex p-0 lqd-submenu-default-style" id="main-header-collapse">
                                <ul id="primary-nav" className="main-nav flex reset-ul inline-ul lqd-menu-counter-right lqd-menu-items-inline main-nav-hover-fade-inactive link-15 link-bold lg:justify-end" data-submenu-options='{"toggleType": "fade", "handler": "mouse-in-out"}' data-localscroll="true" data-localscroll-options='{"itemsSelector":"> li > a", "trackWindowScroll": true, "includeParentAsOffset": true}' >
                                    <li className="is-active"> <Link className="text-blue-300" to="/">Home</Link> </li>
                                    <li><Link className="text-blue-300" to="/about">About</Link> </li>

                                    <li
                                        onMouseEnter={() => { setDropdownOpen(true); }}
                                        onMouseLeave={() => setDropdownOpen(false)}
                                        onClick={() => setDropdownOpen(false)}
                                        className="relative"
                                    >
                                        <Link to="/services" className="text-blue-300">
                                            Services <span className={`${dropdownOpen ? 'services-right-icon' : ""} link-icon inline-flex hide-if-empty right-icon`}><i className="lqd-icn-ess icon-ion-ios-arrow-down"></i></span>
                                        </Link>
                                        <CSSTransition in={dropdownOpen} timeout={300} classNames="fade" unmountOnExit>
                                            <ul className="dropdown-menu absolute top-full left-0 mt-2 w-48 bg-white shadow-lg">
                                                {serviceArr?.map((data, ind) => {
                                                    return (
                                                        <li
                                                            key={ind}
                                                            onMouseEnter={() => { setSubDropdownOpen(data.card.servicetitle); if (data.card.servicetitle === 'Digital Marketing') { setsubDropdownErrow(true) } }}
                                                            onMouseLeave={() => { setSubDropdownOpen(null); if (data.card.servicetitle === 'Digital Marketing') { setsubDropdownErrow(false) } }}
                                                            className="relative"
                                                        >

                                                            <Link className="text-blue-300 block px-4 py-2 hover:bg-gray-200" to={`/services/${data.card.servicetitle ? data.card.servicetitle.toLowerCase().replace(/\//g, '-').replace(/ /g, '-') : ''}`}>
                                                                {data.card.servicetitle}
                                                                {data.card.servicetitle === 'Digital Marketing' ? <span className={`${subDropdownErrow ? "services-right-icon" : ""} link-icon inline-flex hide-if-empty right-icon`}><i className="lqd-icn-ess icon-ion-ios-arrow-down"></i></span> : ""}
                                                            </Link>
                                                            {data.card.servicetitle === 'Digital Marketing' && (
                                                                <CSSTransition in={subDropdownOpen === 'Digital Marketing'} timeout={300} classNames="fade" unmountOnExit>
                                                                    {renderSubDropdown(data.services_provide)}
                                                                </CSSTransition>
                                                            )}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </CSSTransition>
                                    </li>
                                    <li>
                                        <Link className="text-blue-300" to="/blog">Blog</Link>
                                    </li>
                                    <li>
                                        <Link className="text-blue-300" to="/career">Career</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="w-35percent flex justify-end items-center lg:hidden">
                        <Link to="/contact" className="button btn whitespace-nowrap btn-solid btn-md btn-hover-txt-switch btn-hover-txt-switch-y bg-primary rounded-100 box-shadow-center text-13 font-bold uppercase tracking-0/3 leading-1/5em text-white hover:text-white hover:bg-primary">
                            <span className="btn-txt" data-text="Contact Us" data-split-text="true">Contact Us</span>
                        </Link>
                    </div>
                </section>
               
               
               
               {/* mobile navbar */}
                <section className="lqd-section lqd-mobile-sec relative">
                    <div className="lqd-mobile-sec-inner navbar-header flex items-stretch w-full">
                        <div className="lqd-mobile-modules-container empty"></div>
                        <button
                            type="button"
                            className={`border-none bg-transparent navbar-toggle ${isCollapsed ? 'collapsed' : ''} nav-trigger style-mobile flex relative items-center p-0`}
                            onClick={toggleCollapse}
                            aria-expanded={!isCollapsed}
                        >
                            <span className="sr-only">Toggle Navigation</span>
                            <span className="bars inline-block relative z-1">
                                <span className="bars-inner flex flex-col w-full h-full">
                                    <span className="bar inline-block"></span>
                                    <span className="bar inline-block"></span>
                                    <span className="bar inline-block"></span>
                                </span>
                            </span>
                        </button>
                        <Link className="navbar-brand flex relative" to="/">
                            <span className="navbar-brand-inner">
                                <img className="logo-default" src="/assets/images/logo/logo-mobile.svg" alt="Clevero Technology" />
                            </span>
                        </Link>
                    </div>
                    <div className="lqd-mobile-sec-nav w-full absolute z-10">
                        <div className={`mobile-navbar-collapse navbar-collapse ${isCollapsed ? 'collapse' : ''} w-full`} id="lqd-mobile-sec-nav">
                            <ul id="mobile-primary-nav" className="reset-ul lqd-mobile-main-nav main-nav nav">
                                <li className="is-active"><Link to="/" onClick={closeNavbar}>Home</Link></li>
                                <li><Link to="/about" onClick={closeNavbar}>About</Link></li>
                                <li className="relative">
                                    <Link to="/services">
                                        <span onClick={closeNavbar}>Services</span> <span className="link-icon inline-flex hide-if-empty right-icon" onClick={(e) => { e.preventDefault(); handleAccordionClick(e); }} style={{ marginLeft: '10px' }}><i className="lqd-icn-ess icon-ion-ios-arrow-down"></i></span>
                                    </Link>
                                    <CSSTransition in={isAccordionOpen} timeout={300} classNames="fade" unmountOnExit>
                                        <ul className="dropdown-menu dropdown-menu-mobile text-blue-300 block px-4 py-2 hover:bg-gray-200">
                                            {serviceArr?.map((data, ind) => (
                                                <li key={ind}>
                                                    <Link
                                                        className="text-blue-300 block px-4 py-2 hover:bg-gray-200"
                                                        style={{ fontSize: '15px', color: 'black', fontWeight: 600 }}
                                                        to={`/services/${data.card.servicetitle ? data.card.servicetitle.toLowerCase().replace(/\//g, '-').replace(/ /g, '-') : ''}`}
                                                        onClick={closeNavbar}
                                                    >
                                                        {data.card.servicetitle}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </CSSTransition>
                                </li>
                                <li><Link to="/career" onClick={closeNavbar}>Career</Link></li>
                                <li><Link to="/contact" onClick={closeNavbar}>Contact</Link></li>
                                <li><Link to="/blog" onClick={closeNavbar}>Blog</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
            </header>
        </>
    );
}
