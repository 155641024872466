import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const BlogFaqAccordion = (props) => {
  const [activeIndex, setActiveIndex] = useState(props.activeOne);

  const url = useParams()
   
  useEffect(()=>{
    if(props.keys === "faq_two"){
      setActiveIndex(null);
    }
  },[url])

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <>
      {props.Faq?.map((value, index) => (
        <div key={index} className={`accordion-item panel mb-20 ${activeIndex === index ? "active" : ""}`} >
          <div className="accordion-heading" role="tab" id={`heading-tab-${index}`}>
            <div className="accordion-title">
              <a className={`collapsed user-select-none text-16 leading-2em bg-slate-100 ${activeIndex === index ? "" : "collapsed"}`}
                role="button"
                onClick={() => handleAccordionClick(index)}
                aria-expanded={activeIndex === index ? "true" : "false" }
                aria-controls={`collapse-question-tab-${index}`}
              >
                <span className="accordion-title-txt">{value.title}</span>
                <span className="text-22 text-brown-500 accordion-expander">
                  <i className="lqd-icn-ess icon-ion-ios-add"></i>
                  <i className="lqd-icn-ess icon-ion-ios-remove"></i>
                </span>
              </a>
            </div>
          </div>
          <div id={`collapse-question-tab-${index}`} className={`accordion-collapse collapse ${ activeIndex === index ? "show" : "" }`} role="tabpanel" aria-labelledby={`heading-tab-${index}`}>
            <div className="text-15 accordion-content">{value.content}</div>
          </div>
        </div>
      ))}
    </>
  );
};
export default BlogFaqAccordion;