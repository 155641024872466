import React  from 'react'
import TitlebarInner from './TitlebarInner'
import ContactForm from './ContactForm';
import FooterPage from './FooterPage';
import { metaData } from '../metaData/meta';
import MetaData from './MetaData';
        
function ContactPage() {
                
  return (<>
            <MetaData metaData={metaData["contactUs"]} />
            <TitlebarInner key="career" PageName="Contact Us" PageDec="We are here to answer your questions." />
            <section className="lqd-section form pb-50 pt-50 bg-gray-200">
            <div className="container">
            <div className="row">
                <div className="col col-12 p-0 ca-initvalues-applied lqd-animations-done">
                <div className="relative flex flex-col transition-all pt-55 pb-45 px-50 mx-10percent bg-white rounded-10 module-form">
                    <div className="relative flex flex-wrap items-start justify-between">
                        <div className="w-60percent ld-fancy-heading relative p-10 sm:w-full">
                            <h2 className="ld-fh-element relative mb-0 text-30 lqd-unit-animation-done">Send a Message</h2>
                        </div>
                    <div className="w-40percent relative text-end p-10 sm:w-full sm:text-start animation-element lqd-unit-animation-done">
                        <span className="text-12 text-black-70">or Call for Consultation</span>
                        <br />
                        <strong>
                        <a href='tel:+02269710186'>
                            <span className="text-15 text-primary">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" fill="currentColor">
                                <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"></path>
                                </svg>{' '}
                                +022 6971 0186
                            </span>
                        </a>
                        </strong>
                    </div>
                    </div>
                    <div className="lqd-contact-form lqd-contact-form-button-circle px-15">
                    <div role="form" className="lqd-cf" id="form-contact" lang="en-US" dir="ltr">
                        <div className="screen-reader-response">
                        <p role="status" aria-live="polite" aria-atomic="true"></p>
                        </div>
                        <ContactForm />
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            </section>
            {/* <FooterPage/> */}
        </>
  )
}
export default ContactPage;