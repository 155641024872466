
import React, { useEffect } from 'react'
import { BenefitsSecAnimation } from '../functions/functions'

function BenefitsSection(props){

    useEffect(()=>{
        props.CareerBenefitsData?.forEach((el , ind) => {
            BenefitsSecAnimation(`.benefitssecanimation${ind}`)
        });
    },[])

  return (
    <>
        <section className="lqd-section icon-box pt-50 pb-50 bg-center bg-no-repeat custom-career-section2-bg" id="help-center">
            <div className="container-fluid">
                <div className="row justify-center">
                    <div className="col col-12 text-center p-0">
                        <div className="lqd-imggrp-single block relative mb-10">
                            <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                                <figure className="w-full relative">
                                    <img width="80" height="9" src="/assets/images/career/divider.svg" alt="Shape"/>
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div className="col col-12 text-center p-0">
                        <div className="ld-fancy-heading relative mb-30 lqd-unit-animation-done">
                            <h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2">
                                <span> Great People Deserve Great </span><mark className="lqd-highlight">
                                    <span className="lqd-highlight-txt"> Benefits </span>
                                    <span className="lqd-highlight-inner"></span>
                                </mark>
                            </h2>
                        </div>
                    </div>
                    <div className="col col-12">
                        <div className="module-container flex flex-wrap justify-between mx-auto w-950 md:w-full">                    
                            {props.CareerBenefitsData?.map((data,ind)=>{
                                return(
                                <div key={`CareerBenefitsData${ind}`} className="lqd-iconbox-scale w-45percent mb-65 transition-all">
                                    <div className="iconbox flex flex-grow-1 relative iconbox-side iconbox-square iconbox-heading-arrow-onhover text-start">
                                        <div className="iconbox-icon-wrap">
                                            <div className="iconbox-icon-container inline-flex relative z-1 w-80 h-80 text-30 rounded-20 bg-white items-center justify-center">
                                                <img src={`../assets/images/career/${data.src}`} className={`w-2600 benefitssecanimation${ind}`} alt=""/>
                                            </div>
                                        </div>
                                        <div className="contents">
                                            <h3 className="lqd-iconbox-heading text-20 mb-0/85em">
                                                <span className={`benefitssecanimation${ind}`}>{data.h3} </span> 
                                            </h3>
                                            <p className={`benefitssecanimation${ind}`}>{data.p}</p>
                                        </div>
                                    </div>
                                </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default BenefitsSection