import React, { useEffect, useState } from 'react'
import TitlebarInner from './TitlebarInner'
import BenefitsSection from './BenefitsSection'
import { CareerBenefitsData } from '../data/data'
import { careerData, creativeServices, lineAnimate } from '../functions/functions'
import gsap from 'gsap'
import { Link } from 'react-router-dom'
import FooterPage from './FooterPage'
import { metaData } from '../metaData/meta'
import MetaData from './MetaData'

export default function CareerPage() {

    const [fileSize, setfileSize] = useState({ maxsize: 5, fileSizeError: false });
    const [formData, setformData] = useState({ name: "", email: "", number: "", city: "", file: "", checkbox: false, });
    const [nameError, setnameError] = useState(null);
    const [emailError, setemailError] = useState(null);
    const [numberError, setnumberError] = useState(null);
    const [cityError, setcityError] = useState(null);
    const [fileError, setfileError] = useState(null);
    const [checkBoxError, setcheckBoxError] = useState(false);
    const [emailValidate, setemailValidate] = useState(null);
    const [numberValidate, setnumberValidate] = useState(null);
    const [formValid, setformValid] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        formIsValidate()
    }, [formData, formValid, nameError, emailError, numberError, cityError, fileError, emailValidate])

    function validateEmail(email) {
        const validEmail = email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        if (!formData.email.trim() == "" && !validEmail) { setemailValidate(true); }
        else { setemailValidate(false); }
    };

    function formIsValidate() {
        if (!formData.name == "" && !numberValidate === true && !formData.number == "" && !formData.city == "" && !formData.file.name == "" && !formData.checkbox == false && !emailValidate === true) {
            setformValid(true)
        } else {
            setformValid(false)
        }
    }

    function formSubmit(e) {
        e.preventDefault();

        setformData({ ...formData, name: formData.name.trim(), email: formData.email.trim(), number: formData.number.trim(), city: formData.city.trim() })

        if (formData.name.trim() == "") { setnameError(true) }
        else { setnameError(false); };
        if (formData.email.trim() == "") { setemailError(true) }
        else { setemailError(false); setemailValidate(false) }; validateEmail(formData.email);
        if (formData.number.trim() == "") { setnumberError(true) }
        else { setnumberError(false); setnumberValidate(false) };
        if (formData.city.trim() == "") { setcityError(true) }
        else { setcityError(false); };
        if (formData.file == "") { setfileError(true) }
        else { setfileError(false); };
        if (formData.checkbox == false) { setcheckBoxError(true) }
        else { setcheckBoxError(false); };
        if (formValid) {
            setShowAlert(true);
            // console.log(formData)
            // axios.post('https://example.com/api/contact', formData)
            // .then(response => {
            //     console.log('Form submitted successfully:', response.data);
            //     // Handle success response as needed
            // })
            // .catch(error => {
            //     console.error('Error submitting form:', error);
            //     // Handle error response as needed
            // });
            clearForm();
            setTimeout(() => {
                setShowAlert(false);
            }, 3000); // Hide alert after 3 seconds and clear form


            // console.log("Data filled and sent mail to user");
        }

    }

    const clearForm = () => {
        setformData({
            name: '',
            email: '',
            number: '',
            city: '',
            file: '',
            checkbox: false,
        });
        document.querySelector('.file-upload').classList.remove('active');
        document.getElementById('noFile').textContent = "No file chosen...";
    };

    function fileChoose(e) {

        let filename = e.target.value;
        let files = e.target.files[0];

        if (files?.type == "image/png" || files?.type == "image/jpg" || files?.type == "image/jpeg" || files?.type == "application/pdf") {
            if (files.size / 1024 / 1024 <= fileSize.maxsize) {
                setfileError(true);
                setformData({ ...formData, file: files })
                setfileSize({ ...fileSize, fileSizeError: false })
                if (/^\s*$/.test(filename)) {
                    document.querySelector('.file-upload').classList.remove('active');
                    document.getElementById('noFile').textContent = "No file chosen...";
                } else {
                    document.querySelector('.file-upload').classList.add('active');
                    document.getElementById('noFile').textContent = `${filename.replace("C:\\fakepath\\", "").slice(0, 31)}...`;
                }
            } else {
                setfileSize({ ...fileSize, fileSizeError: true })
                setformData({ ...formData, file: '' })
                document.getElementById('noFile').textContent = "No file chosen...";
                if (formData.file.name === '') {
                    setfileError(false);
                }
            }
        } else {
            // alert("Only jpg/jpeg and png files are allowed!");
            document.getElementById('noFile').textContent = "No file chosen...";
        }
    }

    useEffect(() => {
        careerData('.careerData1');
        careerData('.careerData2');
        creativeServices('creative-services-box1');
        lineAnimate('form-line-line-animate');
        gsap.timeline({
            scrollTrigger: {
                trigger: ".home-form-animate",
                start: "top 90%",
            },
        }).fromTo(".home-form-animate h2,.home-form-animate .animation-element", { y: 60, scaleX: 0.8, scaleY: 0.8, opacity: 0 }, { duration: 2, ease: 'expo.out', y: 0, scaleX: 1, scaleY: 1, opacity: 1 });
    }, [])

    return (
        <>
            <MetaData metaData={metaData["career"]} />
            {showAlert && (
                <div className="alert alert-success alert-dismissible fade show fixed-top" role="alert">
                    Form submitted successfully!
                </div>
            )}


            <link rel="stylesheet" href="/assets/css/demo/start-hub-6.css" />
            <TitlebarInner key="career" PageName="Career" PageDec="If you're passionate about crafting remarkable tech solutions and possess natural talent, we believe that together, we can work wonders!" />
            <main className="content bg-top-center about-background" id="lqd-site-content">

                <div id="lqd-contents-wrap">
                    <section className="lqd-section video-box pt-30 pb-50" id="video-tutorials">
                        <div className="container">
                            <div className="row">
                                <div className="col col-12 col-xl-6 relative text-center">
                                    <div className="lqd-imggrp-single block relative module-img" data-parallax="true">
                                        <div
                                            className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                                            <figure className="w-full relative">
                                                <img className="" width="530" src="/assets/images/career/team-work.svg" alt="phone" />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-xl-6">
                                    <div className="flex flex-col p-10">
                                        <div className="lqd-imggrp-single block relative mb-15">
                                            <div
                                                className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                                                <figure className="w-full relative">
                                                    <img className='careerData1' width="80" height="9" src="/assets/images/career/divider.svg" alt="shape" />
                                                </figure>
                                            </div>
                                        </div>
                                        <div className="ld-fancy-heading relative lqd-unit-animation-done">
                                            <h2 className="careerData1 mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2">
                                                <span> Join Our </span>
                                                <mark className="lqd-highlight">
                                                    <span className="lqd-highlight-txt">Amazing Team </span>
                                                    <span className="lqd-highlight-inner"></span>
                                                </mark>
                                            </h2>
                                        </div>
                                        <div className="ld-fancy-heading relative">
                                            <p className="careerData1 ld-fh-element inline-block relative mb-1em text-16">
                                                We are a dynamic and innovative company committed to the pushing the
                                                boundaries of technology and creativity.
                                                As part of our team, you will have the opportunity to work on exciting
                                                projects. Collaborate with talented
                                                individuals, and make a real impact in the world of technology
                                            </p>
                                        </div>
                                        <div className="careerData1 ld-fancy-heading relative">
                                            <p className="ld-fh-element inline-block relative mb-1em text-16">
                                                Whether you are a seasoned professional or a recent graduate, we welcome
                                                individuals who are passionate, driven, and eager to learn.
                                            </p>
                                        </div>
                                        <div className="ld-fancy-heading relative">
                                            <p className="careerData1 ld-fh-element inline-block relative  text-16">
                                                Join us on our journey to create groundbreaking solutions and shape the
                                                future of technology. Explore career opportunities with
                                                <Link to="/"><strong className="text-primary"> Clevero Technology</strong></Link> today!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="lqd-section pt-30 pb-30" id="video-tutorials">
                        <div className="container">
                            <div className="row">
                                <div className="col col-12 col-xl-6">
                                    <div className="flex flex-col p-10">
                                        <div className="ld-fancy-heading relative lqd-unit-animation-done">
                                            <h2 className="careerData2 mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2">
                                                <mark className="lqd-highlight">
                                                    <span className="lqd-highlight-txt"> What </span>
                                                    <span className="lqd-highlight-inner"></span>
                                                </mark>
                                                <span> We Do? </span>
                                            </h2>
                                        </div>
                                        <div className="ld-fancy-heading relative">
                                            <div className="ld-fancy-heading relative">
                                                <p className="careerData2 lead "> At <Link to="/"><strong className="text-primary"> Clevero Technology</strong></Link>
                                                    , we specialise in bringing your digital
                                                    ideas to life. Our dedicated team excels in custom web development and
                                                    innovative software applications. From initial concept to deployment, we
                                                    work
                                                    closely with you to create tailored solutions that precisely fit your
                                                    needs.
                                                    With expertise in the latest technologies and industry trends, we ensure
                                                    high-quality results delivered on time and within budget.
                                                </p>
                                                <p className="careerData2 lead ">
                                                    Our commitment doesn't end with project completion; we build lasting
                                                    relationships, providing ongoing support and maintenance to keep your
                                                    solutions
                                                    optimised and evolving with your business.
                                                </p>
                                                <p className="careerData2 lead ">
                                                    Whether you need a new website, custom software development, or digital
                                                    enhancement, <Link to="/"><strong className="text-primary"> Clevero Technology</strong></Link> is
                                                    here to help you succeed.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-xl-6 relative text-center ca-initvalues-applied lqd-animations-done">
                                    <div className="lqd-imggrp-single block relative perspective hover-3d-applied lqd-unit-animation-done" data-hover3d="true" data-float="ease-in">
                                        <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center transform-style-3d">
                                            <figure className="w-full relative" data-stacking-factor="1"><img width="1156" className="" height="1156" src="/assets/images/career/Why_Choose_Clevero_Technology.svg" alt="3D shape marketing" /></figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <BenefitsSection CareerBenefitsData={CareerBenefitsData} />

                    <section className="lqd-section consultation pt-50 bg-gray-200" id="career4">
                        <div className="container">
                            <div className="row">
                                <div className="col col-12 flex flex-row flex-wrap justify-center">
                                    <div className="flex flex-col justify-center text-center md:w-full">
                                        <div className="ld-fancy-heading relative animation-element lqd-unit-animation-done">
                                            <h2 className="mb-0/5em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2">
                                                <span>Advantages of Collaborating with </span><br />
                                                <mark className="lqd-highlight">
                                                    <span className="lqd-highlight-txt">Clevero Technology</span>
                                                    <span className="lqd-highlight-inner"></span>
                                                </mark>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-xl-3 p-0 lqd-unit-animation-done">
                                    <div className="mn-h-100 flex flex-auto p-15 transition-all creative-services-box1">
                                        <div className="iconbox icon-adjust flex-grow-1 relative flex-col iconbox-default iconbox-contents-show-onhover py-40 px-20 mb-30 items-center bg-white rounded-10 shadow-bottom lg:m-0 hide-target">
                                            <div className="ld-slideelement-visible career-trans-1">
                                                <div className="ld-slideelement-visible-inner career-card-op-1">
                                                    <div className="iconbox-icon-wrap">
                                                        <div className="mb-25 iconbox-icon-container inline-flex text-50">
                                                            <img src="/assets/images/career/career-advantage-1.png" height="125" width="125" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ld-slideelement-visible career-trans-1">
                                                <div className="ld-slideelement-visible-inner career-card-op-1">
                                                    <h3 className="lqd-iconbox-heading text-center text-16 leading-1em mb-0">
                                                        Career Growth</h3>
                                                </div>
                                            </div>
                                            <div className="contents">
                                                <div className="ld-slideelement-visible career-trans-1">
                                                    <div className="ld-slideelement-visible-inner text-center career-card-op-1">
                                                        <p>Ascending through skill acquisition and role advancement in one's
                                                            profession.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="ld-slideelement-hidden career-trans-1">
                                                    <div className="ld-slideelement-hidden-inner career-card-op-2">
                                                        <a href="#" className="btn btn-naked btn-icon-right btn-hover-swp mt-em mb-0 items-center text-15 font-bold text-secondary hover:text-primary"><span
                                                            className="btn-txt" data-text="Learn more">Learn more</span>
                                                            <span className="btn-icon text-16 tracking-0"><i
                                                                className="lqd-icn-ess icon-md-arrow-round-forward-2"></i>
                                                            </span><span className="btn-icon mr-10 text-16 tracking-0"><i
                                                                className="lqd-icn-ess icon-md-arrow-round-forward-2"></i></span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-xl-3 p-0 lqd-unit-animation-done">
                                    <div className="mn-h-100 flex flex-auto p-15 transition-all creative-services-box1">
                                        <div className="iconbox icon-adjust flex-grow-1 relative flex-col iconbox-default iconbox-contents-show-onhover py-40 px-20 mb-30 items-center bg-white rounded-10 shadow-bottom lg:m-0 hide-target">
                                            <div className="ld-slideelement-visible career-trans-1">
                                                <div className="ld-slideelement-visible-inner career-card-op-1">
                                                    <div className="iconbox-icon-wrap">
                                                        <div className="mb-25 iconbox-icon-container inline-flex text-50">
                                                            <img src="/assets/images/career/career-advantage-2.png" height="125" width="125" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ld-slideelement-visible career-trans-1">
                                                <div className="ld-slideelement-visible-inner career-card-op-1">
                                                    <h3 className="lqd-iconbox-heading text-center text-16 leading-1em mb-0">
                                                        Hybrid Work Model</h3>
                                                </div>
                                            </div>
                                            <div className="contents">
                                                <div className="ld-slideelement-visible career-trans-1">
                                                    <div className="ld-slideelement-visible-inner text-center career-card-op-1">
                                                        <p> A flexible approach combining remote and in-office work,
                                                            offering employees the freedom to choose where and how they
                                                            work.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="ld-slideelement-hidden career-trans-1">
                                                    <div className="ld-slideelement-hidden-inner career-card-op-2"><a
                                                        href="#"
                                                        className="btn btn-naked btn-icon-right btn-hover-swp mt-em mb-0 items-center text-15 font-bold text-secondary hover:text-primary"><span
                                                            className="btn-txt" data-text="Learn more">Learn more</span>
                                                        <span className="btn-icon text-16 tracking-0"><i
                                                            className="lqd-icn-ess icon-md-arrow-round-forward-2"></i>
                                                        </span><span className="btn-icon mr-10 text-16 tracking-0"><i
                                                            className="lqd-icn-ess icon-md-arrow-round-forward-2"></i></span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-xl-3 p-0 lqd-unit-animation-done">
                                    <div className="mn-h-100 flex flex-auto p-15 transition-all creative-services-box1">
                                        <div className="iconbox icon-adjust flex-grow-1 relative flex-col iconbox-default iconbox-contents-show-onhover py-40 px-20 mb-30 items-center bg-white rounded-10 shadow-bottom lg:m-0 hide-target">
                                            <div className="ld-slideelement-visible career-trans-1">
                                                <div className="ld-slideelement-visible-inner career-card-op-1">
                                                    <div className="iconbox-icon-wrap">
                                                        <div className="mb-25 iconbox-icon-container inline-flex text-50">
                                                            <img src="/assets/images/career/career-advantage-3.png" height="125" width="125" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ld-slideelement-visible career-trans-1">
                                                <div className="ld-slideelement-visible-inner career-card-op-1">
                                                    <h3 className="lqd-iconbox-heading text-center text-16 leading-1em mb-0">
                                                        Salary</h3>
                                                </div>
                                            </div>
                                            <div className="contents">
                                                <div className="ld-slideelement-visible career-trans-1">
                                                    <div className="ld-slideelement-visible-inner text-center career-card-op-1">
                                                        <p>We make sure that our employees are constantly highly motivated and well compensated.</p>
                                                    </div>
                                                </div>
                                                <div className="ld-slideelement-hidden career-trans-1">
                                                    <div className="ld-slideelement-hidden-inner career-card-op-2"><a
                                                        href="#"
                                                        className="btn btn-naked btn-icon-right btn-hover-swp mt-em mb-0 items-center text-15 font-bold text-secondary hover:text-primary"><span
                                                            className="btn-txt" data-text="Learn more">Learn more</span>
                                                        <span className="btn-icon text-16 tracking-0"><i
                                                            className="lqd-icn-ess icon-md-arrow-round-forward-2"></i>
                                                        </span><span className="btn-icon mr-10 text-16 tracking-0"><i
                                                            className="lqd-icn-ess icon-md-arrow-round-forward-2"></i></span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-xl-3 p-0 lqd-unit-animation-done">
                                    <div className="mn-h-100 flex flex-auto p-15 transition-all creative-services-box1">
                                        <div className="iconbox icon-adjust flex-grow-1 relative flex-col iconbox-default iconbox-contents-show-onhover py-40 px-20 mb-30 items-center bg-white rounded-10 shadow-bottom lg:m-0 hide-target">
                                            <div className="ld-slideelement-visible career-trans-1">
                                                <div className="ld-slideelement-visible-inner career-card-op-1">
                                                    <div className="iconbox-icon-wrap">
                                                        <div className="mb-25 iconbox-icon-container inline-flex text-50">
                                                            <img src="/assets/images/career/career-advantage-4.png" height="125" width="125" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ld-slideelement-visible career-trans-1">
                                                <div className="ld-slideelement-visible-inner career-card-op-1">
                                                    <h3 className="lqd-iconbox-heading text-center text-16 leading-1em mb-0">
                                                        Flexible Hours</h3>
                                                </div>
                                            </div>
                                            <div className="contents">
                                                <div className="ld-slideelement-visible career-trans-1">
                                                    <div className="ld-slideelement-visible-inner text-center career-card-op-1">
                                                        <p>A work schedule that allows employees to adjust their start and
                                                            end times, promoting better work-life balance and productivity.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="ld-slideelement-hidden career-trans-1">
                                                    <div className="ld-slideelement-hidden-inner career-card-op-2"><a
                                                        href="#"
                                                        className="btn btn-naked btn-icon-right btn-hover-swp mt-em mb-0 items-center text-15 font-bold text-secondary hover:text-primary"><span
                                                            className="btn-txt" data-text="Learn more">Learn more</span>
                                                        <span className="btn-icon text-16 tracking-0"><i
                                                            className="lqd-icn-ess icon-md-arrow-round-forward-2"></i>
                                                        </span><span className="btn-icon mr-10 text-16 tracking-0"><i
                                                            className="lqd-icn-ess icon-md-arrow-round-forward-2"></i></span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section style={{ backgroundImage: "url(assets/images/career/contact-form-bg.jpg)" }} className="lqd-section contact-form bg-center bg-cover pt-70 pb-100 pl-10 pr-10" id="contact">
                        <div className="container">

                            <div className="row items-end pl-10 pr-10 resp-form">
                                <div className="w-45percent custom-h-100 pr-10 pl-10 sm:w-full">
                                    <div className="relative w-full flex flex-col justify-end pb-40">
                                        <div className="absolute -top-145percent ltr-left-0 module-shape-1 animation-element">
                                            <div id="lqd-lottie-form-contact" className="lqd-lottie"></div>
                                        </div>
                                        <div className="home-form-big-txt ld-fancy-heading relative mask-text">
                                            <h2 className="form-line-line-animate ld-fh-element inline-block relative lqd-split-lines mb-0/5em text-70">
                                                Want to build
                                                Career?🎯</h2>
                                        </div>
                                        <div className="ld-fancy-heading relative mask-text w-410 max-w-full">
                                            <p className="form-line-line-animate ld-fh-element inline-block relative lqd-split-lines mb-0/5em text-16 leading-20">Looking for collaboration? Send an email to
                                                <a className="text-manual-primary" href="mailto:hr@clevero.in"> info@clevero.in</a> to for vailable for enquires and collaborations,</p>
                                            <img src="/assets/images/career/formman.svg" alt="career" className="custom-blend-multiply" />
                                        </div>
                                    </div>
                                </div>

                                <div className="w-50percent lg:w-55percent sm:w-full home-form-animate">
                                    <div className="lqd-contact-form lqd-contact-form-inputs-underlined lqd-contact-form-button-block lqd-contact-form-button-circle lqd-contact-form-inputs-border-thick bg-white pt-45 pb-50 px-75 rounded-12 animation-element">
                                        <div role="form">
                                            <div className="screen-reader-response animation-element">
                                                <p role="status" aria-live="polite" aria-atomic="true"></p>
                                            </div>
                                            <form className="lqd-cf-form" data-status="init" id="firm-form" noValidate>
                                                <div className="row -mr-15 -ml-15 flex-wrap">
                                                    <div className="col col-12 col-md-6 px-15">
                                                        <p>
                                                            <span className="lqd-form-control-wrap text">
                                                                <input type="text" name="name" size="40" value={formData.name} onChange={(e) => { setformData({ ...formData, name: e.target.value }); setnameError(false); formIsValidate(); }} className="lqd-cf-form-control text-17 leading-1/7em text-gray-700 " placeholder="Name" />
                                                                <div className={`border-bottom ${nameError ? 'border-b-red' : ''}`} />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="col col-12 col-md-6 px-15">
                                                        <p>
                                                            <span className="lqd-form-control-wrap email">
                                                                <input type="email" name="email" size="40" value={formData.email} onChange={(e) => { setformData({ ...formData, email: e.target.value }); setemailError(false); validateEmail(formData.email); formIsValidate(); }} className="lqd-cf-form-control text-17 leading-1/7em text-gray-700 border-black-20" placeholder="Email" />
                                                                <div className={`border-bottom ${emailError ? 'border-b-red' : ''}`} />
                                                                {
                                                                    emailValidate ? <p className='accept-files clr-red'>Please enter a valid email address</p> : ""
                                                                }
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="col col-12 col-md-6 px-15">
                                                        <p>
                                                            <span className="lqd-form-control-wrap tel">
                                                                <input type="number" name="number" size="40" value={formData.number} onChange={(e) => { setformData({ ...formData, number: e.target.value }); setnumberError(false); formIsValidate(); }} className="lqd-cf-form-control text-17 leading-1/7em text-gray-700 border-black-20" placeholder="Number" />
                                                                <div className={`border-bottom ${numberError ? 'border-b-red' : ''}`} />
                                                                {
                                                                    numberValidate ? <p className='accept-files clr-red'>Please enter a valid number</p> : ""
                                                                }
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="col col-12 col-md-6 px-15">
                                                        <input type="text" name="city" placeholder="City" size="40" value={formData.city} onChange={(e) => { setformData({ ...formData, city: e.target.value }); setcityError(false); formIsValidate(); }} className="lqd-cf-form-control text-17 leading-1/7em text-gray-700 border-black-20" />
                                                        <div className={`border-bottom ${cityError ? 'border-b-red' : ''}`} />
                                                    </div>
                                                    <div className="col col-12 px-15 mt-20 mb-50">
                                                        <div className="file-upload">
                                                            <div className="file-select">
                                                                <div className="file-select-button" id="fileName">Choose File</div>
                                                                <div className="file-select-name" id="noFile">No file chosen...</div>
                                                                <input onChange={(e) => { fileChoose(e); }} type="file" accept="image/png, image/jpg, image/jpeg, application/pdf" name="chooseFile" id="chooseFile" />
                                                            </div>
                                                            <div className={`border-bottom ${fileError ? 'border-b-red' : ''}`} />
                                                        </div>
                                                        {
                                                            fileSize?.fileSizeError ?
                                                                <p className='accept-files clr-red'>File size exceeds 5MB. Please upload a smaller file</p>
                                                                :
                                                                <p className='accept-files'>png , jpg , jpeg , pdf</p>

                                                        }
                                                    </div>

                                                    <div className="col col-12 px-15">
                                                        <span className="lqd-form-control-wrap acceptance">
                                                            <span className={`lqd-cf-form-control lqd-cf-acceptance ${checkBoxError ? 'error' : ''}`}>
                                                                <span className="lqd-cf-list-item">
                                                                    <label for="check-mark">
                                                                        <input type="checkbox" id='check-mark' onChange={(e) => { setformData({ ...formData, checkbox: e.target.checked }); setcheckBoxError(false); formIsValidate(); }} checked={formData.checkbox} />
                                                                        <span className="lqd-cf-list-item-label">
                                                                            I am bound by the terms of the Service I accept Privacy Policy
                                                                        </span>
                                                                    </label>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div className="col col-12 px-15">
                                                        <input onClick={formSubmit} type="submit" className={`${!formValid ? "contact-form-btn" : ""} lqd-cf-form-control has-spinner bg-primary border-0 text-14 font-bold tracking-0/5 uppercase text-white`} />
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="lqd-cf-response-output"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            {/* <FooterPage /> */}
        </>
    )
}
